import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import UserFlow from 'components/UserFlow';
import orderFlowConfig from './orderFlowConfig';
import H1 from 'components/primitives/H1';
import TabView from 'components/TabView';
import OrderHistory from '../OrderHistory';
import useFlowContext from 'components/UserFlow/useFlowContext';
import useQueryParam from 'hooks/useQueryParam';
import { RequestStatus } from 'api/hooks/useApiCall';
import Spinner from 'components/Spinner';
import useOrderUserFlowData, {
  UserFlowDataContext,
} from './useOrderUserFlowData';
import useUserInfo from 'hooks/useUserInfo';
import { mapPositionFromRepairshopInfo } from './util';

export const inventoryIdQueryParam = 'inventoryId';

const Wrap = styled.div`
  flex: 1;
`;

const OrderUserFlow: FC = () => {
  const userInfo = useUserInfo();
  const [openTab, setOpenTab] = useState(0);

  const [inventoryId] = useQueryParam(inventoryIdQueryParam);

  const userflowData = useOrderUserFlowData(inventoryId);
  const {
    inventoryResponse,
    choosableRepairshopsResponse,
    userRepairshopInfoResponse,
  } = userflowData;

  const handleOrderSent = () => {
    setOpenTab(1);

    flowContext.resetFormValues();
    flowContext.setPage(0);
  };

  const flowContext = useFlowContext(orderFlowConfig(handleOrderSent));

  // Reset form when inventoryId changes. OBS: keep this effect first, as following effects also sets default values.
  useEffect(() => {
    flowContext.resetFormValues();
    flowContext.setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryId]);

  // Recalculate map bounds when tab changes back to UserFlow
  useEffect(() => {
    if (openTab === 0) {
      flowContext.updateFormValues((values) => ({
        ...values,
        autoFitMapToMarkers: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTab]);

  // Update user info when user info is fetched or inventoryId changes
  useEffect(() => {
    if (userInfo) {
      flowContext.updateFormValues((values) => ({
        ...values,
        name: userInfo.displayName,
        phoneNumber: userInfo.phoneNumber,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, inventoryId]);

  // Update inventory details when the inventory has been fetched.
  useEffect(() => {
    // Run effect when all responses have been fetched
    if (
      [
        inventoryResponse.status,
        choosableRepairshopsResponse.status,
        userRepairshopInfoResponse.status,
      ].some((s) => s !== RequestStatus.Fetched)
    )
      return;

    const inventory = inventoryResponse.response;

    const inventoryRepairshopInfo = choosableRepairshopsResponse.response?.find(
      (r) => r.repairshopInfo?.id === inventory?.repairShopId
    );
    const userRepairshopInfo = userRepairshopInfoResponse.response;

    // If the vehicle has a known repairshop, then use that as the origin, otherwise use the user's repairshop.
    const vehicleOrigin = inventoryRepairshopInfo?.repairshopInfo
      ? mapPositionFromRepairshopInfo(inventoryRepairshopInfo.repairshopInfo)
      : userRepairshopInfo
      ? mapPositionFromRepairshopInfo(userRepairshopInfo)
      : undefined;

    // Assume that if the vehicle isn't at the user's repairshop, then the user wants it to be moved there.
    const vehicleDestination =
      !!inventoryRepairshopInfo?.repairshopInfo?.id &&
      !!userRepairshopInfo &&
      inventoryRepairshopInfo?.repairshopInfo?.id !== userRepairshopInfo.id
        ? mapPositionFromRepairshopInfo(userRepairshopInfo)
        : undefined;

    flowContext.updateFormValues((values) => ({
      ...values,
      inventoryId: inventory?.id,
      registrationNumber: inventory?.registrationNumber,
      vehicleOrigin: vehicleOrigin ?? values.vehicleOrigin,
      vehicleDestination: vehicleDestination ?? values.vehicleDestination,
      autoFitMapToMarkers: true,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inventoryResponse.status,
    choosableRepairshopsResponse.status,
    userRepairshopInfoResponse.status,
  ]);

  return (
    <UserFlowDataContext.Provider value={userflowData}>
      <H1>
        {inventoryResponse.response && flowContext.formValues.registrationNumber
          ? `Flytt av '${flowContext.formValues.registrationNumber}'`
          : 'Bärgning/Flytt'}{' '}
        {inventoryResponse.status === RequestStatus.Fetching && (
          <Spinner iconOnly />
        )}
      </H1>
      <TabView
        openTab={openTab}
        onTabClicked={setOpenTab}
        pages={[
          {
            content: (
              <Wrap>
                <UserFlow flowContext={flowContext} />
              </Wrap>
            ),
            tabLabel: 'Beställ bärgning/flytt',
            keepMounted: true,
          },
          {
            content: <OrderHistory />,
            tabLabel: 'Historik',
          },
        ]}
      />
    </UserFlowDataContext.Provider>
  );
};

export default OrderUserFlow;
