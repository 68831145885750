import { InventoryCommentModel } from 'api';
import { ApiResponse } from 'api/hooks/useResponse';
import ManageRequestStatus from 'components/ManageRequestStatus';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Colors, MediaQuery, Sizes } from 'utils/style';
import { SectionTitle } from '../components';
import CommentInput from './CommentInput';
import LogItem from './LogItem';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: ${Sizes.Gutter / 2}px;
  background-color: ${Colors.DarkWhite};
`;

const LogsScroll = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${Sizes.Gutter / 4}px;
  padding: 0 ${Sizes.Gutter / 2}px;
  padding-bottom: ${Sizes.Gutter}px;
  overflow: auto;

  ${MediaQuery.tablet} {
    padding: 0 ${Sizes.Gutter}px;
    padding-bottom: ${Sizes.Gutter}px;
  }
`;

interface Props {
  inventoryId: number;
  logsResponse: ApiResponse<InventoryCommentModel[]>;
  onCommentAdded: () => void;
}

const InventoryLogSection: FC<Props> = ({
  logsResponse,
  inventoryId,
  onCommentAdded,
}) => {
  return (
    <Wrapper>
      <SectionTitle>Kommentarer & logg</SectionTitle>
      <ManageRequestStatus status={[logsResponse.status]}>
        <LogsScroll>
          {logsResponse.response
            ?.sort((a, b) => b.created.getTime() - a.created.getTime())
            .map((log) => (
              <LogItem key={log.id} log={log} />
            ))}
        </LogsScroll>

        <CommentInput
          inventoryId={inventoryId}
          onCommentAdded={onCommentAdded}
        />
      </ManageRequestStatus>
    </Wrapper>
  );
};

export default InventoryLogSection;
