import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';

type QueryUpdate = { name: string; value: string | null; replace: boolean };
const QueryParamUpdatesContext = React.createContext<{
  addQueryUpdate(update: QueryUpdate): void;
}>({
  addQueryUpdate() {},
});

/** This contexts groups together multiple following updates into one, so that they don't overwrite each other */
export const QueryParamUpdatesContextProvider: FC = ({ children }) => {
  const [updatesQueue, setUpdatesQueue] = useState<QueryUpdate[]>([]);

  const history = useHistory();
  const { pathname } = useLocation();
  const params = useQueryParams();

  useEffect(() => {
    if (updatesQueue.length === 0) return;

    const newParams = new URLSearchParams(params);
    let addToHistory = false;

    updatesQueue.forEach(({ name, value, replace }) => {
      addToHistory = addToHistory || !replace;

      if (value === null) {
        newParams.delete(name);
      } else {
        newParams.set(name, value);
      }
    });

    setUpdatesQueue([]);
    if (addToHistory) {
      history.push(`${pathname}?${newParams.toString()}`);
    } else {
      history.replace(`${pathname}?${newParams.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatesQueue]);

  const contextValue = useMemo(
    () => ({
      addQueryUpdate(update: QueryUpdate) {
        setUpdatesQueue((q) => [...q, update]);
      },
    }),
    []
  );
  return (
    <QueryParamUpdatesContext.Provider value={contextValue}>
      {children}
    </QueryParamUpdatesContext.Provider>
  );
};

export const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

type setFunction = (value: string | null, addToHistory?: boolean) => void;
function useQueryParam(
  name: string,
  defaultValue: string
): [string, setFunction];
function useQueryParam(
  name: string,
  defaultValue?: string
): [string | undefined, setFunction];

function useQueryParam(name: string, defaultValue?: string) {
  const { addQueryUpdate } = useContext(QueryParamUpdatesContext);
  const params = useQueryParams();

  const setQueryParam: setFunction = useCallback(
    (value, addToHistory) => {
      addQueryUpdate({ name, value, replace: !addToHistory });
    },
    [name, addQueryUpdate]
  );

  return [params.get(name) ?? defaultValue, setQueryParam] as const;
}

export default useQueryParam;
