import React from 'react';
import { FlowConfig } from 'components/UserFlow/FlowConfig';
import NavigationButtons from 'components/UserFlow/NavigationButtons';
import { MapPosition } from 'containers/OrderPage/OrderUserFlow/MapPage';
import VehicleInfoPage from './VehicleInfoPage';
import SendOrderPage from './SendOrderPage';
import PrefilledMapPage from './PrefilledMapPage';

export interface FormValues {
  vehicleOrigin: MapPosition;
  vehicleDestination: MapPosition;
  registrationNumber?: string;
  name?: string;
  payer: string;
  phoneNumber?: string;
  comment?: string;

  /** undefined if inventoryId !== undefined */
  requestedTime?: string;
  /** null if inventoryId === undefined */
  pickupTime: Date | null;

  feedbackSMS: string[];
  feedbackEmails: string[];
  carkeyLocation?: string;
  carLocation?: string;
  inventoryId?: number;

  // state values
  autoFitMapToMarkers: boolean;
}

const orderFlowConfig = (onOrderSent: () => void): FlowConfig<FormValues> => ({
  pages: [
    {
      page: PrefilledMapPage,
      bottomContent: ({ flowContext }) => (
        <>
          <NavigationButtons
            flowContext={flowContext}
            disableForward={
              !flowContext.formValues.vehicleOrigin.address ||
              !flowContext.formValues.vehicleOrigin.coordinate
            }
          >
            Steg 2: Lämnas
          </NavigationButtons>
        </>
      ),
    },
    {
      page: PrefilledMapPage,
      bottomContent: ({ flowContext }) => (
        <>
          <NavigationButtons
            flowContext={flowContext}
            disableForward={
              !flowContext.formValues.vehicleDestination.address ||
              !flowContext.formValues.vehicleDestination.coordinate
            }
          >
            Steg 3: Fordonsuppgifter
          </NavigationButtons>
        </>
      ),
    },
    {
      page: VehicleInfoPage,
      bottomContent: ({ flowContext }) => {
        const formValues = flowContext.formValues;

        const isFormValid =
          !!formValues.registrationNumber &&
          (formValues.inventoryId ? !!formValues.pickupTime : true);

        return (
          <NavigationButtons
            flowContext={flowContext}
            disableForward={!isFormValid}
          >
            Skicka order
          </NavigationButtons>
        );
      },
      hiddenStep: true,
    },
    {
      page: ({ flowContext }) => (
        <SendOrderPage flowContext={flowContext} onOrderSent={onOrderSent} />
      ),
      bottomContent: undefined,
    },
  ],

  initialFormValues: {
    payer: defaultPayer,
    vehicleOrigin: {
      address: '',
    },
    vehicleDestination: {
      address: '',
    },
    requestedTime: 'Normal (inom 48h)',
    pickupTime: null,
    feedbackEmails: [],
    feedbackSMS: [],

    // state variables
    autoFitMapToMarkers: true,
  },
});

export const defaultPayer = 'Verkstad';

export default orderFlowConfig;
