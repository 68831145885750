import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Colors, Sizes, MediaQuery } from 'utils/style';
import Routes from 'utils/routes';
import ContactBox from './ContactBox';
import { UserMenu } from './UserMenu';
import VisibleForRole from 'components/VisibleForRole';
import { Role } from 'api';
import useUserInfo from 'hooks/useUserInfo';

const Wrapper = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 110vw;
  z-index: 5;
  height: 100vh;
  width: 100vw;
  background: ${Colors.Red};
  color: ${Colors.White};
  transition: left 0.3s;
  padding: ${Sizes.Gutter}px;
  padding-top: 0;
  overflow: auto;

  ${({ visible }) =>
    visible &&
    css`
      left: 0;
    `}

  & a:hover {
    text-decoration: none;
    color: inherit;
  }

  ${MediaQuery.desktop} {
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    height: auto;
    padding: 0;

    position: unset;
    background: transparent;
    color: ${Colors.Black};

    margin-left: ${Sizes.Gutter}px;
  }
`;

const ListItems = styled.div`
  display: flex;
  flex-direction: column;
  ${MediaQuery.desktop} {
    flex-direction: row;
  }
`;

const activeClassName = 'active';
const borderColor = '#FFF8';

const SubMenuLink = styled(NavLink).attrs({ activeClassName })`
  color: inherit;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  box-sizing: border-box;
  padding: ${Sizes.Gutter * 0.5}px 0;

  &:first-child {
    border-top: 1px solid ${borderColor};
  }
  border-bottom: 1px solid ${borderColor};

  ${MediaQuery.desktop} {
    border-top: 3px solid transparent;
    border-bottom: none;
    margin-right: ${Sizes.Gutter}px;
    padding: 0;
    padding-top: ${Sizes.Gutter * 0.5}px;
    font-weight: normal;

    &:hover {
      color: ${Colors.Black};
      text-decoration: underline;
    }

    &:first-child {
      border-top: 3px solid transparent;
    }

    &.${activeClassName} {
      font-weight: bold;
      border-top: 3px solid ${Colors.Red};
    }
  }
`;

const CloseWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  padding-top: ${Sizes.Gutter}px;

  & > button {
    color: inherit;
    background: transparent;
    border: none;
    font-size: 30px;
    margin: 0;
    padding: 0 5px;
    margin-right: -5px;
  }

  ${MediaQuery.desktop} {
    display: none;
  }
`;

const MyContactBox = styled(ContactBox)`
  padding: ${Sizes.Gutter * 2}px 0;

  ${MediaQuery.desktop} {
    display: none;
  }
`;

const MyLoginMenu = styled(UserMenu)`
  padding-bottom: ${Sizes.Gutter * 2}px;
  margin: 0;
  justify-content: space-between;

  ${MediaQuery.desktop} {
    display: none;
  }
`;

interface Props {
  /** will only be used when in mobile css is used */
  visible: boolean;
  onClose(): void;
}

const SubMenu: React.FC<Props> = ({ visible, onClose }) => {
  const userInfo = useUserInfo();
  const customerStagingGroupId = userInfo?.selectedCustomer?.stagingGroupId;

  return (
    <Wrapper visible={visible}>
      <CloseWrap>
        <button onClick={onClose}>
          <i className="las la-times" />
        </button>
      </CloseWrap>
      <MyLoginMenu />

      {!!userInfo && (
        <ListItems onClick={onClose}>
          <SubMenuLink exact to="/">
            Hem
          </SubMenuLink>
          <SubMenuLink to={Routes.Order}>Bärgning/Flytt</SubMenuLink>
          <SubMenuLink to={Routes.Cases}>Ärenden</SubMenuLink>
          {customerStagingGroupId && (
            <SubMenuLink to={Routes.Inventory}>
              Uppställningsplatser
            </SubMenuLink>
          )}
          {/* <SubMenuLink to={Routes.Towers}>Bärgare i området</SubMenuLink> */}
          <SubMenuLink to={Routes.MyRepairshop}>Min Verkstad</SubMenuLink>
          <SubMenuLink to={Routes.RepairshopUsers}>Användare</SubMenuLink>

          <VisibleForRole roles={[Role.Admin]}>
            <SubMenuLink
              to={Routes.AdminCustomersTab}
              isActive={(_match, location) =>
                location.pathname.includes('admin')
              }
            >
              Admin
            </SubMenuLink>
          </VisibleForRole>
        </ListItems>
      )}

      <MyContactBox />
    </Wrapper>
  );
};

export default SubMenu;
