import React from 'react';
import Container from 'components/primitives/Container';
import styled from 'styled-components';
import UsersTable from './UsersTable';
import InvitedUsersTable from './InvitedUsersTable';
import PageHead from 'components/primitives/PageHead';

const MyContainer = styled(Container)``;

const RepairshopUsersPage: React.FC = () => {
  return (
    <>
      <PageHead>Användare</PageHead>
      <MyContainer>
        <UsersTable />

        <InvitedUsersTable />
      </MyContainer>
    </>
  );
};

export default RepairshopUsersPage;
