import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import Arrow from './Arrow';
import useDimensions from '../../hooks/useDimensions';
import PageIndicator from './PageIndicator';
import { Colors, MediaQuery, Sizes } from 'utils/style';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
`;

const SliderWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
`;

const SlideSubtitle = styled.i`
  height: ${Sizes.Gutter / 2}px;
  margin-left: ${Sizes.Gutter / 2}px;
  color: ${Colors.SemiBlack};

  ${MediaQuery.tablet} {
    margin-left: 0;
  }
`;

const MyPageIndicator = styled(PageIndicator)`
  margin-top: ${Sizes.Gutter / 4}px;
  margin-bottom: ${Sizes.Gutter / 2}px;
`;

interface ContentProps {
  translation: number;
  transition: number;
  minHeight: number;
}

const Content = styled.div<ContentProps>`
  transform: translateX(${(props) => -props.translation}px);
  transition: transform ease-out ${(props) => props.transition}s;
  display: flex;
`;

const Slide = styled.img<{ dwidth: number; cover: boolean; hPadding: number }>`
  object-fit: ${({ cover }) => (cover ? 'cover' : 'contain')};
  padding: 0 ${({ hPadding }) => hPadding}px;
  width: ${({ dwidth }) => dwidth}px;
`;

interface Props {
  slides: string[];
  slideSubtitle?: string | string[];
  onSlideClick?(index: number): void;

  /** defines aspect ratio that the images should keep */
  imageWidth: number;
  /** defines aspect ratio that the images should keep */
  imageHeight: number;

  /** if true, automatically get imageWidth and imageHeight from slides[].
   * imageWidth and -Height props becomes the initial values before any images have loaded */
  autoFitImages?: boolean;

  /** if true, images covers all available space,
   * cropping some out if the aspect ratio isn't the same */
  imagesCover?: boolean;

  /** amount of blank space between images */
  imageSeparation?: number;

  /** amount of images to show side by side */
  imageCount?: number;
}

const Slider = (props: Props) => {
  const {
    slides,
    slideSubtitle,
    onSlideClick,
    imageHeight,
    imageWidth,
    imageCount = 1,
    autoFitImages,
    imagesCover,
    imageSeparation,
  } = props;

  const [wrapperRef, wrapperDimensions] = useDimensions<HTMLDivElement>(200);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const preloadedImages = useRef<HTMLImageElement[]>([]);
  const imageCountRef = useRef(imageCount);
  imageCountRef.current = imageCount;

  const [state, setState] = useState({
    activeIndex: 0,
    transition: 0.45,
    maxImageWidth: imageWidth,
    maxImageHeight: imageHeight,
  });
  const { transition, activeIndex, maxImageHeight, maxImageWidth } = state;

  // preload images and get their dimensions if props.autoFitImages
  useEffect(() => {
    setState((state) => ({
      ...state,
      maxImageWidth: imageWidth,
      maxImageHeight: imageHeight,
    }));

    for (let image of slides) {
      const toLoad = new Image();
      preloadedImages.current.push(toLoad);
      toLoad.src = image;

      if (!autoFitImages) {
        continue;
      }

      toLoad.onload = function () {
        const anyThis = this as any;
        const { width, height } = anyThis;

        setState((state) => {
          let { maxImageWidth, maxImageHeight } = state;
          if (width > maxImageWidth) {
            maxImageWidth = width;
          }
          if (height > maxImageHeight) {
            maxImageHeight = height;
          }
          return { ...state, maxImageHeight, maxImageWidth };
        });
      };
    }
  }, [slides, imageWidth, imageHeight, autoFitImages]);

  const startX = useRef<number>(0);
  const currX = useRef<number>(0);

  // because the handle functions doesn't get state updates
  const activeIndexRef = useRef<number>(state.activeIndex);
  activeIndexRef.current = activeIndex;
  const wrapperDimensionsRef =
    useRef<typeof wrapperDimensions>(wrapperDimensions);
  wrapperDimensionsRef.current = wrapperDimensions;

  useEffect(() => {
    const capturedContentRef = contentRef.current;

    capturedContentRef!.addEventListener('touchstart', handleTouchStart);
    capturedContentRef!.addEventListener('touchmove', handleTouchMove);
    capturedContentRef!.addEventListener('touchend', handleTouchEnd);

    return () => {
      capturedContentRef!.removeEventListener('touchend', handleTouchEnd);
      capturedContentRef!.removeEventListener('touchmove', handleTouchMove);
      capturedContentRef!.removeEventListener('touchstart', handleTouchStart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef.current]);

  const handleTouchStart = (eve: TouchEvent) => {
    startX.current = eve.touches[0].clientX;
    currX.current = eve.touches[0].clientX;
  };

  const handleTouchMove = (eve: TouchEvent) => {
    currX.current = eve.touches[0].clientX;
    contentRef.current!.style.transform = `translateX(${-calcRefTranslation()}px)`;
    contentRef.current!.style.transition = 'none';
  };

  const handleTouchEnd = (eve: TouchEvent) => {
    contentRef.current!.style.transition = '';
    contentRef.current!.style.transform = '';
    setState((state) => ({
      ...state,
      activeIndex: translationToActiveIndex(calcRefTranslation()),
    }));
  };

  const calcRefTranslation = () =>
    getTranslation(activeIndexRef.current) + (startX.current - currX.current);

  //width of the container
  const getWidth = () => {
    if (!wrapperDimensionsRef.current || !wrapperDimensionsRef.current.width) {
      return 0;
    }
    return wrapperDimensionsRef.current.width;
  };

  const getImageWidth = () =>
    getWidth() / imageCountRef.current + getImageHPadding();

  const getImageHeight = () =>
    (maxImageHeight / maxImageWidth) * getImageWidth();

  const translationToActiveIndex = (translation: number) =>
    Math.min(
      Math.max(0, Math.round(translation / getImageWidth())),
      slides.length - 1
    );

  const getTranslation = (activeIndex: number) =>
    activeIndex * getImageWidth() + getImageHPadding();

  const getImageHPadding = () =>
    imageCountRef.current === 1 ? 0 : (imageSeparation || 0) / 2;

  const nextSlide = (e: any) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    return setState({
      ...state,
      activeIndex: activeIndex >= slides.length - 1 ? 0 : activeIndex + 1,
    });
  };

  const prevSlide = (e: any) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setState({
      ...state,
      activeIndex: activeIndex <= 0 ? slides.length - 1 : activeIndex - 1,
    });
  };

  return (
    <Wrapper>
      {slides.length > 1 && <Arrow direction="left" handleClick={prevSlide} />}
      <SliderWrapper ref={wrapperRef}>
        <Content
          translation={getTranslation(activeIndex)}
          transition={transition}
          minHeight={getImageHeight()}
          ref={contentRef}
        >
          {slides.map((slide, i) => (
            <Slide
              dwidth={getImageWidth()}
              key={slide + i}
              src={slide}
              cover={!!imagesCover}
              hPadding={getImageHPadding()}
              onClick={onSlideClick ? () => onSlideClick(i) : undefined}
            />
          ))}
        </Content>
      </SliderWrapper>
      {slides.length > 1 && <Arrow direction="right" handleClick={nextSlide} />}
      <SlideSubtitle>
        {slideSubtitle &&
          (slideSubtitle instanceof Array
            ? slideSubtitle[activeIndex] ?? ''
            : slideSubtitle)}
      </SlideSubtitle>

      {slides.length > 1 && (
        <MyPageIndicator
          pages={slides.length}
          currentPage={activeIndex}
          onPageClick={(index: number) =>
            setState((state) => ({ ...state, activeIndex: index }))
          }
        />
      )}
    </Wrapper>
  );
};

export default Slider;
