import { InventoryClient, InventoryCommentModel } from 'api';
import useApiCall, { RequestStatus } from 'api/hooks/useApiCall';
import { TextArea } from 'components/form';
import Button from 'components/primitives/Button';
import Spinner from 'components/Spinner';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { MediaQuery, Sizes } from 'utils/style';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${Sizes.Gutter / 2}px;
  padding-bottom: ${Sizes.Gutter}px;
  gap: ${Sizes.Gutter / 2}px;

  ${MediaQuery.tablet} {
    padding: 0 ${Sizes.Gutter}px;
    padding-bottom: ${Sizes.Gutter}px;
  }
`;

const MyTextArea = styled(TextArea)`
  resize: vertical;
  min-height: 3em;
`;

const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;

interface Props {
  inventoryId: number;
  onCommentAdded: () => void;
}

const CommentInput: FC<Props> = ({ inventoryId, onCommentAdded }) => {
  const [editBuffer, setEditBuffer] = useState('');
  const saveCommentCall = useApiCall(
    new InventoryClient(),
    (c, inventoryId: number, comment: InventoryCommentModel) =>
      c.saveComment(inventoryId, comment)
  );

  const handleSaveClick = async () => {
    const [, error] = await saveCommentCall.run(
      inventoryId,
      new InventoryCommentModel({
        created: new Date(),
        text: editBuffer,
        id: 0,
        inventoryId,
      })
    );

    if (!error) {
      onCommentAdded();
    }
  };

  const isSaving = saveCommentCall.status === RequestStatus.Fetching;

  return (
    <Wrapper>
      <MyTextArea
        placeholder="Skriv en kommentar..."
        value={editBuffer}
        onChange={(e) => {
          setEditBuffer(e.target.value);
        }}
        disabled={isSaving}
      />
      <ActionsRow>
        <Button
          small
          disabled={isSaving || !editBuffer.length}
          onClick={handleSaveClick}
        >
          {isSaving ? (
            <>
              <Spinner light iconOnly /> Skickar...
            </>
          ) : (
            'Skicka'
          )}
        </Button>
      </ActionsRow>
    </Wrapper>
  );
};

export default CommentInput;
