import { InputWrapper, Label, TextArea } from 'components/form';
import React, { useState } from 'react';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useApiCall from 'api/hooks/useApiCall';
import {
  CreateInventoryTransferRequest,
  InventoryClient,
  InventoryCommentModel,
  InventoryModel,
  StagingLotClient,
} from 'api';
import ButtonsModal from 'components/Modal/ButtonsModal';
import useUserInfo from 'hooks/useUserInfo';
import Spinner from 'components/Spinner';
import StagingLotTimeSlotDatePicker from 'components/StagingLotTimeSlotDatePicker';
import useResponse from 'api/hooks/useResponse';

interface OwnTransferFormValues {
  plannedDeliveryToRepairShop: Date | null;
  comment: string;
}

interface Props {
  inventory: InventoryModel;
  onClose: () => void;
  onOwnTransferBooked: () => void;
}

const OwnTransferModal: FC<Props> = ({
  inventory,
  onClose,
  onOwnTransferBooked,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const stagingLotResponse = useResponse(
    new StagingLotClient(),
    (c) => c.get(inventory.stagingLotId),
    [inventory.stagingLotId]
  );

  const userInfo = useUserInfo();
  const destinationRepairShop = userInfo?.memberOfActiveCustomers?.find(
    (customer) => customer.id === userInfo.selectedCustomerId
  );

  const form = useForm<OwnTransferFormValues>({});

  const createTransferCall = useApiCall(
    new InventoryClient(),
    (c, inventoryId: number, createRequest: CreateInventoryTransferRequest) =>
      c.createTransfer(inventoryId, createRequest)
  );

  const addCommentApiCall = useApiCall(
    new InventoryClient(),
    (c, inventoryId: number, comment: InventoryCommentModel) =>
      c.saveComment(inventoryId, comment)
  );

  const handleSave = async (formValues: OwnTransferFormValues) => {
    if (
      inventory.departmentType === undefined ||
      !formValues.plannedDeliveryToRepairShop
    ) {
      return;
    }

    if (!destinationRepairShop?.repairshopId) {
      return;
    }

    const createRequest = new CreateInventoryTransferRequest({
      fromRepairShopId: inventory.repairShopId,
      toRepairShopId: destinationRepairShop.repairshopId,
      plannedDeliveryToRepairShop: formValues.plannedDeliveryToRepairShop,
      toStagingLot: false,
    });

    setIsLoading(true);
    const [transferResponse, transferError] = await createTransferCall.run(
      inventory.id,
      createRequest
    );

    if (transferResponse && formValues.comment.trim().length > 0) {
      const commentRequest = new InventoryCommentModel({
        id: 0,
        text: formValues.comment,
        inventoryId: inventory.id,
        created: new Date(),
      });

      await addCommentApiCall.run(inventory.id, commentRequest);
    }
    setIsLoading(false);
    if (transferResponse && !transferError) {
      onOwnTransferBooked();
      onClose();
    }
  };

  return (
    <ButtonsModal
      title="Egen flytt"
      buttons={[
        {
          label: 'Avbryt',
          disabled: isLoading,
        },
        {
          label: 'Boka',
          onClick: form.handleSubmit(handleSave),
          disabled:
            !form.formState.isDirty || !form.formState.isValid || isLoading,
          dontPopAfterClick: true,
        },
      ]}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <InputWrapper>
            <Label>Flytta till</Label>
            <span>{destinationRepairShop?.displayName}</span>
          </InputWrapper>
          <InputWrapper>
            <Label requiredStar>Upphämtningstid</Label>
            <Controller
              control={form.control}
              name={'plannedDeliveryToRepairShop'}
              rules={{ required: true }}
              render={({ field }) => (
                <StagingLotTimeSlotDatePicker
                  stagingLotResponse={stagingLotResponse}
                  onChange={(date) => field.onChange(date)}
                  value={field.value}
                  ref={field.ref}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Kommentar</Label>
            <TextArea
              placeholder="Skriv här..."
              {...form.register('comment')}
            />
          </InputWrapper>
        </>
      )}
    </ButtonsModal>
  );
};

export default OwnTransferModal;
