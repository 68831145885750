import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'utils/style';

const Wrapper = styled.label`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
`;

const Input = styled.input`
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
` as FC<React.HTMLProps<HTMLInputElement>>;

export const checkBoxStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  padding: 0;
  border: 1px solid ${Colors.Gray};
  border-radius: 0;
  background-color: ${Colors.White};
`;

const RenderedCheckbox = styled.div<{ padRight?: boolean }>`
  ${checkBoxStyle}

  ${({ padRight }) =>
    padRight &&
    css`
      margin-right: 10px;
    `}

  input + & {
    color: currentColor;

    & > * {
      opacity: 0;
    }
  }

  input:checked + & {
    background-color: ${Colors.Selected};
    border-color: ${Colors.Selected};
    color: ${Colors.White};

    & > * {
      opacity: 1;
    }
  }

  input:disabled + & {
    opacity: 0.5;
    background-color: inherit;
  }

  input:focus + & {
    border: 2px solid ${Colors.Selected};
  }

  input:disabled:checked + & {
    background-color: ${Colors.Gray};
    border-color: ${Colors.Gray};
    color: ${Colors.White};
  }

  input + &.halfChecked {
    background-color: ${Colors.DarkGray};

    & > * {
      opacity: 1;
    }
  }
`;

interface Props extends React.HTMLProps<HTMLInputElement> {
  halfChecked?: boolean;
}

const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      type,
      children,
      className,
      halfChecked,
      onClick,
      readOnly,
      ...inputProps
    },
    ref
  ) => {
    return (
      <Wrapper className={className} onClick={onClick as any}>
        <Input
          {...inputProps}
          ref={ref}
          disabled={inputProps.disabled || readOnly}
          type="checkbox"
        />
        <RenderedCheckbox
          padRight={!!children}
          className={halfChecked ? 'halfChecked' : ''}
        >
          {halfChecked ? (
            <FontAwesomeIcon icon={faMinus} size={'sm'} />
          ) : (
            <FontAwesomeIcon icon={faCheck} size={'sm'} />
          )}
        </RenderedCheckbox>
        {children}
      </Wrapper>
    );
  }
);

export default Checkbox;
