import React, { FunctionComponent, useState } from 'react';
import ButtonsModal, {
  modalContainerClass,
} from 'components/Modal/ButtonsModal';
import { CustomerAdminClient, CustomerPreviewModel } from 'api';
import { Input, Select } from 'components/form';
import styled from 'styled-components';
import { Sizes } from 'utils/style';
import useApiCall, { RequestStatus } from 'api/hooks/useApiCall';
import useModalStack from 'components/Modal/useModalStack';
import Spinner from 'components/Spinner';
import { statisticsDistanceInKmOptions } from './constants';

const MyModal = styled(ButtonsModal)`
  .${modalContainerClass} {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    min-height: 200px;
  }
`;

const MyInput = styled(Input)`
  margin-bottom: ${Sizes.Gutter / 2}px;
`;

interface Props {
  onCustomerAdded(customer: CustomerPreviewModel): void;
}

const AddCustomerModal: FunctionComponent<Props> = ({ onCustomerAdded }) => {
  const [name, setName] = useState('');
  const [repairshopId, setRepairshopId] = useState('');
  const [orgNr, setOrgNr] = useState('');
  const [statisticsDistanceInKm, setStatisticsDistanceInKm] = useState(
    statisticsDistanceInKmOptions[0]
  );

  const popModal = useModalStack().pop;

  const repairshopIdNumber = Number(repairshopId);
  const createCustomerCall = useApiCall(
    new CustomerAdminClient(),
    (client) =>
      client.createCustomer(
        repairshopIdNumber,
        name,
        orgNr,
        statisticsDistanceInKm
      ),
    { popModalBeforeError: true }
  );

  const createCustomer = async () => {
    const [response, error] = await createCustomerCall.run();

    if (!error && response) {
      popModal();
      onCustomerAdded(response);
    }
  };

  switch (createCustomerCall.status) {
    case RequestStatus.Idle:
      return (
        <MyModal
          title="Lägg till kund"
          buttons={[
            {
              label: 'Lägg till',
              disabled:
                !name ||
                repairshopId === '' ||
                isNaN(repairshopIdNumber) ||
                !orgNr,
              onClick: createCustomer,
              dontPopAfterClick: true,
            },
            { label: 'Avbryt' },
          ]}
        >
          <b>Visningsnamn</b>
          <MyInput value={name} onChange={(eve) => setName(eve.target.value)} />

          <b>Verkstads-id</b>
          <MyInput
            type="text"
            value={repairshopId}
            onChange={(eve) =>
              setRepairshopId(eve.target.value.replace(/\D/g, ''))
            }
          />

          <b>Organisationsnummer</b>
          <MyInput
            value={orgNr}
            onChange={(eve) => setOrgNr(eve.target.value)}
          />

          <b>Statistik - avståndsavgränsning</b>
          <Select
            value={statisticsDistanceInKm}
            onChange={(eve) =>
              setStatisticsDistanceInKm(Number(eve.target.value))
            }
          >
            {statisticsDistanceInKmOptions.map((v) => (
              <option key={v} value={v}>
                {v} km
              </option>
            ))}
          </Select>
        </MyModal>
      );

    case RequestStatus.Fetching:
    default:
      // Modal should be popped when not Idle or Fetching
      return (
        <MyModal buttons={[]}>
          <Spinner>Lägger till kund...</Spinner>
        </MyModal>
      );
  }
};

export default AddCustomerModal;
