import React from 'react';
import styled from 'styled-components';
import { FlowContext } from './FlowConfig';
import BottomSection from './BottomSection';
import StepIndicator from './StepIndicator';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  min-height: 100%;
`;

interface Props<FormValues extends object> {
  flowContext: FlowContext<FormValues>;
}

const UserFlow = <FormValues extends object>({
  flowContext,
}: Props<FormValues>) => {
  const { currentFlow, currentPageIndex } = flowContext;

  const currentPageSetting = currentFlow.pages[currentPageIndex];
  const CurrentPage = currentPageSetting && currentPageSetting.page;
  const CurrentBottomContent =
    currentPageSetting && currentPageSetting.bottomContent;

  return (
    <Wrapper>
      {CurrentPage && <CurrentPage flowContext={flowContext} />}
      {CurrentBottomContent && (
        <BottomSection>
          <CurrentBottomContent flowContext={flowContext} />
          <StepIndicator
            currentStep={currentPageIndex}
            stepCount={currentFlow.pages.filter(p => !p.hiddenStep).length}
          />
        </BottomSection>
      )}
    </Wrapper>
  );
};

export default UserFlow;
