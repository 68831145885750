import styled from 'styled-components';
import { Colors, FontSizes } from '../../utils/style';

const Text = styled.div`
  color: ${Colors.Black};
  font-size: ${FontSizes.Normal}px;
  white-space: pre-wrap;
`;

export default Text;
