import React, { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';

import { Sizes, FontSizes, Colors, MediaQuery } from 'utils/style';
import Routes from 'utils/routes';
import useUserInfo from 'hooks/useUserInfo';
import { inputStyle } from 'components/form';
import { RequestStatus } from 'api/hooks/useApiCall';
import { Spinner } from 'reactstrap';
import useSetSelectedCustomer from 'hooks/useSetSelectedCustomer';
import selectArrow from 'assets/selectArrow.svg';
import Popover, { SelectItem, SelectItems } from 'components/Popover';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${MediaQuery.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

const CustomerSelectWrapper = styled.div`
  position: relative;

  background: ${Colors.White};
  font-size: 24px;
  color: ${Colors.Black};
  border: 1px solid ${Colors.Gray};

  i {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    left: 5px;
    top: 0;
    bottom: 0;
  }
`;

const SelectTrigger = styled.button`
  ${inputStyle}
  min-width: 0;
  padding-right: calc(${(Sizes.Gutter / 3) * 3}px + 1.5em);
  background-image: url('${selectArrow}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;

  position: relative;
  z-index: 2;
  padding-left: 35px;

  border: 0;
  background-color: transparent;
`;

const SelectSearchInput = styled.input`
  ${inputStyle}
  border: 2px solid ${Colors.Gray};
`;

const UserLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${Sizes.Gutter}px;

  ${MediaQuery.tablet} {
    margin-right: ${Sizes.Gutter}px;
    &:not(:first-child) {
      margin: 0 ${Sizes.Gutter}px;
    }
  }
`;

const UserLink = styled.a`
  color: inherit;
  font-size: ${FontSizes.Large}px;
`;

interface Props {
  className?: string;
}

export const UserMenu: FunctionComponent<Props> = ({ className }) => {
  const userInfo = useUserInfo();
  const [setSelectedCustomer, changeCustomerStatus] = useSetSelectedCustomer();
  const [searchString, setSearchString] = React.useState('');

  const selectableCustomers = useMemo(
    () =>
      userInfo?.memberOfCustomers
        ?.filter((customer) => customer.isActive)
        .sort((a, b) =>
          (a.displayName ?? '').localeCompare(b.displayName ?? '')
        ) ?? [],
    [userInfo]
  );

  const selectedCustomer = useMemo(
    () =>
      selectableCustomers.find(
        (customer) => customer.id === userInfo?.selectedCustomerId
      ),
    [selectableCustomers, userInfo]
  );

  const filteredCustomers = useMemo(() => {
    const lowerSearchString = searchString.toLowerCase();

    return selectableCustomers.filter((customer) =>
      customer.displayName?.toLowerCase().includes(lowerSearchString)
    );
  }, [selectableCustomers, searchString]);

  if (!userInfo) {
    return (
      <Wrapper className={className}>
        <UserLinkWrapper>
          <UserLink href={Routes.Logout}>Logga in</UserLink>
        </UserLinkWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={className}>
      {changeCustomerStatus === RequestStatus.Fetching ? (
        <Spinner />
      ) : (
        <CustomerSelectWrapper>
          <i className="las la-warehouse" />
          <Popover
            noPadding
            content={
              <>
                <SelectSearchInput
                  placeholder="Sök..."
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />

                <SelectItems>
                  {filteredCustomers.length === 0 ? (
                    <SelectItem disabled>
                      <i>Ingen kund hittades</i>
                    </SelectItem>
                  ) : (
                    filteredCustomers.map((customer) => (
                      <SelectItem
                        key={customer.id}
                        selected={customer.id === userInfo.selectedCustomerId}
                        onClick={() => setSelectedCustomer(customer.id)}
                      >
                        {customer.displayName}
                      </SelectItem>
                    ))
                  )}
                </SelectItems>
              </>
            }
          >
            <SelectTrigger>
              {selectedCustomer?.displayName ?? 'Ingen kund vald'}
            </SelectTrigger>
          </Popover>
        </CustomerSelectWrapper>
      )}

      <UserLinkWrapper>
        <UserLink href={Routes.UserManage}>{userInfo.displayName}</UserLink>
        <UserLink href={Routes.Logout}>Logga ut</UserLink>
      </UserLinkWrapper>
    </Wrapper>
  );
};
