import H1 from 'components/primitives/H1';
import React, { FunctionComponent } from 'react';
import CustomersTab from './CustomersTab';
import { Switch, Route } from 'react-router';
import TabStrip from 'components/TabView/TabStrip';
import Routes from 'utils/routes';
import StationsTab from './StationsTab';
import UsersTab from './UsersTab';

const AdminPage: FunctionComponent = () => (
  <>
    <H1>Admin</H1>

    <TabStrip
      tabs={[
        { label: 'Kunder', linkTo: Routes.AdminCustomersTab },
        { label: 'Stationer', linkTo: Routes.AdminStationsTab },
        { label: 'Användare', linkTo: Routes.AdminUsersTab },
      ]}
    />

    <Switch>
      <Route path={Routes.AdminCustomersTab} component={CustomersTab} />
      <Route path={Routes.AdminStationsTab} component={StationsTab} />
      <Route path={Routes.AdminUsersTab} component={UsersTab} />
    </Switch>
  </>
);

export default AdminPage;
