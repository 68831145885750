import styled from 'styled-components';
import { Sizes, MediaQuery } from 'utils/style';

const Container = styled.div`
  padding: 0 ${Sizes.Gutter / 2}px;

  ${MediaQuery.tablet} {
    padding: 0px ${Sizes.Gutter}px;
  }
`;

export default Container;
