import { useState, useEffect } from 'react';

function getIsWindowDimensionBetween(
  min?: number,
  max?: number,
  dimension: 'width' | 'height' = 'width'
) {
  if (dimension === 'width') {
    return (
      window.innerWidth >= (min ?? 0) && window.innerWidth <= (max ?? Infinity)
    );
  } else if (dimension === 'height') {
    return (
      window.innerHeight >= (min ?? 0) &&
      window.innerHeight <= (max ?? Infinity)
    );
  }

  throw new Error(`Invalid dimension: ${dimension}`);
}

const useWindowDimensionIsBetween = (
  min?: number,
  max?: number,
  dimension: 'width' | 'height' = 'width'
) => {
  const [isBetween, setIsBetween] = useState<boolean>(
    getIsWindowDimensionBetween(min, max, dimension)
  );

  useEffect(() => {
    const handleResize = () => {
      setIsBetween(getIsWindowDimensionBetween(min, max, dimension));
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dimension, max, min]);

  return isBetween;
};

export default useWindowDimensionIsBetween;
