import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Colors, MediaQuery, Sizes } from 'utils/style';
import { NavLink } from 'react-router-dom';

const TabWrap = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${Colors.Gray};

  ${MediaQuery.tablet} {
    padding-left: ${Sizes.Gutter}px;
  }
`;

const Tab = styled.button<{ isActiveTab?: boolean }>`
  background: transparent;
  border: none;
  margin: 0;
  padding: ${Sizes.Gutter / 4}px ${Sizes.Gutter / 2}px;
  color: ${Colors.Black};

  ${({ isActiveTab }) =>
    isActiveTab &&
    css`
      background: ${Colors.Black};
      color: ${Colors.White};
    `}
`;

const activeClass = 'active';
const TabLink = styled(NavLink)`
  &,
  &:hover,
  &:active,
  &:visited {
    display: inline-block;
    background: transparent;
    border: none;
    margin: 0;
    padding: ${Sizes.Gutter / 4}px ${Sizes.Gutter / 2}px;

    color: ${Colors.Black};
    text-decoration: none;

    &.${activeClass} {
      background: ${Colors.Black};
      color: ${Colors.White};
    }
  }
`;

interface TabSetting {
  label: string;
  linkTo?: string;
}

interface Props {
  tabs: TabSetting[];
  openTab?: number;
  onTabClicked?(tabIndex: number): void;
}

const TabStrip: FunctionComponent<Props> = ({
  tabs,
  openTab,
  onTabClicked,
}) => (
  <TabWrap>
    {tabs.map((tab, index) =>
      tab.linkTo ? (
        <TabLink
          key={index}
          onClick={onTabClicked ? () => onTabClicked(index) : undefined}
          to={tab.linkTo}
          activeClassName={activeClass}
        >
          {tab.label}
        </TabLink>
      ) : (
        <Tab
          key={index}
          isActiveTab={index === openTab}
          onClick={onTabClicked ? () => onTabClicked(index) : undefined}
        >
          {tab.label}
        </Tab>
      )
    )}
  </TabWrap>
);

export default TabStrip;
