import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Colors, FontSizes, Sizes, MediaQuery } from 'utils/style';
import Button from './Button';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  background: ${Colors.DarkWhite};

  ${MediaQuery.tablet} {
    grid-template-columns: 1fr auto;
    padding: ${Sizes.Gutter}px;

    background: transparent;
  }
`;

const Title = styled.h1<{ hasBackLink: boolean; hasActions: boolean }>`
  grid-row: 2 / 3;
  padding: 0;
  margin: 0;
  padding: ${Sizes.Gutter / 2}px;

  ${({ hasBackLink }) =>
    hasBackLink &&
    css`
      padding-top: 0;
    `}
  ${({ hasActions }) =>
    hasActions &&
    css`
      padding-bottom: ${Sizes.Gutter / 4}px;
    `}

  font-size: ${FontSizes.Huge}px;
  font-weight: bold;

  ${MediaQuery.tablet} {
    grid-row: 1 / 2;
    padding: 0;

    color: ${Colors.Black};
    font-size: ${FontSizes.Heading}px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const Actions = styled.div`
  grid-row: 3 / 4;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: ${Sizes.Gutter / 2}px;
  padding-top: 0;

  ${MediaQuery.tablet} {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    margin-top: 0;
    padding: 0;

    border-bottom: 0;
  }
`;

const ActionButton = styled(Button)`
  &:not(:first-child) {
    margin-left: ${Sizes.Gutter / 2}px;
  }
`;

const BackLinkWrap = styled.div`
  grid-row: 1 / 2;
  padding: ${Sizes.Gutter / 2}px;
  padding-bottom: 0;

  ${MediaQuery.tablet} {
    grid-row: 2 / 3;
    padding: 0;
  }
`;

const BackLink = styled(Link)`
  &,
  &:hover,
  &:active {
    font-weight: 600;
    color: ${Colors.Red};
  }
`;

interface Action {
  label: React.ReactChild;
  onClick(): void;
  disabled?: boolean;
}

interface Props {
  backTo?: string;
  backToLabel?: string;
  actions?: Action[];
}

const PageHead: FunctionComponent<Props> = ({
  backTo,
  backToLabel,
  actions,
  children,
}) => (
  <Wrapper>
    <Title hasBackLink={!!backTo} hasActions={!!actions && actions.length > 0}>
      {children}
    </Title>
    {actions && actions.length > 0 && (
      <Actions>
        {actions?.map((action, i) => (
          <ActionButton
            small
            onClick={action.onClick}
            disabled={action.disabled}
            key={i}
          >
            {action.label}
          </ActionButton>
        ))}
      </Actions>
    )}

    {backTo && (
      <BackLinkWrap>
        <BackLink to={backTo}>
          <i className="las la-angle-left" /> {backToLabel ?? ''}
        </BackLink>
      </BackLinkWrap>
    )}
  </Wrapper>
);

export default PageHead;
