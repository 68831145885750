import React, { useMemo } from 'react';
import { FC } from 'react';
import {
  AddInventoryFormValues,
  AddInventoryFormValuesToCreateInventoryRequest,
  defaultAddInventoryFormValues,
} from './addInventoryFormConverter';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import ValidationMessage from '../../../components/form/ValidationMessage';
import {
  CreateInventoryRequest,
  DepartmentType,
  InventoryClient,
  InventoryModel,
  InventoryStatus,
  StagingLotClient,
} from 'api';
import useAppTexts from 'hooks/useAppTexts';
import styled from 'styled-components';
import { InputWrapper, Label, Select, TextArea } from 'components/form';
import UniqueRegNrInput from './UniqueRegNrInput';
import useResponse from 'api/hooks/useResponse';
import Spinner from 'components/Spinner';
import useApiCall, { RequestStatus } from 'api/hooks/useApiCall';
import { MediaQuery, Sizes } from 'utils/style';
import useUserInfo from 'hooks/useUserInfo';
import ButtonsModal from 'components/Modal/ButtonsModal';
import StagingLotTimeSlotDatePicker from 'components/StagingLotTimeSlotDatePicker';
import { generatePath, useHistory } from 'react-router';
import Routes, { RouteParams } from 'utils/routes';

const MyButtonsModal = styled(ButtonsModal)`
  width: 100%;

  ${MediaQuery.tablet} {
    width: 410px;
  }
`;

const ModalSpinner = styled(Spinner)`
  margin-top: ${Sizes.Gutter}px;
`;

interface Props {
  onClose: (newInventory?: InventoryModel) => void;
}

const AddInventoryModal: FC<Props> = ({ onClose }) => {
  const history = useHistory();
  const { departmentTypes, inventoryStatuses } = useAppTexts();

  const user = useUserInfo();

  const stagingLotGroupId = user?.memberOfActiveCustomers?.find(
    (m) => m.id === user.selectedCustomerId
  )?.stagingGroupId;

  const stagingLotsResponse = useResponse(new StagingLotClient(), (c) =>
    c.getStagingLotsByGroupId(stagingLotGroupId ?? 0)
  );

  const createInventoryApiCall = useApiCall(
    new InventoryClient(),
    (c, updateRequest: CreateInventoryRequest) => c.create(updateRequest)
  );

  const statusOptions = [
    { status: InventoryStatus.New },
    { status: InventoryStatus.InTransitToStagingLot },
    { status: InventoryStatus.StandBy },
  ];

  const form = useForm<AddInventoryFormValues>({
    defaultValues: defaultAddInventoryFormValues,
  });

  const handleSave = async (formValues: AddInventoryFormValues) => {
    const updateRequest =
      AddInventoryFormValuesToCreateInventoryRequest(formValues);

    const [response, error] = await createInventoryApiCall.run(updateRequest);
    if (!error) {
      onClose(response);
    }
  };

  const selectedStatus = useWatch({
    control: form.control,
    name: 'status',
  });

  const usePlannedDate = useMemo(() => {
    return selectedStatus !== InventoryStatus.StandBy;
  }, [selectedStatus]);

  const destinationRepairShopId = useWatch({
    control: form.control,
    name: 'repairShopId',
  });

  const { isDirty } = form.formState;

  return (
    <MyButtonsModal
      title="Lägg till uppställt fordon"
      buttons={[
        {
          label: (
            <>
              <FontAwesomeIcon icon={faUndo} /> Ångra ändringar
            </>
          ),
          variant: 'secondary',
          onClick: () => form.reset(),
          dontPopAfterClick: true,
          hide: !isDirty,
        },
        {
          disabled: !isDirty,
          label: 'Spara',
          onClick: form.handleSubmit(handleSave),
          dontPopAfterClick: true,
        },
        {
          label: 'Avbryt',
          dontPopAfterClick: true,
          onClick: () => onClose(),
        },
      ]}
    >
      {stagingLotsResponse.status === RequestStatus.Fetching ? (
        <ModalSpinner />
      ) : (
        <>
          <InputWrapper>
            <ValidationMessage control={form.control} name="registrationNumber">
              <Controller
                control={form.control}
                name="registrationNumber"
                render={({ field }) => (
                  <>
                    <Label requiredStar>Regnummer</Label>
                    <UniqueRegNrInput
                      registrationNumber={field.value}
                      onChange={field.onChange}
                      onOpenExistingInventory={(id) => {
                        onClose();
                        history.push(
                          generatePath(Routes.InventoryItem, {
                            [RouteParams.inventoryItemId]: id,
                          })
                        );
                      }}
                      ref={field.ref}
                    />
                  </>
                )}
                rules={{
                  required: 'Ange ett registreringsnummer',
                }}
              />
            </ValidationMessage>
          </InputWrapper>

          <InputWrapper>
            <Label requiredStar>Status</Label>
            <Select
              {...form.register('status', {
                valueAsNumber: true,
                onChange: (e) => {
                  form.resetField('plannedDeliveryToStagingLot');
                  form.resetField('actualDeliveryToStagingLot');
                },
              })}
            >
              {statusOptions.map((option) => (
                <option key={option.status} value={option.status}>
                  {inventoryStatuses[option.status]}
                </option>
              ))}
            </Select>
          </InputWrapper>
          <InputWrapper>
            <ValidationMessage control={form.control} name="repairShopId">
              <Label requiredStar>Plats</Label>
              <Select
                {...form.register('repairShopId', {
                  valueAsNumber: true,
                  validate: (value) => (value === -1 ? 'Välj en plats' : true),
                })}
              >
                <option key={-1} value={-1} disabled>
                  Välj...
                </option>
                {stagingLotsResponse.response?.map((stagingLot) => (
                  <option
                    key={stagingLot.repairShopId}
                    value={stagingLot.repairShopId}
                  >
                    {stagingLot.customerDisplayName}
                  </option>
                ))}
              </Select>
            </ValidationMessage>
          </InputWrapper>
          <InputWrapper>
            <ValidationMessage
              control={form.control}
              name={
                usePlannedDate
                  ? 'plannedDeliveryToStagingLot'
                  : 'actualDeliveryToStagingLot'
              }
            >
              <Label requiredStar>
                {usePlannedDate ? 'Planerad ankomsttid' : 'Ankomsttid'}
              </Label>
              <Controller
                control={form.control}
                name={
                  usePlannedDate
                    ? 'plannedDeliveryToStagingLot'
                    : 'actualDeliveryToStagingLot'
                }
                disabled={form.getValues('repairShopId') === -1}
                rules={{
                  required: usePlannedDate
                    ? 'Ange en planerad ankomsttid'
                    : 'Ange en ankomsttid',
                }}
                render={({ field }) => {
                  const selectedStagingLot = stagingLotsResponse.response?.find(
                    (sl) => sl.repairShopId === destinationRepairShopId
                  );

                  return (
                    <StagingLotTimeSlotDatePicker
                      onChange={(date) => field.onChange(date)}
                      value={field.value}
                      disabled={form.getValues('repairShopId') === -1}
                      ref={field.ref}
                      stagingLotResponse={{
                        status: stagingLotsResponse.status,
                        response: selectedStagingLot?.stagingLotGroupId
                          ? selectedStagingLot
                          : null,
                      }}
                    />
                  );
                }}
              />
            </ValidationMessage>
          </InputWrapper>
          <InputWrapper>
            <ValidationMessage control={form.control} name="departmentType">
              <Label>Verkstadstyp</Label>
              <Select
                {...form.register('departmentType', {
                  valueAsNumber: true,
                })}
              >
                <option key={-1} value={-1}>
                  {' '}
                </option>

                {Object.values(DepartmentType)
                  .filter((v): v is DepartmentType => typeof v === 'number')
                  .map((v) => (
                    <option key={v} value={v}>
                      {departmentTypes[v]}
                    </option>
                  ))}
              </Select>
            </ValidationMessage>
          </InputWrapper>
          <InputWrapper>
            <Label>Kommentar</Label>
            <TextArea
              {...form.register('comment')}
              placeholder="Skriv här..."
            />
          </InputWrapper>
        </>
      )}
    </MyButtonsModal>
  );
};

export default AddInventoryModal;
