import { FC, useState, useMemo } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { Colors, Sizes } from 'utils/style';
import Popover from 'components/Popover';
import Button from 'components/primitives/Button';
import H3 from 'components/primitives/H3';

import SwedishTimeStringInput from 'components/form/SwedishDateInput/SwedishTimeStringInput';

const dayInMillis = 24 * 60 * 60 * 1000;

const Wrapper = styled.button<{ isOpen?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin: -2px -10px;
  padding: 2px 10px;

  border-radius: 1000px;
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  color: ${Colors.Red};

  span {
    font-size: 1.2em;
  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          background-color: ${Colors.Red};
          color: ${Colors.White};
        `
      : css`
          &:hover {
            background-color: ${Colors.Red};
            color: ${Colors.White};
          }
        `}
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.Gutter / 4}px;
  max-width: min(70vw, 400px);

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${Sizes.Gutter / 2}px;
    margin: 0;
  }
`;

const PresetButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: ${Sizes.Gutter / 4}px;
  margin: 0;
  margin-bottom: ${Sizes.Gutter}px;
`;

const PresetButton = styled(Button)<{ isSelected?: boolean }>`
  padding: ${Sizes.Gutter / 4}px ${Sizes.Gutter / 2}px;
  line-height: 1em;
  background: transparent;
  color: ${Colors.Black};
  border: 1px solid ${Colors.DarkGray};
  border-radius: 1000px;

  &:active {
    background-color: ${Colors.DarkGray};
    color: ${Colors.White};
  }

  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: ${Colors.Black};
          border-color: ${Colors.Black};
          color: ${Colors.White};
        `
      : css`
          &:hover {
            background-color: ${Colors.DarkGray};
            color: ${Colors.White};
          }
        `}
`;

const TimeInputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .react-datepicker-wrapper {
    width: fit-content;
  }

  .separator {
    margin: 0 ${Sizes.Gutter / 2}px;
  }
`;

const SaveButton = styled(Button)`
  margin-top: ${Sizes.Gutter / 2}px;
  margin-left: auto;
`;

function getTimeInputString(msOnUTCDay: number) {
  const date = new Date();
  date.setUTCHours(0);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(msOnUTCDay);

  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0'
  )}`;
}

function timeInputStringToMillis(timeInputString: string) {
  const [hoursStr, minutesStr] = timeInputString.split(':');

  const hours = Number(hoursStr);
  const minutes = Number(minutesStr);

  const date = new Date();
  date.setUTCHours(0);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);

  const utc0 = date.getTime();

  date.setHours(hours);
  date.setMinutes(minutes);

  return (date.getTime() - utc0 + dayInMillis) % dayInMillis;
}

function millisToHourMinuteString(ms: number) {
  const hours = Math.floor(ms / 1000 / 60 / 60);
  const minutes = Math.floor((ms / (1000 * 60)) % 60);

  return `${hours}h ${minutes}min`;
}

interface Props {
  activeFromMsOnUTCDay: number;
  activeToMsOnUTCDay: number;
  onSaveClick: (
    activeFromMsOnUTCDay: number,
    activeToMsOnUTCDay: number
  ) => void;
}

const TimeRangePicker: FC<Props> = ({
  activeFromMsOnUTCDay,
  activeToMsOnUTCDay,
  onSaveClick,
}) => {
  const [open, setOpen] = useState(false);
  const [selectCustomTime, setSelectCustomTime] = useState(false);

  const [activeFromInputString, setActiveFromInputString] = useState(
    getTimeInputString(activeFromMsOnUTCDay)
  );
  const [activeToInputString, setActiveToInputString] = useState(
    getTimeInputString(activeToMsOnUTCDay + 1)
  );

  const timePresets = useMemo(() => {
    return [
      {
        label: 'Dygnet runt',
        from: '00:00',
        to: '00:00',
      },
      {
        label: 'Arbetstid',
        from: '07:00',
        to: '17:00',
      },
      {
        label: 'Förmiddagar',
        from: '07:00',
        to: '12:00',
      },
      {
        label: 'Eftermiddagar',
        from: '12:00',
        to: '17:00',
      },
    ];
  }, []);

  const activeFromInputMillis = timeInputStringToMillis(activeFromInputString);
  const activeToInputMillis = timeInputStringToMillis(activeToInputString) - 1; // -1 because we want to include the last minute

  const handleSaveClick = () => {
    onSaveClick(activeFromInputMillis, activeToInputMillis);
    setOpen(false);
  };

  const handlePresetClick = (from: string, to: string) => {
    setSelectCustomTime(false);
    setActiveFromInputString(from);
    setActiveToInputString(to);
  };

  const areInputsValid =
    !isNaN(activeFromInputMillis) && !isNaN(activeToInputMillis);

  return (
    <Popover
      content={
        <InputsWrapper>
          <H3>Skicka SMS:</H3>

          <PresetButtonsWrapper>
            {timePresets.map((preset) => (
              <PresetButton
                small
                onClick={() => handlePresetClick(preset.from, preset.to)}
                isSelected={
                  !selectCustomTime &&
                  preset.from === activeFromInputString &&
                  preset.to === activeToInputString
                }
                key={preset.label}
              >
                {preset.label}
              </PresetButton>
            ))}

            <PresetButton
              small
              isSelected={selectCustomTime}
              onClick={() => {
                setSelectCustomTime(true);
              }}
            >
              <i className="las la-arrow-right" /> Egen tid
            </PresetButton>
          </PresetButtonsWrapper>

          {selectCustomTime ? (
            <>
              <label>SMS skickas ut mellan:</label>

              <TimeInputsWrapper>
                <SwedishTimeStringInput
                  value={activeFromInputString}
                  onChange={(timeString) =>
                    setActiveFromInputString(timeString ?? '')
                  }
                />
                <span className="separator">-</span>
                <SwedishTimeStringInput
                  value={activeToInputString}
                  onChange={(timeString) =>
                    setActiveToInputString(timeString ?? '')
                  }
                />
              </TimeInputsWrapper>
              <i>
                {areInputsValid
                  ? `(${millisToHourMinuteString(
                      ((activeToInputMillis -
                        activeFromInputMillis +
                        dayInMillis) %
                        dayInMillis) +
                        1
                    )})`
                  : 'Ogiltig tid'}
              </i>
            </>
          ) : (
            <>
              <span>
                SMS skickas ut från klockan <b>{activeFromInputString}</b> till{' '}
                <b>{activeToInputString}</b> (
                {millisToHourMinuteString(
                  ((activeToInputMillis - activeFromInputMillis + dayInMillis) %
                    dayInMillis) +
                    1
                )}
                ).
              </span>
            </>
          )}

          <SaveButton
            small
            onClick={handleSaveClick}
            disabled={!areInputsValid}
          >
            Spara
          </SaveButton>
        </InputsWrapper>
      }
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        setSelectCustomTime(
          timePresets.every(
            (preset) =>
              preset.from !== activeFromInputString ||
              preset.to !== activeToInputString
          )
        );
        setActiveFromInputString(getTimeInputString(activeFromMsOnUTCDay));
        setActiveToInputString(getTimeInputString(activeToMsOnUTCDay + 1));
      }}
    >
      <Wrapper isOpen={open}>
        <span className="la la-clock" />
        {getTimeInputString(activeFromMsOnUTCDay)} -{' '}
        {getTimeInputString(activeToMsOnUTCDay + 1)}
      </Wrapper>
    </Popover>
  );
};

export default TimeRangePicker;
