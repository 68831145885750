import React, { useCallback, FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { NotificationClient } from 'api';
import Spinner from 'components/Spinner';
import styled from 'styled-components';
import {
  Colors,
  Sizes,
  MediaQuery,
  FontSizes,
  DeviceMinWidths,
} from 'utils/style';
import ImageSlider from 'components/Slider';
import H1 from 'components/primitives/H1';
import Container from 'components/primitives/Container';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { ButtonLink } from 'components/primitives/Button';
import useResponse from 'api/hooks/useResponse';
import { RequestStatus } from 'api/hooks/useApiCall';
import Routes, { ParamsIn, RouteParams } from 'utils/routes';
import CenterContent, {
  centerContentWidth,
} from 'components/primitives/CenterContent';
import useModalStack from 'components/Modal/useModalStack';
import FullImageViewerModal from 'components/Modal/FullImageViewerModal';

const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Sizes.Gutter / 2}px 0;

  ${MediaQuery.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 ${Sizes.Gutter * 0.5}px;
  }
`;

const Section = styled(Container)`
  padding: ${Sizes.Gutter / 2}px;

  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.DarkWhite};
  }

  & > pre {
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
  }

  ${MediaQuery.tablet} {
    padding: ${Sizes.Gutter}px 0;
    margin: 0 ${Sizes.Gutter / 2}px;
    width: calc(50% - ${Sizes.Gutter}px);
  }
`;

const TowerNotesHeader = styled.div`
  background: ${Colors.DarkWhite};
  color: ${Colors.Black};
  font-size: ${FontSizes.Large}px;
  font-weight: bold;

  margin: 0;
  padding: ${Sizes.Gutter}px ${Sizes.Gutter / 2}px;
  padding-bottom: ${Sizes.Gutter / 2}px;

  ${MediaQuery.tablet} {
    padding: ${Sizes.Gutter * 2}px ${Sizes.Gutter}px;
    padding-bottom: ${Sizes.Gutter}px;

    background: transparent;
    color: ${Colors.Black};
    font-size: ${FontSizes.Huge}px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const SliderWrapper = styled.div`
  height: 80vw;
  width: 100%;

  ${MediaQuery.tablet} {
    height: 40vw;
    max-height: ${centerContentWidth * 0.4}px;
    padding: 0 ${Sizes.Gutter}px;
  }
`;

const MyButtonLink = styled(ButtonLink)`
  margin-top: ${Sizes.Gutter / 2}px;
`;

const NotificationPage: FunctionComponent = () => {
  const { [RouteParams.notificationId]: id } =
    useParams<ParamsIn<typeof Routes.Notification>>();
  const modalStack = useModalStack();

  const assignment = useResponse(
    new NotificationClient(),
    useCallback(
      async (client) => {
        if (id) {
          return await client.index(id);
        }
      },
      [id]
    ),
    [id]
  );
  const dimensions = useWindowDimensions();

  if (assignment.status === RequestStatus.Fetching || !assignment.response) {
    return <Spinner />;
  }

  const response = assignment.response;
  return (
    <>
      <H1>Fordon på väg</H1>

      <CenterContent>
        {response.imageThumbnailLinks &&
          response.imageThumbnailLinks.length > 0 && (
            <>
              <SliderWrapper>
                <ImageSlider
                  slides={response.imageThumbnailLinks}
                  slideSubtitle="Klicka på en bild för att förstora den"
                  imageWidth={640}
                  imageHeight={480}
                  imageCount={
                    dimensions && dimensions.width < DeviceMinWidths.tablet
                      ? 1
                      : 2
                  }
                  autoFitImages
                  imagesCover
                  imageSeparation={5}
                  onSlideClick={(index) =>
                    modalStack.push(
                      <FullImageViewerModal
                        imageUrls={response.imageLinks!}
                        startingIndex={index}
                      />
                    )
                  }
                />
              </SliderWrapper>
              <TowerNotesHeader>Information</TowerNotesHeader>
            </>
          )}
        <ValuesContainer>
          <Section>
            <b>Skadetyp: {response.incidentType}</b>
            <div>Lämnade skadeplats: {response.leftIncidentDateString}</div>
            <div>Beräknad ankomst: {response.eta}</div>
            <div>Anteckningar:</div>
            <pre>{response.incidentNotes || '-'}</pre>
          </Section>
          <Section>
            <b>Regnr: {response.registrationNumber}</b>
            <div>Fordonstyp: {response.vehicleType}</div>
            <div>Modell: {response.vehicleBrand}</div>
          </Section>
          <Section>
            <b>Bärgad av: {response.towerName}</b>
            {response.towerPhoneNumber && (
              <>
                <div>{response.towerPhoneNumber}</div>
                <div>
                  <MyButtonLink href={`tel:${response.towerPhoneNumber}`}>
                    Ring bärgare
                  </MyButtonLink>
                </div>
                <div>
                  <MyButtonLink href={`sms:${response.towerPhoneNumber}`}>
                    Skicka SMS till bärgare
                  </MyButtonLink>
                </div>
              </>
            )}
          </Section>
        </ValuesContainer>
      </CenterContent>
    </>
  );
};

export default NotificationPage;
