import React, { useState } from 'react';
import { CaseClient } from '../api';
import styled from 'styled-components';

import CaseComponent from '../components/Case';
import SearchBar from '../components/SearchBar';
import Container from 'components/primitives/Container';
import { HeadingsWrapper, Heading } from 'components/SectionedList';
import { FontSizes, Sizes, MediaQuery, Colors } from 'utils/style';
import Spinner from 'components/Spinner';
import useResponse from 'api/hooks/useResponse';
import { RequestStatus } from 'api/hooks/useApiCall';

const Head = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  background: ${Colors.DarkWhite};

  margin: 0;

  & > h1 {
    color: ${Colors.Black};
    font-size: ${FontSizes.Huge}px;
    font-weight: bold;
    padding: ${Sizes.Gutter / 2}px ${Sizes.Gutter / 2}px;
    margin: 0;
  }

  ${MediaQuery.tablet} {
    flex-direction: row;
    padding: ${Sizes.Gutter}px;

    background: transparent;
    text-transform: uppercase;

    & > h1 {
      font-size: ${FontSizes.Heading}px;
      padding: 0;
    }
  }
`;

const MySearchBar = styled(SearchBar)`
  width: 100%;
  margin-bottom: -1px;

  ${MediaQuery.tablet} {
    width: auto;
    margin-left: auto;
    margin-bottom: 0;
  }
`;

const ImagesHeading = styled(Heading)`
  width: 165px;
  max-width: 165px;
  min-width: 165px;
`;

const MyContainer = styled(Container)`
  flex: 1;

  ${MediaQuery.phone} {
    padding: 0;
  }
`;

const Cases = () => {
  const [filterTerm, setFilterTerm] = useState('');
  const cases = useResponse(new CaseClient(), (client) => client.get());

  const getFilteredCases = () => {
    if (!cases.response) {
      return [];
    }

    if (filterTerm === '') {
      return cases.response;
    }

    // hacky filter that might be good enough
    return cases.response.filter((c) =>
      JSON.stringify(c).toLowerCase().includes(filterTerm.toLowerCase())
    );
  };

  return (
    <div>
      <Head>
        <h1>Ärenden</h1>
        <MySearchBar onChange={(term: string) => setFilterTerm(term)} />
      </Head>
      <MyContainer>
        <HeadingsWrapper>
          <ImagesHeading flex={10}>Bilder</ImagesHeading>
          <Heading flex={10}>Status</Heading>
          <Heading flex={15}>Skadeinformation</Heading>
          <Heading flex={15}>Fordonsinformation</Heading>
          <Heading flex={10}>Bärgare</Heading>
          <Heading flex={10}>Kund</Heading>
        </HeadingsWrapper>
        {cases.status === RequestStatus.Fetching ? (
          <Spinner />
        ) : (
          getFilteredCases().map((c) => (
            <CaseComponent key={c.id} repairShopCase={c} />
          ))
        )}
      </MyContainer>
    </div>
  );
};

export default Cases;
