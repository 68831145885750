import React from 'react';
import styled from 'styled-components';
import { Sizes, Colors, MediaQuery } from 'utils/style';
import { WebOrder } from 'api';
import { toLocalDateTimeString } from 'utils/dateTime';
import { RowWrapper, Section, SectionLabel } from 'components/SectionedList';

const Wrapper = styled(RowWrapper)`
  &:nth-child(odd) {
    background: ${Colors.DarkWhite};
  }

  ${MediaQuery.tablet} {
    padding-left: ${Sizes.Gutter / 2}px;
  }
`;

const MySectionLabel = styled(SectionLabel)`
  font-weight: bold;
  ${MediaQuery.tablet} {
    font-weight: bold;
  }
`;
const FieldValue = styled.div``;

interface Props {
  order: WebOrder;
}

const OrderHistoryRow: React.SFC<Props> = ({ order }) => {
  return (
    <Wrapper>
      <Section>
        <MySectionLabel>Beställd</MySectionLabel>
        <FieldValue>{toLocalDateTimeString(order.created)}</FieldValue>
      </Section>
      <Section>
        <MySectionLabel>Status</MySectionLabel>
        <FieldValue>{order.status}</FieldValue>
      </Section>
      <Section flex={2}>
        <MySectionLabel>Hämtas</MySectionLabel>
        <FieldValue>{order.pickupLocation}</FieldValue>
      </Section>
      <Section flex={2}>
        <MySectionLabel>Lämnas</MySectionLabel>
        <FieldValue>{order.requestedDropOffLocation}</FieldValue>
      </Section>
      <Section>
        <MySectionLabel>Regnr</MySectionLabel>
        <FieldValue>{order.registrationNumber}</FieldValue>
      </Section>
      <Section>
        <MySectionLabel>Referens</MySectionLabel>
        <FieldValue>{order.name}</FieldValue>
      </Section>
      <Section flex={3}>
        <MySectionLabel>Kommentar</MySectionLabel>
        <FieldValue>{order.comment}</FieldValue>
      </Section>
    </Wrapper>
  );
};

export default OrderHistoryRow;
