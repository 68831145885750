import React, { FunctionComponent } from 'react';
import { withGoogleMap, GoogleMap, GoogleMapProps } from 'react-google-maps';

type Props = GoogleMapProps & { forwardRef: React.RefObject<GoogleMap> };

const WithGoogleMap = withGoogleMap<Props>((props) => (
  <GoogleMap {...props} ref={props.forwardRef}>
    {props.children}
  </GoogleMap>
));

const GMap: FunctionComponent<Props> = (props) => (
  <WithGoogleMap
    {...props}
    containerElement={<div style={{ height: `400px`, flex: `auto` }} />}
    mapElement={<div style={{ height: `100%` }} />}
  />
);

export default GMap;
