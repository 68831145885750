import React from 'react';
import Button from 'components/primitives/Button';
import { BottomContentProps } from './FlowConfig';
import styled from 'styled-components';
import { Sizes } from 'utils/style';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 ${Sizes.Gutter / 2}px;
  margin-top: ${Sizes.Gutter / 2}px;
`;

const ContinueButton = styled(Button)``;

const BackButton = styled(Button)``;

interface Props<FormValues extends object>
  extends BottomContentProps<FormValues> {
  preventBack?: boolean;
  disableForward?: boolean;
  children?: React.ReactChild;
}

const NavigationButtons = <FormValues extends object>({
  preventBack,
  disableForward,
  children,
  flowContext: {
    currentFlow: { pages },
    currentPageIndex,
    setPage,
  },
}: Props<FormValues>) => (
  <Wrapper>
    {!preventBack && currentPageIndex > 0 ? (
      <BackButton
        onClick={() => {
          let newIndex = currentPageIndex - 1;
          newIndex = Math.min(pages.length - 1, Math.max(0, newIndex));
          setPage(newIndex);
        }}
      >
        <i className="las la-angle-double-left" />
        Tillbaka
      </BackButton>
    ) : (
      <span></span>
    )}
    {currentPageIndex < pages.length - 1 && (
      <ContinueButton
        onClick={() => {
          let newIndex = currentPageIndex + 1;
          newIndex = Math.min(pages.length - 1, Math.max(0, newIndex));
          setPage(newIndex);
        }}
        disabled={disableForward}
      >
        {children}
        <i className="las la-angle-double-right" />
      </ContinueButton>
    )}
  </Wrapper>
);

export default NavigationButtons;
