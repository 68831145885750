import React, { FunctionComponent, useState } from 'react';
import { MediaQuery, Sizes, Colors } from 'utils/style';
import styled from 'styled-components';
import { Input } from 'components/form';
import ManageRequestStatus from 'components/ManageRequestStatus';
import Button from 'components/primitives/Button';
import { StationModel, StationAdminClient } from 'api';
import useApiCall from 'api/hooks/useApiCall';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Sizes.Gutter / 2}px;

  ${MediaQuery.desktop} {
    flex: 1;
    align-items: flex-start;
    padding: 0;
    padding-right: ${Sizes.Gutter}px;

    border-right: 1px solid ${Colors.Gray};
  }
`;

const MyInput = styled(Input)`
  min-width: 0;
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${Sizes.Gutter / 2}px;
  width: 100%;
`;

interface Props {
  station: StationModel;
  onStationUpdated(station: StationModel): void;
}

const EditStationFields: FunctionComponent<Props> = ({
  station,
  onStationUpdated,
}) => {
  const [name, setName] = useState(station.name ?? '');
  const [postAddress, setPostAddress] = useState(station.postAddress ?? '');
  const [zipCode, setZipCode] = useState(station.zipCode ?? '');

  const updateCall = useApiCall(
    new StationAdminClient(),
    (client, station: StationModel) => client.updateStation(station.id, station)
  );

  const handleSaveClick = async () => {
    const updated = new StationModel(station);

    updated.name = name;
    updated.postAddress = postAddress;
    updated.zipCode = zipCode;

    const [saved] = await updateCall.run(updated);
    if (saved) {
      onStationUpdated(saved);
    }
  };

  return (
    <Wrapper>
      <ManageRequestStatus status={[updateCall.status]} ignoreErrorStatus>
        <InputWrap>
          <b>Namn</b>
          <MyInput value={name} onChange={(eve) => setName(eve.target.value)} />
        </InputWrap>

        <InputWrap>
          <b>Postadress</b>
          <MyInput
            value={postAddress}
            onChange={(eve) => setPostAddress(eve.target.value)}
          />
        </InputWrap>

        <InputWrap>
          <b>Postkod</b>
          <MyInput
            value={zipCode}
            onChange={(eve) => setZipCode(eve.target.value)}
          />
        </InputWrap>

        <Button onClick={handleSaveClick}>Spara</Button>
      </ManageRequestStatus>
    </Wrapper>
  );
};

export default EditStationFields;
