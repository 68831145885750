export const RouteParams = {
  notificationId: 'id',
  customerId: 'customerId',
  userId: 'userId',
  stationId: 'stationId',
  inventoryItemId: 'inventoryItemId',
} as const;

const Routes = {
  Home: '/',
  MyRepairshop: '/my-repairshop',
  RepairshopUsers: '/repairshop-users',
  Cases: '/cases',
  Towers: '/towers',
  Order: '/order',
  Notification: `/notification/:${RouteParams.notificationId}`,
  Inventory: '/inventory',
  InventoryItem: `/inventory/:${RouteParams.inventoryItemId}`,

  AdminCustomersTab: '/admin/customers',
  AdminUsersTab: '/admin/users',
  AdminStationsTab: '/admin/stations',

  CustomerAdmin: `/admin/customers/:${RouteParams.customerId}`,
  UserAdmin: `/admin/users/:${RouteParams.userId}`,
  StationAdmin: `/admin/stations/:${RouteParams.stationId}`,

  Logout: '/Identity/Account/Logout',
  Login: '/Identity/Account/Login',
  UserManage: '/Identity/Account/Manage',
} as const;

export default Routes;

type ExtractRouteParams<R extends string> = R extends `:${infer Param}`
  ? Param
  : never;

type RouteSegments<Route extends string> = Route extends `/${infer CleanRoute}`
  ? RouteSegments<CleanRoute>
  : Route extends `${infer Segment}/${infer Rest}`
  ? Segment | RouteSegments<Rest>
  : Route;

/** Utility type to extract all params in a Route */
export type ParamsIn<Route extends string> = Record<
  ExtractRouteParams<RouteSegments<Route>>,
  string
>;
