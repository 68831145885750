import { StagingLotClient, StagingLotWeekDay } from 'api';
import useResponse from 'api/hooks/useResponse';
import ManageRequestStatus from 'components/ManageRequestStatus';
import useModalStack from 'components/Modal/useModalStack';
import Button from 'components/primitives/Button';
import { TableActions, TableActionsTitle } from 'components/Table/components';
import SortedTable from 'components/Table/SortedTable';
import useUserInfo from 'hooks/useUserInfo';
import React from 'react';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'utils/style';
import DeleteTimeSlotModal from './DeleteTimeSlotModal';
import TimeSlotUpsertModal from './TimeSlotUpsertModal/TimeSlotUpsertModal';
import { shortDayLabelsByWeekDay, sortDaysList, toHHMM } from './utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionButton = styled.button`
  margin: 0;
  padding: 10px;
  appearance: none;
  border: none;
  background: transparent;
  color: ${Colors.Red};
  font-size: 20px;
`;

const TimeSlotsEditor: FC = () => {
  const modalStack = useModalStack();
  const userInfo = useUserInfo();

  const stagingLotGroupId = userInfo?.selectedCustomer?.stagingGroupId;
  const slotsResponse = useResponse(new StagingLotClient(), async (c) =>
    stagingLotGroupId ? c.getStagingLotsByGroupId(stagingLotGroupId) : null
  );

  if (!stagingLotGroupId) return null;

  return (
    <Wrapper>
      <TableActions>
        <TableActionsTitle>Öppettider</TableActionsTitle>
        <Button
          small
          onClick={() => {
            modalStack.push(
              <TimeSlotUpsertModal
                onTimeslotSaved={() => {
                  slotsResponse.update();
                  modalStack.pop();
                }}
              />
            );
          }}
        >
          <i className="las la-plus" /> Lägg till öppettid
        </Button>
      </TableActions>

      <ManageRequestStatus status={[slotsResponse.status]}>
        <SortedTable
          columnSettings={[
            {
              header: 'Etikett',
              attribute: 'label',
            },
            {
              header: 'Ställplats',
              attribute: 'slotCustomerName',
            },
            {
              header: 'Veckodagar',
              attribute: 'days',
              notSortable: true,
              formatter: (days: StagingLotWeekDay[]) =>
                sortDaysList(days)
                  .map((d) => shortDayLabelsByWeekDay[d])
                  .join(', '),
            },
            {
              header: 'Tidsintervall',
              notSortable: true,
              cellRenderer: ({ row }) => {
                return (
                  <span>
                    {toHHMM(row.startTime)} - {toHHMM(row.endTime)}
                  </span>
                );
              },
            },
            {
              header: '',
              cellRenderer: ({ row }) => (
                <>
                  <ActionButton
                    title="Redigera"
                    onClick={() => {
                      modalStack.push(
                        <TimeSlotUpsertModal
                          timeSlot={{
                            ...row,
                            stagingLotId: row.slotStagingLotId,
                          }}
                          onTimeslotSaved={() => {
                            slotsResponse.update();
                            modalStack.pop();
                          }}
                        />
                      );
                    }}
                  >
                    <i className="las la-edit" />
                  </ActionButton>
                  <ActionButton
                    title="Ta bort"
                    onClick={() => {
                      modalStack.push(
                        <DeleteTimeSlotModal
                          stagingLotId={row.slotStagingLotId}
                          timeSlot={row}
                          onTimeSlotDeleted={() => {
                            slotsResponse.update();
                            modalStack.pop();
                          }}
                          onCancel={() => {
                            modalStack.pop();
                          }}
                        />
                      );
                    }}
                  >
                    <i className="las la-trash" />
                  </ActionButton>
                </>
              ),
              notSortable: true,
              width: '100px',
              cellStyle: css`
                padding-top: 0;
                padding-bottom: 0;
              `,
            },
          ]}
          rows={
            slotsResponse.response
              ?.map(
                (stagingLot) =>
                  stagingLot.timeSlots?.map((s) => ({
                    ...s,
                    slotCustomerName: stagingLot.customerDisplayName,
                    slotStagingLotId: stagingLot.id,
                  })) ?? []
              )
              .flat(1) ?? []
          }
        />
      </ManageRequestStatus>
    </Wrapper>
  );
};

export default TimeSlotsEditor;
