import React from 'react';
import styled from 'styled-components';

import { Case } from '../api';
import ImageSlider from './Slider';
import { toLocalDateTimeString } from '../utils/dateTime';
import { Sizes, Colors, MediaQuery, DeviceMinWidths } from 'utils/style';
import Container from './primitives/Container';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useModalStack from './Modal/useModalStack';
import FullImageViewerModal from './Modal/FullImageViewerModal';
import Modal from './Modal';

const maxWidth = 1000;
const MyModal = styled(Modal).attrs({
  large: true,
})`
  display: flex;
  flex-direction: column;
  max-width: ${maxWidth}px;
`;

const ScrollWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const ImagesWrapper = styled.div`
  height: 80vw;

  ${MediaQuery.tablet} {
    height: 40vw;
    max-height: ${maxWidth * 0.4}px;
    margin: 0 ${Sizes.Gutter * 2}px;
  }
`;

const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Sizes.Gutter / 2}px 0;

  ${MediaQuery.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 ${Sizes.Gutter * 1.5}px;
  }
`;

const Section = styled(Container)`
  padding: ${Sizes.Gutter / 2}px;

  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.DarkWhite};
  }

  ${MediaQuery.tablet} {
    padding: ${Sizes.Gutter}px 0;
    margin: 0 ${Sizes.Gutter / 2}px;
    width: calc(50% - ${Sizes.Gutter}px);
  }
`;

interface Props {
  repairShopCase: Case;
}

const CaseDetailModal = (props: Props) => {
  const { repairShopCase } = props;
  const dimensions = useWindowDimensions();
  const modalStack = useModalStack();

  const images =
    (repairShopCase.incident && repairShopCase.incident.thumbnailImages) || [];

  const buildHeaderString = () => {
    if (repairShopCase && repairShopCase.incident) {
      return (
        'Bärgning/flytt ' + toLocalDateTimeString(repairShopCase.incident.date)
      );
    }
    return 'Bärgning/flytt';
  };

  return (
    <MyModal onClose={() => modalStack.pop()} title={buildHeaderString()}>
      <ScrollWrap>
        {images.length > 0 && (
          <ImagesWrapper>
            <ImageSlider
              slides={images}
              slideSubtitle="Klicka på en bild för att förstora den"
              imageWidth={640}
              imageHeight={480}
              imageCount={
                dimensions && dimensions.width < DeviceMinWidths.tablet ? 1 : 2
              }
              autoFitImages
              imagesCover
              imageSeparation={5}
              onSlideClick={(index) =>
                modalStack.push(
                  <FullImageViewerModal
                    imageUrls={repairShopCase.incident?.images!}
                    startingIndex={index}
                  />
                )
              }
            />
          </ImagesWrapper>
        )}

        <ValuesContainer>
          {repairShopCase.incident && (
            <Section>
              <b>Skadetyp: {repairShopCase.incident.type}</b>
              <div>
                Beställd: {toLocalDateTimeString(repairShopCase.incident.date)}
              </div>
              {repairShopCase.leftIncidentTimeStamp && (
                <div>
                  Hämtat fordon:{' '}
                  {toLocalDateTimeString(repairShopCase.leftIncidentTimeStamp)}
                </div>
              )}
              {repairShopCase.leftVehicleTimeStamp && (
                <div>
                  Lämnat fordon:{' '}
                  {toLocalDateTimeString(repairShopCase.leftVehicleTimeStamp)}
                </div>
              )}
            </Section>
          )}
          {repairShopCase.wreck && (
            <Section>
              <b>Regnr: {repairShopCase.wreck.registrationNumber}</b>
              <div>
                Bilmodell: {repairShopCase.wreck.make}{' '}
                {repairShopCase.wreck.model}
              </div>
              <div>Försäkring: {repairShopCase.wreck.insuranceInformation}</div>
            </Section>
          )}
          {repairShopCase.tower && (
            <Section>
              <b>Bärgad av: {repairShopCase.tower.name}</b>
              <div> {repairShopCase.tower.phoneNumber} </div>
            </Section>
          )}
          {repairShopCase.customer &&
            (repairShopCase.customer.name ||
              repairShopCase.customer.phoneNumber) && (
              <Section>
                <b>Kund: {repairShopCase.customer.name}</b>
                <div> {repairShopCase.customer.phoneNumber} </div>
              </Section>
            )}
        </ValuesContainer>
      </ScrollWrap>
    </MyModal>
  );
};

export default CaseDetailModal;
