import useResponse from 'api/hooks/useResponse';
import { InventoryClient, RepairShopInfoClient } from 'api';
import { createContext, useContext } from 'react';

export const UserFlowDataContext = createContext<
  ReturnType<typeof useOrderUserFlowData> | undefined
>(undefined);

export const useUserFlowDataContext = () => {
  const context = useContext(UserFlowDataContext);
  if (!context)
    throw new Error(
      'useUserFlowDataContext must be used within a <UserFlowDataContext.Provider>'
    );

  return context;
};

const useOrderUserFlowData = (inventoryId: string | undefined) => {
  const userRepairshopInfoResponse = useResponse(
    new RepairShopInfoClient(),
    (client) => client.getUsersCurrentCustomerRepairshopInfo()
  );
  const choosableRepairshopsResponse = useResponse(
    new RepairShopInfoClient(),
    (c) => c.getUsersRepairShopInfoWithCustomerName()
  );

  const inventoryResponse = useResponse(
    new InventoryClient(),
    async (c) =>
      inventoryId !== undefined
        ? await c.getInventoryDetails(Number(inventoryId))
        : null,
    [inventoryId]
  );

  return {
    userRepairshopInfoResponse,
    choosableRepairshopsResponse,
    inventoryResponse,
  };
};

export default useOrderUserFlowData;
