import Modal from 'components/Modal';
import React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import { MediaQuery, Sizes } from 'utils/style';
import searchBar from 'assets/images/Inventory_SearchBar.png';
import inspection from 'assets/images/Inventory_Inspection.png';
import inspectionToday from 'assets/images/Inventory_InspectionToday.png';
import actualDate from 'assets/images/Inventory_ActualDate.png';
import plannedDate from 'assets/images/Inventory_PlannedDate.png';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: ${Sizes.Gutter}px;
  overflow-y: auto;

  ${MediaQuery.tablet} {
    gap: ${Sizes.Gutter}px;
    grid-template-columns: 1fr 1fr;
  }

  p {
    margin-bottom: ${Sizes.Gutter / 3}px;
  }
`;

const Column = styled.div`
  max-width: 500px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Section = styled.div`
  margin-bottom: ${Sizes.Gutter}px;

  img + p {
    margin-top: ${Sizes.Gutter / 2}px;
  }
`;

interface Props {
  onClose: () => void;
}

const HelpModal: FC<Props> = ({ onClose }) => {
  return (
    <Modal title="Hjälp & Dokumentation" onClose={onClose}>
      <Wrapper>
        <Column>
          <Section>
            <h3>Statusar</h3>
            <p>
              <Bold>Ny -</Bold> Ett fordon har registrerats för uppställning
            </p>
            <p>
              <Bold>På väg till uppställning -</Bold> Fordonet har lämnat
              verkstad eller väggrenen och är på väg till uppställningsplatsen
              (visas bara för bokningar med AK)
            </p>
            <p>
              <Bold>Uppställd -</Bold> Fordonet är framme och finns på
              uppställningsplatsen
            </p>
            <p>
              <Bold>Beställd -</Bold> Fordonet har en bokad tid då det ska
              hämtas tillbaka till verkstaden
            </p>
            <p>
              <Bold>På väg till verkstad -</Bold> Fordonet har lämnat
              uppställningsplatsen och är på väg till verkstad (visas bara för
              bokningar med AK)
            </p>
            <p>
              <Bold>Stängd -</Bold> Ärendet är avslutat
            </p>
          </Section>
          <Section>
            <h3>Hitta stängda ärenden</h3>
            <p>
              Om du behöver se ett ärende som är stängt så kan du söka på
              registreringsnumret i rutan uppe i högra hörnet. Tryck på tratten
              och välj “inkludera stängda ärenden”.
            </p>
            <img src={searchBar} alt="Search bar" />
          </Section>
        </Column>
        <Column>
          <Section>
            <h3>Besiktning</h3>
            <p>Bokad besiktning visas bredvid registreringsnumret:</p>
            <img src={inspection} alt="Inspection badge" width={130} />
            <p>Infaller besiktingen idag visas klockslaget:</p>
            <img
              src={inspectionToday}
              alt="Inspection today badge"
              width={80}
            />
          </Section>
          <Section>
            <h3>Planerad och faktiskt ankomsttid</h3>
            <p>Kursiverade tider/datum anger planerad/bokad ankomsttid:</p>
            <img src={plannedDate} alt="Planned date badge" width={210} />

            <p>
              När körningen är utförd så byts den planerade/bokade tiden till
              den faktiska tiden då fordonet anlände:
            </p>
            <img src={actualDate} alt="Actual date badge" width={210} />
          </Section>
        </Column>
      </Wrapper>
    </Modal>
  );
};

export default HelpModal;
