import React, { FC } from 'react';
import { faParking } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyValueList from 'components/KeyValuesList';
import { SubSection, SubSectionTitle } from '../components';
import {
  InventoryClient,
  InventoryDeliveryType,
  InventoryModel,
  StagingLotModel,
} from 'api';
import { toLocalDateTimeString } from 'utils/dateTime';
import useAppTexts from 'hooks/useAppTexts';
import { Controller, Path, UseFormReturn } from 'react-hook-form';
import { InventoryFormValues } from '../utils/inventoryFormConverters';
import { DeepFilterObjectPropertyTypes } from 'utils/types/DeepFilterObjectPropertyTypes';
import { ApiResponse } from 'api/hooks/useResponse';
import StagingLotTimeSlotDatePicker from 'components/StagingLotTimeSlotDatePicker';
import SwedishDateTimePopover from 'components/form/SwedishDateInput/SwedishDateTimePopover';
import useApiCall from 'api/hooks/useApiCall';
import styled from 'styled-components';
import { Colors } from 'utils/style';

export const RemoveButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${Colors.Red};
`;
interface Props {
  inventory: InventoryModel;
  form: UseFormReturn<InventoryFormValues>;
  stagingLotResponse: ApiResponse<StagingLotModel>;
  onDeleteTransfer: (transferId: number) => void;
}

const ToStagingLotSubsection: FC<Props> = ({
  inventory,
  form,
  stagingLotResponse,
  onDeleteTransfer,
}) => {
  const { inventoryPickupTypes } = useAppTexts();

  const mkDateInput = (
    name: Path<DeepFilterObjectPropertyTypes<InventoryFormValues, Date | null>>
  ) => (
    <Controller
      control={form.control}
      name={name}
      render={({ field }) =>
        name !== 'actualPickupToStagingLot' ? (
          <StagingLotTimeSlotDatePicker
            optional
            small
            stagingLotResponse={stagingLotResponse}
            onChange={(date) => field.onChange(date)}
            value={field.value}
            ref={field.ref}
          />
        ) : (
          <SwedishDateTimePopover
            optional
            small
            onChange={(date) => field.onChange(date)}
            value={field.value}
            ref={field.ref}
          />
        )
      }
    />
  );

  const transferToStagingLot = inventory.transferToStagingLot;

  const isPerformedByAk =
    transferToStagingLot?.pickUpType === InventoryDeliveryType.Ak;

  return (
    <SubSection>
      <SubSectionTitle>
        <FontAwesomeIcon icon={faParking} />
        Till uppställning{' '}
        {transferToStagingLot && transferToStagingLot.isRebookable && (
          <RemoveButton
            onClick={() => onDeleteTransfer(transferToStagingLot.transferId)}
          >
            <i className="las la-trash" title="Ta bort"></i>
          </RemoveButton>
        )}
      </SubSectionTitle>

      <KeyValueList
        useEmptyPlaceholder
        items={
          isPerformedByAk
            ? [
                {
                  key: 'Utförs av',
                  value:
                    inventoryPickupTypes[transferToStagingLot?.pickUpType!],
                },
                {
                  key: 'Bokad',
                  value: transferToStagingLot.isRebookable
                    ? mkDateInput('plannedDeliveryToStagingLot')
                    : toLocalDateTimeString(
                        transferToStagingLot.plannedDelivery
                      ),
                },
                {
                  key: 'Lämnat skadeplats/verkstad',
                  value: toLocalDateTimeString(
                    transferToStagingLot?.actualPickup
                  ),
                },
                {
                  key: 'Ankomst',
                  value: toLocalDateTimeString(
                    transferToStagingLot?.actualDelivery
                  ),
                },
              ]
            : [
                {
                  key: 'Utförs av',
                  value:
                    inventoryPickupTypes[transferToStagingLot?.pickUpType!],
                },
                {
                  key: 'Lämnat skadeplats/verkstad',
                  value: mkDateInput('actualPickupToStagingLot'),
                },
                {
                  key: 'Estimerad ankomst',
                  value: mkDateInput('plannedDeliveryToStagingLot'),
                },
                {
                  key: 'Ankomst',
                  value: mkDateInput('actualDeliveryToStagingLot'),
                },
              ]
        }
      />
    </SubSection>
  );
};

export default ToStagingLotSubsection;
