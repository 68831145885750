import React, { useRef, useEffect } from 'react';

import { Legend, LegendIcon, LegendText, Wrapper } from './components';
import GMap from 'components/GMap';
import { Marker, GoogleMap } from 'react-google-maps';

export interface Coordinate {
  lat: number;
  lng: number;
}

export interface MarkerSetting {
  position?: Coordinate;
  name: string;
  iconUrl: string;
}

interface Props {
  markers: MarkerSetting[];
  handleMapClick: (
    position: google.maps.MouseEvent | google.maps.IconMouseEvent
  ) => void;
  bounds?: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral;
}

const MapContainer: React.FC<Props> = (props) => {
  const { markers, bounds, children } = props;
  const mapRef = useRef<GoogleMap | null>(null);

  useEffect(() => {
    if (bounds) {
      mapRef.current?.fitBounds(bounds);
    }
  }, [bounds]);

  return (
    <Wrapper>
      <GMap
        forwardRef={mapRef}
        onClick={props.handleMapClick}
        options={{
          fullscreenControl: false,
          streetViewControl: false,
          zoomControl: false,
          mapTypeControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
          },
        }}
      >
        {markers
          .filter((m) => !!m.position)
          .map((marker) => (
            <Marker
              position={marker.position}
              icon={{ url: marker.iconUrl }}
              key={`${marker.position!.lat},${marker.position!.lng}`}
            />
          ))}
        {children}
      </GMap>

      <Legend>
        {props.markers.map((marker, index) => (
          <React.Fragment key={index}>
            <LegendIcon src={marker.iconUrl} />
            <LegendText>{marker.name}</LegendText>
          </React.Fragment>
        ))}
      </Legend>
    </Wrapper>
  );
};

export default React.memo<React.PropsWithChildren<Props>>(MapContainer);
