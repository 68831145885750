import React from 'react';
import styled from 'styled-components';

import { Sizes, Colors } from '../../utils/style';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

interface DotProps {
  active: boolean;
}
const Dot = styled.div<DotProps>`
  margin: ${Sizes.Gutter / 3}px;
  background-color: ${Colors.Black};
  opacity: ${({ active }) => (active ? 1 : 0.3)};
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, ${({ active }) => (active ? 1 : 0.6)});
  height: 9px;
  width: 9px;
`;

const DotClick = styled.button`
  border: 0;
  padding: 0;
  background: transparent;

  &:focus {
    outline: none;
  }
`;

interface Props {
  pages: number;
  currentPage: number;
  onPageClick?(page: number): void;
  className?: string;
}

const PageIndicator: React.SFC<Props> = ({
  pages,
  currentPage,
  onPageClick,
  className,
}) => {
  const dots = [];
  for (let i = 0; i < pages; i++) {
    dots.push(
      <DotClick
        onClick={onPageClick ? () => onPageClick(i) : undefined}
        key={i}
      >
        <Dot active={i === currentPage} />
      </DotClick>
    );
  }

  return <Wrapper className={className}>{dots}</Wrapper>;
};

export default PageIndicator;
