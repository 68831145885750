import React from 'react';
import Spinner from 'components/Spinner';
import { OrderClient } from 'api';
import OrderHistoryRow from './OrderHistoryRow';
import useResponse from 'api/hooks/useResponse';
import { RequestStatus } from 'api/hooks/useApiCall';

const OrderHistory: React.SFC = () => {
  const orders = useResponse(new OrderClient(), (client) =>
    client.getSentOrders()
  );

  if (orders.status === RequestStatus.Fetching || !orders.response) {
    return <Spinner />;
  }

  return (
    <div>
      {orders.response
        .sort((a, b) => (a.created < b.created ? 1 : -1))
        .map((order, index) => (
          <OrderHistoryRow key={index} order={order} />
        ))}
    </div>
  );
};

export default React.memo(OrderHistory);
