import React, { forwardRef, useState } from 'react';
import { Input } from '.';
import styled from 'styled-components';
import { enforceValidRegnr, isValidSweRegnr } from 'utils/regex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { Colors } from 'utils/style';
import Popover from 'components/Popover';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    height: 100%;
    padding: 0 10px;
  }

  input {
    width: 100%;
  }
`;

interface Props {
  value: string;
  onChange: (value: string) => void;
  small?: boolean;

  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const RegNrInput = forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, small = false, onFocus, onKeyDown, onBlur }, ref) => {
    const [showWarning, setShowWarning] = useState(false);

    return (
      <Wrapper>
        {/* My webbrowser tried to autocomplete this input field as a password, so I added name & autoComplete="off */}
        <Input
          name="regnr"
          autoComplete="off"
          small={small}
          value={value}
          onChange={(e) => {
            const newValue = e.target.value;
            onChange(enforceValidRegnr(newValue));
          }}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          ref={ref}
        />

        {!!value.length && !isValidSweRegnr(value) && (
          <Popover
            open={showWarning}
            content={
              <>
                <FontAwesomeIcon icon={faWarning} color={Colors.Warn} /> Detta
                är inte giltigt som ett svenskt registreringsnummer.
              </>
            }
          >
            <span
              onClick={() => setShowWarning(!showWarning)}
              onMouseEnter={() => setShowWarning(true)}
              onMouseLeave={() => setShowWarning(false)}
            >
              <FontAwesomeIcon icon={faWarning} color={Colors.Warn} />
            </span>
          </Popover>
        )}
      </Wrapper>
    );
  }
);

export default RegNrInput;
