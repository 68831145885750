import React, { FunctionComponent, useState } from 'react';
import ButtonsModal from 'components/Modal/ButtonsModal';
import { TowerModel, ITowerModel } from 'api';
import styled from 'styled-components';
import { Sizes } from 'utils/style';
import { Input } from 'components/form';

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${Sizes.Gutter / 2}px;
  width: 100%;
`;

interface Props {
  toEdit: TowerModel;
  onSave(tower: TowerModel): void;
  useCreateTowerLabels?: boolean;
}

const EditTowerModal: FunctionComponent<Props> = ({
  toEdit,
  onSave,
  useCreateTowerLabels,
}) => {
  const [mailAddress, setMailAddress] = useState(toEdit.mailAddress ?? '');
  const [name, setName] = useState(toEdit.name ?? '');
  const [phoneNumber, setPhoneNumber] = useState(toEdit.phoneNumber ?? '');
  const [profileImageLink, setProfileImageLink] = useState(
    toEdit.profileImageLink ?? ''
  );
  const [towerRole, setTowerRole] = useState(toEdit.towerRole ?? '');

  const handleAddClick = async () => {
    const newTower = TowerModel.fromJS({
      ...toEdit,
      mailAddress,
      name,
      phoneNumber,
      profileImageLink,
      towerRole,
    } as ITowerModel);
    onSave(newTower);
  };

  return (
    <ButtonsModal
      title={useCreateTowerLabels ? 'Lägg till bärgare' : 'Uppdatera bärgare'}
      buttons={[
        {
          label: useCreateTowerLabels ? 'Lägg till' : 'Spara',
          onClick: handleAddClick,
        },
        {
          label: 'Avbryt',
        },
      ]}
    >
      <InputWrap>
        <b>Namn</b>
        <Input value={name} onChange={(eve) => setName(eve.target.value)} />
      </InputWrap>

      <InputWrap>
        <b>Rollbeskrivning</b>
        <Input
          value={towerRole}
          onChange={(eve) => setTowerRole(eve.target.value)}
        />
      </InputWrap>

      <InputWrap>
        <b>Telefonnummer</b>
        <Input
          value={phoneNumber}
          onChange={(eve) => setPhoneNumber(eve.target.value)}
        />
      </InputWrap>

      <InputWrap>
        <b>Email</b>
        <Input
          value={mailAddress}
          onChange={(eve) => setMailAddress(eve.target.value)}
        />
      </InputWrap>

      <InputWrap>
        <b>Profilbild, länk</b>
        <Input
          value={profileImageLink}
          onChange={(eve) => setProfileImageLink(eve.target.value)}
        />
      </InputWrap>
    </ButtonsModal>
  );
};

export default EditTowerModal;
