import React, { FC, useRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import { Input } from '..';
import { DatePickerGlobalStyles } from './SwedishInlineDateInput';
import styled from 'styled-components';
import { isValidDate } from 'utils/dateTime';

registerLocale('sv', sv);

const MyInput = styled(Input)`
  width: 100px;
  min-width: 0;
`;

interface Props {
  value: Date | null;
  onChange: (value: Date | null) => void;
  popperClassName?: string;
  small?: boolean;
}
const SwedishTimeInput: FC<Props> = ({
  value,
  onChange,
  popperClassName,
  small,
}) => {
  const inputRef = useRef(null);

  return (
    <>
      <DatePicker
        showPopperArrow={false}
        selected={isValidDate(value?.toString()) ? value : undefined}
        onChange={(date) => onChange(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeFormat="HH:mm"
        dateFormat="HH:mm"
        popperPlacement="top"
        locale={sv}
        customInput={<MyInput small={small} ref={inputRef} />}
        popperClassName={popperClassName}
      />
      <DatePickerGlobalStyles />
    </>
  );
};

export default SwedishTimeInput;
