import { UserClient, UserInvitationModel } from 'api';
import useApiCall from 'api/hooks/useApiCall';
import useResponse from 'api/hooks/useResponse';
import { Input } from 'components/form';
import ManageRequestStatus from 'components/ManageRequestStatus';
import ButtonsModal from 'components/Modal/ButtonsModal';
import useModalStack from 'components/Modal/useModalStack';
import Button from 'components/primitives/Button';
import { RemoveButton } from 'components/Table/EditableList';
import SortedTable, {
  SortedTableColumnSetting,
} from 'components/Table/SortedTable';
import React, { useState, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { toLocalDateTimeString } from 'utils/dateTime';
import { Sizes, FontSizes, MediaQuery } from 'utils/style';
import { isValidEmail } from 'utils/regex';

const InviteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${Sizes.Gutter * 2}px;
  margin-bottom: ${Sizes.Gutter / 2}px;

  h2 {
    font-size: ${FontSizes.Huge}px;
    font-weight: bold;
  }

  & > *:not(:last-child) {
    margin-bottom: ${Sizes.Gutter / 2}px;
  }

  ${MediaQuery.tablet} {
    flex-direction: row;
    align-items: baseline;

    h2 {
      flex: 1;
    }

    & > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: ${Sizes.Gutter / 2}px;
    }
  }
`;

const InvitedUsersTable: React.FC = () => {
  const [inviteEmail, setInviteEmail] = useState('');
  const { push: pushModal, pop: popModal } = useModalStack();

  const invites = useResponse(
    new UserClient(),
    useCallback((client) => client.getInvitations(), [])
  );
  const deleteInvite = useApiCall(
    new UserClient(),
    useCallback(
      (client, invitation: UserInvitationModel) =>
        client.deleteInvitation(invitation.id),
      []
    )
  );

  const inviteCall = useApiCall(
    new UserClient(),
    useCallback((client) => client.inviteUser(inviteEmail), [inviteEmail]),
    { errorMessage: 'ett fel inträffade när inbjudan skulle skickas' }
  );

  const runDeleteInvite = deleteInvite.run;
  const updateInvites = invites.update;
  const handleDeleteClick = useCallback(
    (invite: UserInvitationModel) => {
      pushModal(
        <ButtonsModal
          title="Ta bort inbjudan?"
          onClose={() => popModal()}
          buttons={[
            {
              label: 'Ta bort',
              onClick: async () => {
                const [, error] = await runDeleteInvite(invite);
                if (!error) {
                  updateInvites();
                }
              },
            },
            { label: 'Avbryt' },
          ]}
        >
          Är du säker på att du vill ta bort inbjudan för{' '}
          <b>{invite.userEmail}</b>?
        </ButtonsModal>
      );
    },
    [pushModal, popModal, runDeleteInvite, updateInvites]
  );

  const columnSettings = useMemo(
    (): SortedTableColumnSetting<UserInvitationModel>[] => [
      {
        header: 'Skickad till',
        attribute: 'userEmail',
      },
      {
        header: 'Skapad',
        attribute: 'created',
        formatter: (date) => toLocalDateTimeString(date as Date),
      },
      {
        header: 'Går ut',
        attribute: 'expirationDate',
        formatter: (date) => toLocalDateTimeString(date as Date),
      },
      {
        header: 'Ta bort',
        notSortable: true,
        cellRenderer: ({ row }) => (
          <RemoveButton onClick={() => handleDeleteClick(row)}>
            <i className="las la-trash"></i>
          </RemoveButton>
        ),
        width: '70px',
        cellStyle: css`
          padding-bottom: 0;
          padding-top: 0;
        `,
      },
    ],
    [handleDeleteClick]
  );

  return (
    <ManageRequestStatus
      status={[invites.status, inviteCall.status, deleteInvite.status]}
    >
      <InviteWrapper>
        <h2>Inbjudningar</h2>
        <Input
          placeholder="Epost"
          value={inviteEmail}
          onChange={(eve: React.ChangeEvent<HTMLInputElement>) =>
            setInviteEmail(eve.target.value)
          }
        />
        <Button
          disabled={!isValidEmail(inviteEmail)}
          onClick={async () => {
            const [, error] = await inviteCall.run();
            if (!error) {
              updateInvites();
              setInviteEmail('');
            }
          }}
        >
          Skicka inbjudan
        </Button>
      </InviteWrapper>

      <SortedTable
        rows={invites.response ?? []}
        columnSettings={columnSettings}
      />
    </ManageRequestStatus>
  );
};

export default InvitedUsersTable;
