import styled from 'styled-components';

import { Sizes, MediaQuery } from 'utils/style';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${Sizes.Gutter / 2}px;
`;

export const LegendIcon = styled.img`
  margin-right: ${Sizes.Gutter / 4}px;
  margin-left: ${Sizes.Gutter}px;
  max-height: ${Sizes.Gutter * 1.5}px;

  ${MediaQuery.tablet} {
    max-height: ${Sizes.Gutter * 2}px;
  }
`;

export const LegendText = styled.div``;
