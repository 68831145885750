import React from 'react';
import styled from 'styled-components';
import { Sizes, FontSizes, Colors } from 'utils/style';
import { supportContactEmail } from 'utils';

const Wrapper = styled.div`
  background-color: ${Colors.Red};
  color: ${Colors.White};
  display: flex;
  flex-direction: column;
  padding: ${Sizes.Gutter / 2}px ${Sizes.Gutter}px;
  padding-bottom: ${Sizes.Gutter}px;
  font-size: ${FontSizes.Normal}px;
  font-weight: bold;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border: 36px solid transparent;
    border-top-color: ${Colors.Red};
    border-bottom: 0;
    border-right: 0;
    margin-left: -18px;
    margin-bottom: -36px;
  }

  a,
  a:active,
  a:visited,
  a:hover {
    color: inherit;
    text-decoration: none;
    font: inherit;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const ContactBox: React.SFC<{ className?: string }> = ({ className }) => (
  <Wrapper className={className}>
    <div>SUPPORT</div>
    <div>
      <a
        href={`mailto:${supportContactEmail}`}
        target="_blank"
        rel="noreferrer"
      >
        {supportContactEmail}
      </a>
    </div>
  </Wrapper>
);

export default ContactBox;
