import styled, { keyframes } from 'styled-components';
import { useFormState, Control, FieldValues, Path } from 'react-hook-form';
import { Colors } from 'utils/style';
import React, { ReactNode } from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ErrorMessage = styled.div`
  padding-bottom: 10px;
  color: ${Colors.Red};
  animation: ${slideDown} 0.2s ease-out;
`;

interface Props<FV extends FieldValues> {
  control: Control<FV>;
  name: Path<FV>;
  children: ReactNode;
}

const ValidationMessage = <FV extends FieldValues>({
  control,
  name,
  children,
}: Props<FV>) => {
  const fieldState = useFormState({
    control,
    name,
  });

  const errorMessage = fieldState.errors[name]?.message;

  return (
    <Wrapper>
      {children}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};

export default ValidationMessage;
