import React, { useState } from 'react';
import { NotificationReceiverDto, INotificationReceiverDto } from 'api';
import { Form, Input } from 'components/form';
import { NotificationType } from './NotificationReceiversEditor';
import Button from 'components/primitives/Button';
import styled from 'styled-components';
import { isValidPhoneNr, isValidEmail } from 'utils/regex';
import { Colors } from 'utils/style';

const AddButton = styled(Button)`
  margin-left: auto;
`;

const MyForm = styled(Form)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: flex-end;
  gap: 10px;

  & > i {
    margin: auto 0;
    font-size: 1.5rem;
  }
`;

const MyInput = styled(Input)`
  flex: 1;
  min-width: 150px;
  padding-top: 0;
  padding-bottom: 0;
`;

interface Props {
  notificationType: NotificationType;
  onSave(edited: NotificationReceiverDto): void;
}

const AddNotificationReceiver: React.SFC<Props> = ({
  notificationType,
  onSave,
}) => {
  const [addMode, setAddMode] = useState(false);
  const [edit, setEdit] = useState<INotificationReceiverDto>({
    ...new NotificationReceiverDto(),
    type: notificationType,
    active: true,
  });

  const getValueType = () => {
    switch (notificationType) {
      case NotificationType.Mail:
        return 'Epost';
      case NotificationType.SMS:
        return 'Telefonnummer';
    }
  };

  const validateEdit = () => {
    if (!edit.description?.trim() || !edit.value) return false;

    switch (notificationType) {
      case NotificationType.Mail:
        return isValidEmail(edit.value);
      case NotificationType.SMS:
        return isValidPhoneNr(edit.value);
    }

    return false;
  };

  const handleSubmit = (eve: React.FormEvent) => {
    eve.preventDefault();
    onSave(new NotificationReceiverDto(edit));
    setAddMode(false);
    setEdit({
      ...new NotificationReceiverDto(),
      type: notificationType,
      active: true,
    });
  };

  if (!addMode) {
    return (
      <AddButton onClick={() => setAddMode(true)} small>
        <i className="las la-plus"></i>
        Lägg till
      </AddButton>
    );
  }

  return (
    <MyForm
      onSubmit={handleSubmit}
      style={
        addMode
          ? {
              backgroundColor: Colors.DarkWhite,
              padding: 10,
              margin: -10,
            }
          : undefined
      }
    >
      {addMode && <i className="las la-plus"></i>}

      <MyInput
        autoFocus
        placeholder="Namn"
        name="description"
        value={edit.description || ''}
        onChange={(eve: React.ChangeEvent<HTMLInputElement>) => {
          setEdit({ ...edit, description: eve.target.value });
        }}
      />
      <MyInput
        name="value"
        placeholder={getValueType()}
        value={edit.value || ''}
        onChange={(eve: React.ChangeEvent<HTMLInputElement>) => {
          setEdit({ ...edit, value: eve.target.value });
        }}
      />

      {validateEdit() ? (
        <Button type="submit" small>
          <i className="las la-plus"></i>
          Lägg till
        </Button>
      ) : (
        <Button
          small
          onClick={(eve) => {
            eve.preventDefault();
            setAddMode(false);
          }}
        >
          Avbryt
        </Button>
      )}
    </MyForm>
  );
};

export default AddNotificationReceiver;
