import { PropsWithChildren, ReactNode } from 'react';
import { Arrow, Content, Portal, Root, Trigger } from '@radix-ui/react-popover';
import styled, { css } from 'styled-components';
import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from './animations/fadeIn';
import { Colors, Sizes } from 'utils/style';
import React from 'react';

export const popoverArrowClassname = 'popover-arrow';

interface MyContentProps {
  $noPadding?: boolean;
  $fullWidth?: boolean;
}
const MyContent = styled(Content)<MyContentProps>`
  ${({ $noPadding }) => ($noPadding ? '' : 'padding: 10px 20px;')}
  background-color: ${Colors.White};
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3), 0 5px 90px rgba(0, 0, 0, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 99;
  max-width: 95vw;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: var(--radix-popover-trigger-width);
    `}

  &[data-state='open'][data-side='top'] {
    animation-name: ${slideDownAndFade};
  }
  &[data-state='open'][data-side='right'] {
    animation-name: ${slideLeftAndFade};
  }
  &[data-state='open'][data-side='bottom'] {
    animation-name: ${slideUpAndFade};
  }
  &[data-state='open'][data-side='left'] {
    animation-name: ${slideRightAndFade};
  }
`;

const MyArrow = styled(Arrow)`
  fill: ${Colors.White};
`;

export const SelectItems = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 50vh;
`;

export const SelectItem = styled.button<{ selected?: boolean }>`
  padding: ${Sizes.Gutter / 4}px ${Sizes.Gutter / 2}px;
  appearance: none;
  border: none;
  border-bottom: 1px solid ${Colors.Gray};
  background-color: ${({ selected }) =>
    selected ? Colors.Selected : Colors.White};
  color: ${({ selected }) => (selected ? Colors.White : Colors.Black)};
  text-align: left;

  &:nth-child(even) {
    background-color: ${({ selected }) =>
      selected ? Colors.Selected : Colors.DarkWhite};
  }
  &:last-child {
    border-bottom: none;
  }
`;

interface Props {
  content: ReactNode;
  side?: 'right' | 'top' | 'bottom' | 'left' | undefined;
  sideOffset?: number;
  align?: 'start' | 'end' | 'center' | undefined;
  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?(open: boolean): void;
  modal?: boolean;
  autoFocusOnOpen?: boolean;
  /** Prevents autofocusing trigger element on close. Default to true. */
  autofocusTriggerOnClose?: boolean;
  noPadding?: boolean;
  noArrow?: boolean;
  fullWidth?: boolean;
  onPointerDownOutside?: (event: Event) => void;

  className?: string;
}

const Popover = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      side,
      sideOffset,
      align,
      defaultOpen,
      open,
      onOpenChange,
      modal,
      children,
      content,
      autoFocusOnOpen = true,
      autofocusTriggerOnClose = true,
      noPadding,
      noArrow,
      fullWidth,
      className,
      onPointerDownOutside,
    },
    ref
  ) => (
    <Root
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      modal={modal}
    >
      <Trigger asChild>{children}</Trigger>
      <Portal>
        <MyContent
          onOpenAutoFocus={
            autoFocusOnOpen ? undefined : (event) => event.preventDefault()
          }
          className={className}
          side={side}
          sideOffset={sideOffset ?? 5}
          align={align}
          collisionPadding={Sizes.Gutter / 2}
          onCloseAutoFocus={
            autofocusTriggerOnClose ? undefined : (e) => e.preventDefault()
          }
          onPointerDownOutside={onPointerDownOutside}
          onKeyDown={(event) => {
            if (event.key === 'Escape') {
              event.stopPropagation();
              event.preventDefault();
              onOpenChange?.(false);
            }
          }}
          $noPadding={noPadding}
          $fullWidth={fullWidth}
          ref={ref}
        >
          {content}

          {!noArrow && <MyArrow className={popoverArrowClassname} />}
        </MyContent>
      </Portal>
    </Root>
  )
);

export default Popover;
