import React, {
  FunctionComponent,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import { UserClient, IUserInfo, CustomerModel } from 'api';
import useResponse from 'api/hooks/useResponse';
import ManageRequestStatus from 'components/ManageRequestStatus';
import Routes from 'utils/routes';
import ButtonsModal from 'components/Modal/ButtonsModal';
import { RequestStatus } from 'api/hooks/useApiCall';
import useSetSelectedCustomer from './useSetSelectedCustomer';

interface UserContextValue extends IUserInfo {
  memberOfActiveCustomers: IUserInfo['memberOfCustomers'];
  selectedCustomer: CustomerModel | undefined;
}

const UserContext = React.createContext<UserContextValue | null | undefined>(
  undefined
);

const useAutoSelectActiveCustomer = (
  memberOfActiveCustomers: IUserInfo['memberOfCustomers'],
  selectedCustomerId: number | undefined,
  activateAutoSelect: boolean
) => {
  const [setSelectedCustomer, changeCustomerStatus] = useSetSelectedCustomer();

  // Change user's customer if they are not a member of the selected customer
  useEffect(() => {
    if (!activateAutoSelect) return;
    if (changeCustomerStatus === RequestStatus.Fetching) return;

    const selectedCustomerIsActive = !!memberOfActiveCustomers?.find(
      (customer) => customer.id === selectedCustomerId
    );

    if (!selectedCustomerIsActive && memberOfActiveCustomers?.length) {
      setSelectedCustomer(memberOfActiveCustomers[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberOfActiveCustomers, selectedCustomerId]);
};

export const UserInfoProvider: FunctionComponent = ({ children }) => {
  const userInfo = useResponse(
    new UserClient(),
    (client) => client.getUserInfo(),
    undefined,
    {
      ignoreUnauthorizedErrors: true,
    }
  );

  const contextValue = useMemo(
    (): UserContextValue | null =>
      userInfo.response
        ? {
            ...userInfo.response,
            memberOfActiveCustomers:
              userInfo.response?.memberOfCustomers?.filter(
                (customer) => customer.isActive
              ),
            selectedCustomer: userInfo.response?.memberOfCustomers?.find(
              (c) => c.id === userInfo.response?.selectedCustomerId
            ),
          }
        : null,
    [userInfo.response]
  );

  useAutoSelectActiveCustomer(
    contextValue?.memberOfActiveCustomers,
    contextValue?.selectedCustomerId,
    userInfo.status === RequestStatus.Fetched
  );

  if (
    contextValue &&
    (!contextValue.memberOfActiveCustomers?.length ||
      !contextValue.memberOfActiveCustomers?.find(
        (customer) => customer.id === contextValue.selectedCustomerId
      ))
  ) {
    return (
      <ButtonsModal buttons={[]} title="Fel">
        Du är inte medlem hos någon av våra kunder. Kontakta administratören för
        att koppla ihop ditt konto med en kund och{' '}
        <a href={Routes.Logout}>logga sedan in</a> igen.
      </ButtonsModal>
    );
  }

  return (
    <UserContext.Provider value={contextValue}>
      <ManageRequestStatus status={[userInfo.status]} ignoreErrorStatus>
        {children}
      </ManageRequestStatus>
    </UserContext.Provider>
  );
};

const useUserInfo = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw Error('useUserInfo must be used within a UserInfoProvider');
  }

  return context;
};

export default useUserInfo;
