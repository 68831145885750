import React, { useState } from 'react';
import Table, { Props as TableProps } from '.';
import styled from 'styled-components';
import { ColumnSetting } from '.';

const Icon = styled.i`
  margin-left: auto;
  padding: 0 10px;
`;

export interface SortedTableColumnSetting<Obj extends Object>
  extends ColumnSetting<Obj, keyof Obj> {
  notSortable?: boolean;
}

export interface Props<Obj extends Object> extends TableProps<Obj> {
  columnSettings: SortedTableColumnSetting<Obj>[];
  defaultSortingColumn?: number;
  defaultSortAscending?: boolean;
}

/**
 * @deprecated use ReactTable component from @assistancekaren/react-components instead.
 */
const SortedTable = <Obj extends Object>({
  columnSettings,
  rows,
  defaultSortingColumn,
  defaultSortAscending,
  ...props
}: Props<Obj>) => {
  const [sortingColumn, setSortingColumn] = useState(
    defaultSortingColumn === undefined ? 0 : defaultSortingColumn
  );
  const [sortAscending, setSortAscending] = useState(
    defaultSortAscending === undefined ? true : defaultSortAscending
  );

  const sortingAttribute = columnSettings[sortingColumn].attribute;
  const formatter =
    columnSettings[sortingColumn].formatter || ((value: any) => value);

  const sortedRows = sortingAttribute
    ? rows.sort((a, b) => {
        const valueA = formatter(a[sortingAttribute]);
        const valueB = formatter(b[sortingAttribute]);

        if (valueA === undefined && valueB === undefined) {
          return 0;
        } else if (valueA === undefined) {
          return sortAscending ? -1 : 1;
        } else if (valueB === undefined) {
          return sortAscending ? 1 : -1;
        }

        return (
          (valueA > valueB ? 1 : valueA === valueB ? 0 : -1) *
          (sortAscending ? 1 : -1)
        );
      })
    : rows;

  const handleHeaderClick = (index: number) => {
    if (index === sortingColumn) {
      setSortAscending(!sortAscending);
    } else {
      setSortingColumn(index);
    }
  };

  return (
    <Table
      columnSettings={columnSettings.map((setting, index) => ({
        ...setting,
        onHeaderClick: setting.notSortable
          ? undefined
          : () => handleHeaderClick(index),
        header: (
          <>
            {setting.header}
            {sortingColumn === index && (
              <Icon
                className={
                  sortAscending ? 'las la-angle-up' : 'las la-angle-down'
                }
              />
            )}
          </>
        ),
      }))}
      rows={sortedRows}
      {...props}
    />
  );
};

/**
 * @deprecated use ReactTable component instead.
 */
export default React.memo(SortedTable) as typeof SortedTable;
