import React from 'react';
import styled from 'styled-components';
import { Select, InputWrapper, TextArea, Label, Input } from 'components/form';
import Container from 'components/primitives/Container';
import { FlowContext } from 'components/UserFlow/FlowConfig';
import { FormValues, defaultPayer } from '../orderFlowConfig';
import { Sizes, MediaQuery, Colors, FontSizes } from 'utils/style';
import MultiStringInput from 'components/form/MultiStringInput';
import useModalStack from 'components/Modal/useModalStack';
import ButtonsModal from 'components/Modal/ButtonsModal';
import { isValidEmail } from 'utils/regex';
import InventorySearchInput from './InventorySearchInput';
import { useUserFlowDataContext } from '../useOrderUserFlowData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import StagingLotTimeSlotDatePicker from 'components/StagingLotTimeSlotDatePicker';
import useResponse from 'api/hooks/useResponse';
import { StagingLotClient } from 'api';

const Wrapper = styled(Container)`
  flex: 1;
  margin: 0 auto;
  max-width: 700px;
  width: 100%;

  padding-top: ${Sizes.Gutter}px;
  ${MediaQuery.tablet} {
    padding-top: ${Sizes.Gutter}px;
  }
`;

const useSelectedOriginRepairshop = (formValues: FormValues) => {
  const { choosableRepairshopsResponse } = useUserFlowDataContext();

  const selectedRepairshop = choosableRepairshopsResponse.response?.find(
    (r) => r.repairshopInfo?.id === formValues.vehicleOrigin.repairshopId
  );

  return selectedRepairshop;
};

interface Props {
  flowContext: FlowContext<FormValues>;
}

const VehicleInfoPage: React.FC<Props> = ({ flowContext }) => {
  const formValues = flowContext.formValues;
  const modalStack = useModalStack();
  const { inventoryResponse } = useUserFlowDataContext();

  const selectedOriginRepairshop = useSelectedOriginRepairshop(formValues);
  const stagingLotResponse = useResponse(
    new StagingLotClient(),
    async (c) =>
      selectedOriginRepairshop?.stagingLotId
        ? await c.get(selectedOriginRepairshop?.stagingLotId)
        : null,
    [selectedOriginRepairshop?.stagingLotId]
  );

  const createValueUpdater =
    (name: keyof FormValues) =>
    (
      eve: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >
    ) => {
      const value = eve.target.value;

      flowContext.updateFormValues((values) => ({
        ...values,
        [name]: value,
      }));
    };

  const updateValue = <K extends keyof FormValues>(
    name: K,
    value: FormValues[K]
  ) => {
    flowContext.updateFormValues((values) => ({ ...values, [name]: value }));
  };

  return (
    <Wrapper>
      {inventoryResponse.response ? (
        <InputWrapper>
          <Label>Uppställt fordon valt:</Label>
          <b
            style={{
              fontSize: FontSizes.Larger,
            }}
          >
            {formValues.registrationNumber}
          </b>
        </InputWrapper>
      ) : (
        <InputWrapper>
          <Label requiredStar>Regnummer</Label>
          <InventorySearchInput
            repairshop={selectedOriginRepairshop}
            value={formValues.registrationNumber || ''}
            onChange={(v) => {
              updateValue('registrationNumber', v);
              updateValue('inventoryId', undefined);
            }}
            onInventorySelected={(inventory) => {
              updateValue('inventoryId', inventory.id);
              updateValue('registrationNumber', inventory.registrationNumber);
            }}
          />

          {formValues.inventoryId && (
            <span>
              <FontAwesomeIcon icon={faCheckCircle} color={Colors.Good} />{' '}
              Uppställt fordon valt
            </span>
          )}
        </InputWrapper>
      )}

      <InputWrapper>
        <Label>Namn på verkstadsreferens</Label>
        <Input
          value={formValues.name || ''}
          onChange={createValueUpdater('name')}
        />
      </InputWrapper>

      <InputWrapper>
        <Label>Betalare</Label>
        <Select onChange={createValueUpdater('payer')} value={formValues.payer}>
          <option value={defaultPayer}>Verkstad</option>
          <option value="Kund">Kund</option>
        </Select>
      </InputWrapper>

      <InputWrapper>
        <Label>Primärt nummer till bärgare (får sms-återkoppling)</Label>
        <Input
          value={formValues.phoneNumber || ''}
          onChange={createValueUpdater('phoneNumber')}
        />
      </InputWrapper>

      <InputWrapper>
        <Label>Extra telnr (får sms-återkoppling)</Label>
        <MultiStringInput
          strings={formValues.feedbackSMS}
          banDuplicates
          onAddString={(str) => {
            if (str === formValues.phoneNumber) {
              modalStack.push(
                <ButtonsModal
                  title="Info"
                  onClose={() => modalStack.pop()}
                  buttons={[{ label: 'Ok' }]}
                >
                  Telefonnumret "{str}" är redan angiven under 'Primärt nummer
                  till bärgare (får sms-återkoppling)'.
                </ButtonsModal>
              );
            } else {
              updateValue('feedbackSMS', [...formValues.feedbackSMS, str]);
            }
          }}
          onRemoveString={(index) => {
            const newArr = [...formValues.feedbackSMS];
            newArr.splice(index, 1);
            updateValue('feedbackSMS', newArr);
          }}
          formatString={(str) => str.replace(/[^\d-+ ]/g, '')}
          placeholder="+ Telefonnummer"
        />
      </InputWrapper>

      <InputWrapper>
        <Label>Mailadress för återrapportering</Label>
        <MultiStringInput
          strings={formValues.feedbackEmails}
          banDuplicates
          onAddString={(str) => {
            if (isValidEmail(str)) {
              updateValue('feedbackEmails', [
                ...formValues.feedbackEmails,
                str,
              ]);
            }
          }}
          onValidateString={(str) =>
            isValidEmail(str) ? undefined : 'Ange en giltig mailadress'
          }
          onRemoveString={(index) => {
            const newArr = [...formValues.feedbackEmails];
            newArr.splice(index, 1);
            updateValue('feedbackEmails', newArr);
          }}
          placeholder="+ Epost"
        />
      </InputWrapper>

      {!formValues.inventoryId ? (
        <InputWrapper>
          <Label>Önskad tidpunkt för flytt</Label>
          <Select
            onChange={createValueUpdater('requestedTime')}
            value={formValues.requestedTime}
          >
            <option value="Normal (inom 48h)">Normal (inom 48h)</option>
            <option value="Brådskande (inom 2h)">Brådskande (inom 2h)</option>
            <option value="">Annat (använd kommentar)</option>
          </Select>
        </InputWrapper>
      ) : (
        <InputWrapper>
          <Label requiredStar>Upphämtningstid</Label>
          <StagingLotTimeSlotDatePicker
            stagingLotResponse={stagingLotResponse}
            onChange={(v) => updateValue('pickupTime', v)}
            value={formValues.pickupTime}
          />
        </InputWrapper>
      )}

      <InputWrapper>
        <Label>Var står bilen parkerad?</Label>
        <Input
          value={formValues.carLocation ?? ''}
          onChange={createValueUpdater('carLocation')}
        />
      </InputWrapper>

      <InputWrapper>
        <Label>Var finns nyckeln?</Label>
        <Input
          value={formValues.carkeyLocation ?? ''}
          onChange={createValueUpdater('carkeyLocation')}
        />
      </InputWrapper>

      <InputWrapper>
        <Label>Kommentar</Label>
        <TextArea
          rows={4}
          value={formValues.comment || ''}
          onChange={createValueUpdater('comment')}
        />
      </InputWrapper>
    </Wrapper>
  );
};

export default VehicleInfoPage;
