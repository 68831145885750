import React from 'react';
import styled from 'styled-components';
import { Spinner as Spin } from 'reactstrap';
import { Sizes } from 'utils/style';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex: 1;
  width: 100%;
  height: 100%;

  *:first-child {
    margin-bottom: ${Sizes.Gutter}px;
  }
`;

const SmallSpinner = styled(Spin)`
  width: 1em;
  height: 1em;
  border-width: 0.2em;
`;

const Spinner: React.FC<{
  light?: boolean;
  iconOnly?: boolean;
  className?: string;
}> = ({ light, iconOnly, children, className }) =>
  iconOnly ? (
    <SmallSpinner color={light ? 'white' : 'dark'} />
  ) : (
    <Wrapper className={className}>
      <Spin color={light ? 'white' : 'dark'} />
      {children}
    </Wrapper>
  );

export default Spinner;
