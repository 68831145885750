import React from 'react';
import styled from 'styled-components';

import sweden from 'assets/sweden.svg';
import H1 from 'components/primitives/H1';
import { Sizes, MediaQuery } from 'utils/style';
import Container from 'components/primitives/Container';
import { StatisticsClient } from 'api';
import Spinner from 'components/Spinner';
import MonthTowingCountChart from './MonthTowingCountChart';
import IncidentTypesPieChart, { legendWidth } from './IncidentTypesPieChart';
import useResponse from 'api/hooks/useResponse';
import { RequestStatus } from 'api/hooks/useApiCall';
import CenterContent from 'components/primitives/CenterContent';

const Wrapper = styled(Container)`
  flex: 1;
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: ${Sizes.Gutter}px;
  }

  .sweden {
    display: none;
    background-image: url(${sweden});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .pie-chart {
    display: flex;
    flex-direction: column;
    justify-content: center;

    b {
      display: block;
      margin-right: ${legendWidth - 10}px;
      text-align: center;
    }
  }

  .monthly-towing-chart {
    b {
      margin-left: 50px;
    }
  }

  ${MediaQuery.tablet} {
    display: grid;
    max-height: 1000px;
    grid-template-columns: 0 repeat(3, 1fr);
    grid-template-rows: auto 0.5fr auto 0.5fr 1fr 1fr; //repeat(8, auto);
    grid-column-gap: ${Sizes.Gutter}px;
    grid-row-gap: 0px;

    .towed-to-me-year {
      grid-area: 3 / 2 / 4 / 3;
    }
    .towed-to-me-month {
      grid-area: 1 / 2 / 2 / 3;
    }

    .towed-to-me-year-number {
      grid-area: 4 / 2 / 5 / 3;
    }
    .towed-to-me-month-number {
      grid-area: 2 / 2 / 3 / 3;
    }

    .sweden {
      grid-area: 1 / 1 / 5 / 2;
    }
    .pie-chart {
      margin-top: ${Sizes.Gutter}px;
      margin-bottom: ${Sizes.Gutter}px;
      grid-area: 1 / 3 / 5 / 5;
    }
    .monthly-towing-chart {
      grid-area: 5 / 2 / 7 / 6;

      b {
        margin-left: 0;
      }
    }
  }

  ${MediaQuery.desktop} {
    grid-template-columns: repeat(5, 1fr);

    .sweden {
      display: initial;
    }

    .monthly-towing-chart {
      grid-area: 5 / 2 / 7 / 5;
    }
  }
`;

const StatsLabel = styled.div`
  font-weight: bold;
`;

const StatsNumber = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;
  padding: 0;

  font-size: 60px;
  font-weight: bolder;
`;

const PieChartVerticalCenter = styled.div`
  padding-top: 60%;
  position: relative;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const HomePage: React.FC = () => {
  const statistics = useResponse(new StatisticsClient(), (client) =>
    client.get()
  );

  if (statistics.status === RequestStatus.Fetching || !statistics.response) {
    return <Spinner>Hämtar statistik...</Spinner>;
  }

  return (
    <>
      <H1>Hem</H1>
      <CenterContent>
        <Wrapper>
          <div className="sweden" />

          <StatsLabel className="towed-to-me-year">
            Fordon bärgade till din verkstad senaste året
          </StatsLabel>
          <StatsNumber className="towed-to-me-year-number">
            {statistics.response.towingsToYouThisYear}
          </StatsNumber>

          <StatsLabel className="towed-to-me-month">
            Fordon bärgade till din verkstad senaste månaden
          </StatsLabel>
          <StatsNumber className="towed-to-me-month-number">
            {statistics.response.towingsToYouThisMonth}
          </StatsNumber>

          <div className="pie-chart">
            <PieChartVerticalCenter>
              <div>
                <b>Skadeorsaker senaste året</b>
                <IncidentTypesPieChart
                  incidentTypes={
                    statistics.response.incidentTypesThisYear || []
                  }
                />
              </div>
            </PieChartVerticalCenter>
          </div>

          <div className="monthly-towing-chart">
            <b>Bärgade fordon till din verkstad</b>
            <MonthTowingCountChart
              monthReports={statistics.response.monthReports || []}
            />
          </div>
        </Wrapper>
      </CenterContent>
    </>
  );
};

export default HomePage;
