import React from 'react';
import styled, { css } from 'styled-components';
import { Sizes, Colors, MediaQuery } from 'utils/style';
const circleDiameter = 25;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${Sizes.Gutter / 2}px ${Sizes.Gutter / 2}px;
  padding-top: ${Sizes.Gutter}px;

  ${MediaQuery.tablet} {
    padding: ${Sizes.Gutter}px ${Sizes.Gutter / 2}px;
  }
`;

interface ActiveProps {
  active: boolean;
}
const Line = styled.div`
  flex: 1;
  height: 3px;
  background-color: ${Colors.Gray};
  max-width: 300px;

  &::before {
    display: block;
    content: '';
    background-color: ${Colors.Red};
    height: 100%;
    transition: width 0.5s;

    width: ${({ active }: ActiveProps) => (active ? '100%' : '0')};
  }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: ${circleDiameter}px;
  height: ${circleDiameter}px;
  margin: -2px;
  border-radius: ${circleDiameter / 2}px;
  z-index: 1;
  background-color: ${Colors.Gray};
  transition: background-color 0.3s;

  ${({ active }: ActiveProps) =>
    active
      ? css`
          background-color: ${Colors.Red};
          color: ${Colors.White};
        `
      : css`
          color: ${Colors.Black};
        `};
`;

interface Props {
  stepCount: number;
  currentStep: number;
}

const StepIndicator: React.FunctionComponent<Props> = ({
  stepCount,
  currentStep,
}) => {
  const components = [
    <Circle key={`circle-${0}`} active>
      1
    </Circle>,
  ];

  let circleNumber = 2;
  for (let i = 1; i < stepCount; i++) {
    components.push(<Line key={`line-${i}`} active={i <= currentStep} />);
    components.push(
      <Circle key={`circle-${i}`} active={i <= currentStep}>
        {circleNumber}
      </Circle>
    );
    circleNumber++;
  }

  if (components.length === 1) {
    return null;
  }
  return <Wrapper>{components}</Wrapper>;
};

export default StepIndicator;
