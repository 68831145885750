import React from 'react';
import { IncidentType } from 'api';
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Legend,
  Tooltip,
} from 'recharts';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { DeviceMinWidths } from 'utils/style';

const colors = [
  'rgb(231, 57, 65, ',
  'rgb(255, 238, 0, ',
  'rgb(150, 150, 150, ',
];

export const legendWidth = 130;

interface Props {
  incidentTypes: IncidentType[];
}

const IncidentTypesPieChart: React.FC<Props> = ({ incidentTypes }) => {
  const data = incidentTypes.sort((a, b) => (a.percent > b.percent ? -1 : 1));
  const dim = useWindowDimensions();

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      minHeight={100}
      minWidth={100}
      maxHeight={400}
    >
      <PieChart>
        <Pie
          dataKey="percent"
          data={data}
          innerRadius="40%"
          startAngle={90}
          endAngle={450}
        >
          {data.map((entry, index) => (
            <Cell
              key={index}
              fill={
                colors[index % colors.length] +
                (1 - index / (data.length + 5)) +
                ')'
              }
            />
          ))}
        </Pie>
        <Legend
          iconType="square"
          align="right"
          verticalAlign={
            dim && dim.width < DeviceMinWidths.tablet ? 'middle' : 'bottom'
          }
          layout="vertical"
          width={legendWidth}
        />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default IncidentTypesPieChart;
