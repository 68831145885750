import { Input, Select } from 'components/form';
import styled from 'styled-components';
import { Colors, FontSizes, MediaQuery, Sizes } from 'utils/style';

export const SectionTitle = styled.h3`
  margin: 0;
  padding: 0 ${Sizes.Gutter / 2}px;
  font-size: ${FontSizes.Larger}px;
  font-weight: bold;

  ${MediaQuery.tablet} {
    padding: 0 ${Sizes.Gutter}px;
    font-size: ${FontSizes.Huge}px;
  }
`;

export const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 ${Sizes.Gutter / 2}px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${Colors.Gray};

  ${MediaQuery.tablet} {
    margin-left: ${Sizes.Gutter}px;
    margin-right: ${Sizes.Gutter}px;
  }
`;

export const SubSectionTitle = styled.h4`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin: 0;
  padding: 0;

  font-size: ${FontSizes.Large}px;
  font-weight: bold;

  ${MediaQuery.tablet} {
    font-size: ${FontSizes.Larger}px;
  }

  svg {
    color: ${Colors.SemiBlack};
  }
`;

export const ActionsListSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin: 0 ${Sizes.Gutter / 2}px;

  ${MediaQuery.tablet} {
    margin: 0 ${Sizes.Gutter}px;
  }

  ${MediaQuery.desktop} {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
`;

export const FullWidthInput = styled(Input)`
  width: 100%;
`;

export const FullWidthSelect = styled(Select)`
  width: 100%;
`;

export const formActionButtonsHeight = 60;

export const FormActionButtons = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  height: ${formActionButtonsHeight}px;
  max-height: ${formActionButtonsHeight}px;
  padding: ${Sizes.Gutter / 2}px;

  background-color: ${Colors.White};
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);

  ${MediaQuery.tablet} {
    position: sticky;
    padding: ${Sizes.Gutter}px;
    height: auto;
    max-height: none;
    margin-top: auto;
    box-shadow: none;
  }
`;
