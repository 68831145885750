import { DepartmentType, InventoryStatus, CreateInventoryRequest } from 'api';

export interface AddInventoryFormValues {
  registrationNumber: string;
  plannedDeliveryToStagingLot: Date | null;
  actualDeliveryToStagingLot: Date | null;
  status: InventoryStatus;
  repairShopId: number;
  departmentType?: DepartmentType | -1;
  comment: string;
}

export const defaultAddInventoryFormValues: Readonly<AddInventoryFormValues> = {
  registrationNumber: '',
  plannedDeliveryToStagingLot: null,
  actualDeliveryToStagingLot: null,
  status: InventoryStatus.New,
  repairShopId: -1,
  departmentType: -1,
  comment: '',
};

export function AddInventoryFormValuesToCreateInventoryRequest(
  formValues: AddInventoryFormValues
): CreateInventoryRequest {
  return new CreateInventoryRequest({
    registrationNumber: formValues.registrationNumber,
    departmentTypeId:
      formValues.departmentType !== -1 ? formValues.departmentType : undefined,
    status: formValues.status,
    plannedDeliveryToStagingLot:
      formValues.plannedDeliveryToStagingLot ?? undefined,
    actualDeliveryToStagingLot:
      formValues.actualDeliveryToStagingLot ?? undefined,
    comment: formValues.comment,
    repairShopId: formValues.repairShopId,
  });
}
