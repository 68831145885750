import { useEffect, DependencyList, useCallback, useMemo } from 'react';
import useApiCall, {
  RequestStatus,
  UseApiCallOptions,
  ApiCall,
  RunReturnValue,
} from './useApiCall';

export interface ApiResponse<Response> {
  response: ApiCall<Response, []>['response'];
  error: ApiCall<Response, []>['error'];
  status: ApiCall<Response, []>['status'];
  setStatus: ApiCall<Response, []>['setStatus'];
  update(response?: Response): Promise<RunReturnValue<Response> | undefined>;
}

/** use deps array to automatically call methodCaller when a dependency changes */
const useResponse = <Client, Response>(
  client: Client,
  methodCaller: (client: Client) => Promise<Response>,
  deps?: DependencyList,
  options?: UseApiCallOptions
): ApiResponse<Response> => {
  const { run, response, error, setResponse, status, setStatus } = useApiCall(
    client,
    methodCaller,
    useMemo(
      () => ({
        ...options,
        initialStatus: options?.initialStatus ?? RequestStatus.Fetching,
      }),
      [options]
    )
  );

  useEffect(() => {
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps || []);

  const update = useCallback(
    async (response?: Response) => {
      if (response !== undefined) {
        setResponse(response);
      } else {
        return await run();
      }
    },
    [setResponse, run]
  );

  return {
    response,
    status,
    error,
    setStatus,
    update,
  };
};

export default useResponse;
