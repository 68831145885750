import React, { forwardRef, ReactNode, useEffect, useState } from 'react';
import Popover from 'components/Popover';
import SwedishInlineDateInput from './SwedishInlineDateInput';
import { inputStyle } from '..';
import styled, { css, keyframes } from 'styled-components';
import { Colors, Sizes } from 'utils/style';
import { toLocalDateTimeString } from 'utils/dateTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faTimes,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import SwedishTimeInput from './SwedishTimeInput';
import useWindowDimensionIsBetween from 'hooks/useWindowDimensionIsBetween';

const MyPopover = styled(Popover)`
  max-height: var(--radix-popover-content-available-height);
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -45%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
`;

const MobileCenterer = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  z-index: 99;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  overflow: auto;

  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3), 0 5px 90px rgba(0, 0, 0, 0.2);
  animation: ${fadeIn} 0.2s;
`;

const PopoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Sizes.Gutter / 2}px;
  background-color: ${Colors.DarkWhite};
  max-width: 356px;
`;

const TimeInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-bottom: ${Sizes.Gutter / 2}px;

  label {
    margin: 0;
  }
`;

const FakeDateInput = styled.div`
  ${inputStyle}
  padding: 0;
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: row;
`;

const InputActivator = styled.button<{ small?: boolean }>`
  flex: 1;
  width: auto;
  min-width: 0;
  margin: 0;
  padding: ${Sizes.Gutter / 3}px;
  padding-right: 0;

  appearance: none;
  border: none;
  background: none;
  font: inherit;
  color: ${Colors.Black};
  line-height: 24px;
  text-align: left;

  ${({ small }) =>
    small &&
    css`
      padding: 2px ${Sizes.Gutter / 3}px;
      padding-right: 0;
    `}
`;

const AddButton = styled.button<{ disabled?: boolean }>`
  padding: 0 10px;
  margin-left: -5px;

  border-radius: 1000px;
  border: none;
  appearance: none;
  background: none;
  font: inherit;
  color: ${Colors.Red};
  cursor: pointer;
  align-self: flex-start;

  &:hover {
    background-color: ${Colors.DarkWhite};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      color: ${Colors.Gray};

      &:hover {
        background-color: transparent;
      }
    `}
`;

const InputClearWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ClearButton = styled.button`
  padding: 0 10px;
  margin: 0;

  appearance: none;
  border: none;
  background: none;
  color: ${Colors.Red};
  cursor: pointer;
`;

const WarningButton = styled(ClearButton)`
  color: ${Colors.Warn};
`;

export interface Props {
  value: Date | null;
  onChange: (value: Date | null) => void;
  timeLabel?: string;
  small?: boolean;
  optional?: boolean;
  bottomContent?: () => React.ReactNode;
  disabled?: boolean;
  warningMessage?: ReactNode;
}

const SwedishDateTimePopover = forwardRef<HTMLButtonElement, Props>(
  (
    {
      value,
      onChange,
      timeLabel,
      small,
      optional,
      bottomContent,
      disabled,
      warningMessage,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [warningOpen, setWarningOpen] = useState(false);
    const isSmallScreen = useWindowDimensionIsBetween(0, 800, 'height');

    const centererRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!isSmallScreen) return;

      let mouseDownStartedInCenterer = false;
      const mouseDownListener = (e: MouseEvent) => {
        if (
          centererRef.current &&
          centererRef.current.contains(e.target as Node)
        ) {
          mouseDownStartedInCenterer = true;
        }
      };

      const mouseUpListener = (e: MouseEvent) => {
        if (
          centererRef.current &&
          !centererRef.current.contains(e.target as Node) &&
          !mouseDownStartedInCenterer
        ) {
          setOpen(false);
          e.stopImmediatePropagation();
        }
        mouseDownStartedInCenterer = false;
      };

      const focusListener = (e: FocusEvent) => {
        if (
          centererRef.current &&
          e.target instanceof Node &&
          !centererRef.current.contains(e.target)
        ) {
          setOpen(false);
        }
      };

      window.addEventListener('mousedown', mouseDownListener);
      window.addEventListener('mouseup', mouseUpListener);
      window.addEventListener('focusin', focusListener);

      return () => {
        window.removeEventListener('mousedown', mouseDownListener);
        window.removeEventListener('mouseup', mouseUpListener);
        window.removeEventListener('focusin', focusListener);
      };
    }, [isSmallScreen, open]);

    const popupContent = (
      <PopoverWrapper>
        <SwedishInlineDateInput
          value={value}
          onChange={(newValue) => {
            onChange(newValue);
          }}
        />
        <TimeInputWrapper>
          <label>{timeLabel ?? 'Klockslag'}</label>
          <SwedishTimeInput
            value={value}
            onChange={(newValue) => {
              onChange(newValue);
            }}
          />
        </TimeInputWrapper>
        {bottomContent && bottomContent()}
      </PopoverWrapper>
    );

    const anchorOnto = !!value ? (
      <FakeDateInput>
        <InputActivator
          ref={ref}
          disabled={disabled}
          small={small}
          onClick={() => !disabled && setOpen(!open)}
        >
          {toLocalDateTimeString(value)}
        </InputActivator>

        {warningMessage && (
          <Popover
            open={warningOpen}
            content={
              <>
                <FontAwesomeIcon icon={faWarning} color={Colors.Warn} />{' '}
                {warningMessage}
              </>
            }
          >
            <WarningButton
              onClick={(e) => {
                e.stopPropagation();
                setWarningOpen((o) => !o);
              }}
              onMouseEnter={() => setWarningOpen(true)}
              onMouseLeave={() => setWarningOpen(false)}
            >
              <FontAwesomeIcon icon={faWarning} />
            </WarningButton>
          </Popover>
        )}

        {!!value && optional && (
          <ClearButton
            onClick={(e) => {
              e.stopPropagation();
              onChange(null);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </ClearButton>
        )}
      </FakeDateInput>
    ) : (
      <AddButton
        ref={ref}
        disabled={disabled}
        onClick={
          !disabled
            ? () => {
                onChange(new Date());
                setOpen(true);
              }
            : undefined
        }
      >
        + <FontAwesomeIcon icon={faCalendarAlt} />
      </AddButton>
    );

    return (
      <InputClearWrapper>
        {isSmallScreen ? (
          <>
            {anchorOnto}
            {open && (
              <MobileCenterer ref={centererRef}>{popupContent}</MobileCenterer>
            )}
          </>
        ) : (
          <MyPopover
            open={open}
            autoFocusOnOpen={true}
            noPadding
            noArrow
            onPointerDownOutside={() => setOpen(false)}
            align="start"
            content={popupContent}
          >
            {anchorOnto}
          </MyPopover>
        )}
      </InputClearWrapper>
    );
  }
);

export default SwedishDateTimePopover;
