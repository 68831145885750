import { FlowConfig, FlowContext } from './FlowConfig';
import { useState } from 'react';
import { copyJSON } from 'utils';

/** Creates a new FlowContext */
const useFlowContext = <FormValues extends Object>(
  flowConfig: FlowConfig<FormValues>
) => {
  const [currentFlow, setCurrentFlow] = useState(flowConfig);
  const [pageIndex, setPageIndex] = useState(currentFlow.startPageIndex || 0);
  const [formValues, setFormValues] = useState(
    copyJSON(currentFlow.initialFormValues)
  );

  const flowContext: FlowContext<typeof formValues> = {
    currentFlow,
    setCurrentFlow,

    currentPageIndex: pageIndex,
    setPage: setPageIndex,

    formValues,
    updateFormValues: setFormValues,
    resetFormValues: () =>
      setFormValues(copyJSON(currentFlow.initialFormValues)),
  };

  return flowContext;
};

export default useFlowContext;
