import { useRef, useState, useEffect } from 'react';

const useWindowDimensions = (delay: number = 0) => {
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>();
  const firstDelayValue = useRef(delay); // to ignore useEffect missing dependency warning
  const timeout = useRef<ReturnType<typeof setTimeout>>(-1 as any);

  useEffect(() => {
    const handleResize = () => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, firstDelayValue.current);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return dimensions;
};

export default useWindowDimensions;
