import { useEffect, FunctionComponent, useRef } from 'react';
import useUserInfo from 'hooks/useUserInfo';

export const CustomerIdWatchCookie = 'CustomerIdWatchCookie';

const CustomerIdCookieWatcher: FunctionComponent = () => {
  const customerId = useUserInfo()?.selectedCustomerId;
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current && customerId !== undefined) {
      firstRender.current = false;
      document.cookie = `${CustomerIdWatchCookie}=${customerId}; path=/`;
    }
  }, [customerId]);

  useEffect(() => {
    const regex = new RegExp(`${CustomerIdWatchCookie}=[^;]+`);
    const watchCookie = () => {
      const trueCustomerId = regex
        .exec(document.cookie)
        ?.toString()
        .replace(/^.*=/, '');

      if (
        trueCustomerId &&
        customerId !== null &&
        customerId !== undefined &&
        Number(trueCustomerId) !== customerId
      ) {
        window.location.reload();
      }
    };

    const handle = setInterval(watchCookie, 5000);

    return () => {
      clearInterval(handle);
    };
  }, [customerId]);

  return null;
};

export default CustomerIdCookieWatcher;
