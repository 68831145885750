import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import SortedTable from 'components/Table/SortedTable';
import Button from 'components/primitives/Button';
import { Sizes, FontSizes, Colors, MediaQuery } from 'utils/style';
import {
  StationModel,
  StationAdminClient,
  TowerModel,
  IStationModel,
} from 'api';
import useModalStack from 'components/Modal/useModalStack';
import EditTowerModal from './EditTowerModal';
import useApiCall from 'api/hooks/useApiCall';
import ManageRequestStatus from 'components/ManageRequestStatus';
import ButtonsModal from 'components/Modal/ButtonsModal';

const Wrapper = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: ${Sizes.Gutter / 2}px;

  ${MediaQuery.desktop} {
    padding: 0;
    padding-left: ${Sizes.Gutter}px;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: ${Sizes.Gutter}px 0;

  h2 {
    font-size: ${FontSizes.Huge}px;
    font-weight: bold;
  }
`;

const MyButton = styled(Button)``;

const TableButton = styled.button`
  border: 0;
  background: transparent;
  color: ${Colors.Red};
  font-size: 20px;
`;

interface Props {
  station: StationModel;
  onStationUpdated(station: StationModel): void;
}

const EditStationTowers: FunctionComponent<Props> = ({
  station,
  onStationUpdated,
}) => {
  const modalStack = useModalStack();

  const addCall = useApiCall(
    new StationAdminClient(),
    (client, tower: TowerModel) => client.addTower(station.id, tower),
    { popModalBeforeError: true }
  );

  const updateCall = useApiCall(
    new StationAdminClient(),
    (client, tower: TowerModel) =>
      client.updateTower(station.id, tower.id, tower),
    { popModalBeforeError: true }
  );

  const deleteCall = useApiCall(
    new StationAdminClient(),
    (client, towerId: number) => client.deleteTower(station.id, towerId),
    { popModalBeforeError: true }
  );

  const handleAddTowerClick = () => {
    modalStack.push(
      <EditTowerModal
        useCreateTowerLabels
        onSave={async (newTower) => {
          const [savedTower] = await addCall.run(newTower);

          if (savedTower) {
            const updatedStation = StationModel.fromJS({
              ...station,
              towers: [...(station.towers ?? []), savedTower],
            } as IStationModel);

            onStationUpdated(updatedStation);
          }
        }}
        toEdit={new TowerModel()}
      />
    );
  };

  const handleUpdateTower = async (updatedTower: TowerModel) => {
    const [savedTower] = await updateCall.run(updatedTower);

    if (savedTower) {
      const updatedStation = StationModel.fromJS({
        ...station,
        towers: (station.towers ?? []).map((tower) =>
          tower.id !== updatedTower.id ? tower : updatedTower
        ),
      } as IStationModel);

      onStationUpdated(updatedStation);
    }
  };

  const handleDeleteTowerClick = (toDelete: TowerModel) => {
    modalStack.push(
      <ButtonsModal
        title="Ta bort bärgare?"
        onClose={() => modalStack.pop()}
        buttons={[
          {
            label: 'Ta bort',
            onClick: async () => {
              const [, error] = await deleteCall.run(toDelete.id);

              if (!error) {
                const updatedStation = StationModel.fromJS({
                  ...station,
                  towers: (station.towers ?? []).filter(
                    (tower) => tower.id !== toDelete.id
                  ),
                } as IStationModel);

                onStationUpdated(updatedStation);
              }
            },
          },
          { label: 'Avbryt' },
        ]}
      >
        Är du säker att du vill ta bort bärgaren <b>{toDelete.name}</b>?{' '}
      </ButtonsModal>
    );
  };

  return (
    <Wrapper>
      <ManageRequestStatus
        status={[addCall.status, updateCall.status, deleteCall.status]}
      >
        <Title>
          <h2>Bärgare</h2>
          <MyButton onClick={handleAddTowerClick}>
            <i className="las la-plus" /> Lägg till bärgare
          </MyButton>
        </Title>

        <SortedTable
          rows={station.towers ?? []}
          columnSettings={[
            {
              header: 'Id',
              attribute: 'id',
              width: '70px',
            },
            {
              header: 'Namn',
              attribute: 'name',
              width: '200px',
            },
            {
              header: 'Email',
              attribute: 'mailAddress',
            },
            {
              header: 'Telefonnummer',
              attribute: 'phoneNumber',
              width: '180px',
            },
            {
              header: 'Profilbild, länk',
              attribute: 'profileImageLink',
              cellStyle: css`
                min-width: 200px;
                max-width: 300px;
                overflow: hidden;
              `,
            },
            {
              header: 'Roll',
              attribute: 'towerRole',
            },
            {
              header: '',
              cellRenderer: ({ row }) => (
                <>
                  <TableButton onClick={() => handleDeleteTowerClick(row)}>
                    <i className="las la-trash" />
                  </TableButton>

                  <TableButton
                    onClick={() =>
                      modalStack.push(
                        <EditTowerModal
                          toEdit={row}
                          onSave={handleUpdateTower}
                        />
                      )
                    }
                  >
                    <i className="las la-edit" />
                  </TableButton>
                </>
              ),
              notSortable: true,
              width: '90px',
              cellStyle: css`
                padding-top: 0;
                padding-bottom: 0;
              `,
            },
          ]}
        />
      </ManageRequestStatus>
    </Wrapper>
  );
};

export default EditStationTowers;
