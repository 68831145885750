import React from 'react';
import styled, { css } from 'styled-components';
import TabStrip from './TabStrip';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
`;

const TabContentWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: 100%;
`;

const TabContent = styled.div<{ displayNone?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;

  ${({ displayNone }) =>
    displayNone &&
    css`
      display: none;
    `}
`;

interface PageSetting {
  content: React.ReactChild;
  tabLabel: string;

  /** Use css: 'display: none;' when tab is hidden */
  keepMounted?: boolean;
}

interface Props {
  pages: PageSetting[];
  onTabClicked(index: number): void;
  openTab: number;
}

const TabView: React.SFC<Props> = ({ pages, onTabClicked, openTab }) => {
  return (
    <Wrapper>
      <TabStrip
        openTab={openTab}
        tabs={pages.map((page) => ({ label: page.tabLabel }))}
        onTabClicked={onTabClicked}
      />

      <TabContentWrap>
        {pages.map((tab, index) =>
          index === openTab || tab.keepMounted ? (
            <TabContent key={index} displayNone={index !== openTab}>
              {tab.content}
            </TabContent>
          ) : null
        )}
      </TabContentWrap>
    </Wrapper>
  );
};

export default TabView;
