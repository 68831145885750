import styled, { css } from 'styled-components';

import { Colors, Sizes, FontSizes, MediaQuery } from '../../utils/style';

export type ButtonVariant = 'primary' | 'secondary' | 'textbutton';

export const buttonStyle = css<{ small?: boolean; variant?: ButtonVariant }>`
  ${({ small, variant }) => {
    switch (variant) {
      case 'textbutton':
        return css`
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          padding: 0;
          margin: 0;

          appearance: none;
          border: none;
          background: transparent;
          font: inherit;
          text-align: inherit;
          color: ${Colors.Red};
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        `;

      case 'primary':
      case 'secondary':
      default:
        const secondary = variant === 'secondary';
        return css`
          background-color: ${secondary ? Colors.White : Colors.Red};
          color: ${secondary ? Colors.Black : Colors.White};
          border: none;
          font-size: ${FontSizes.Large}px;

          padding: ${Sizes.Gutter / 4}px ${Sizes.Gutter / 2}px;

          ${secondary &&
          css`
            border: 1px solid ${Colors.Gray};
          `}

          ${!small &&
          css`
            ${MediaQuery.tablet} {
              padding: ${Sizes.Gutter / 2}px ${Sizes.Gutter * 0.76}px;
            }
          `}

          &:active {
            background-color: ${secondary
              ? Colors.DarkWhite
              : Colors.RedSelected};
          }

          &:disabled {
            background-color: ${secondary ? Colors.White : Colors.RedDisabled};
          }
        `;
    }
  }}
`;

export const ButtonLink = styled.a`
  ${buttonStyle}
  display: inline-block;

  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: ${Colors.White};
    text-decoration: none;
  }
`;

const Button = styled.button`
  ${buttonStyle};
`;

export default Button;
