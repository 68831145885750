import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Colors } from 'utils/style';
import { inputStyle } from '.';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputBox = styled.div`
  ${inputStyle}
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 3px;
  cursor: text;
`;

const StringValue = styled.span`
  display: inline-block;
  position: relative;
  padding: 2px 10px;
  padding-right: 1.7em;
  margin: 0 5px 5px 0;
  min-width: 5em;

  background: ${Colors.Red};
  color: ${Colors.White};
  border-radius: 0.9em;
  font-weight: normal;
`;

const DeleteBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 1.7em;
  height: 1.7em;
  max-height: 100%;
  padding: 0;
  margin: 0;

  color: inherit;
  font-size: 1.1em;
  background: transparent;
  border: 0;
`;

const InputButtonWrap = styled.form`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
  margin-left: 0;
  max-width: 300px;

  &:focus-within > button {
    visibility: visible;
  }
`;

const Input = styled.input`
  ${inputStyle};
  flex: 1;
  padding: 5px 10px;
  margin: 0 5px 0 0;
  min-width: 150px;
  height: 1.5em;

  border: 0;
  border-bottom: 1px solid transparent;

  &:focus {
    outline: none;
    border-bottom: 1px solid ${Colors.Gray};
  }
`;

const OkButton = styled.button`
  display: flex;
  padding: 0 5px;

  background: transparent;
  border: 0;
  color: ${Colors.Red};
  font-size: 1.5em;
  visibility: hidden;

  &:disabled {
    color: ${Colors.Gray};
  }
`;

const ErrorMessage = styled.span`
  color: ${Colors.Red};
`;

interface Props {
  onAddString(str: string): void;
  onRemoveString(index: number): void;
  strings: string[];
  /** disables addding if string input equals one of the strings in props.strings */
  banDuplicates?: boolean;

  /** gets user-updated string as argument and returns error message if invalid */
  onValidateString?(str: string): string | undefined;
  /** gets user-updated string as argument and returns whatever you want it to look like */
  formatString?(stringInput: string): string;
  placeholder?: string;
}

const MultiStringInput: React.SFC<Props> = ({
  onAddString,
  onRemoveString,
  strings,
  banDuplicates,
  onValidateString,
  formatString,
  placeholder,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [stringInput, setStringInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const disableAdd =
    (banDuplicates && !!strings.find((str) => stringInput === str)) ||
    stringInput.trim().length < 1;

  const handleAddString = () => {
    if (!disableAdd) {
      const errorMsg = onValidateString?.(stringInput);

      if (!errorMsg) {
        onAddString(stringInput);
        setStringInput('');
        setErrorMessage('');
      } else {
        setErrorMessage(errorMsg);
      }
    }
  };

  const revalidateErrorMessage = (updatedInputStr: string) => {
    // revalidate error message
    setErrorMessage((errorMessage) => {
      if (!updatedInputStr) return '';

      if (updatedInputStr && errorMessage)
        return onValidateString?.(updatedInputStr) ?? '';

      return errorMessage;
    });
  };

  return (
    <Wrapper>
      <InputBox
        onClick={() => {
          inputRef.current?.focus();
        }}
      >
        {strings.map((str, index) => (
          <StringValue key={index} onClick={(eve) => eve.stopPropagation()}>
            {str}
            <DeleteBtn onClick={() => onRemoveString(index)}>
              <i className="las la-times-circle"></i>
            </DeleteBtn>
          </StringValue>
        ))}

        <InputButtonWrap
          onSubmit={(eve) => {
            eve.stopPropagation();
            eve.preventDefault();
            handleAddString();
          }}
        >
          <Input
            ref={inputRef}
            placeholder={placeholder}
            value={stringInput}
            onChange={(eve) => {
              const newValue = eve.target.value;

              if (formatString) {
                const formatted = formatString(newValue);
                const selectionStart = eve.target.selectionStart;
                const selectionEnd = eve.target.selectionEnd;
                setStringInput(formatted);

                revalidateErrorMessage(formatted);

                if (formatted !== newValue && selectionStart && selectionEnd) {
                  // restore selection, because jumping cursors are annoying
                  setTimeout(() => {
                    inputRef.current?.setSelectionRange(
                      selectionStart - 1,
                      selectionEnd - 1
                    );
                  }, 0);
                }
              } else {
                revalidateErrorMessage(newValue);

                setStringInput(newValue);
              }
            }}
            onBlur={handleAddString}
          />
          <OkButton disabled={disableAdd}>
            <i className="las la-plus-circle"></i>
          </OkButton>
        </InputButtonWrap>
      </InputBox>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};

export default MultiStringInput;
