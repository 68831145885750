import styled from 'styled-components';
import { FontSizes, Colors, Sizes, MediaQuery } from 'utils/style';

export default styled.h2`
  background: ${Colors.DarkWhite};
  color: ${Colors.Black};
  font-size: ${FontSizes.Larger}px;
  font-weight: bold;

  margin: 0;
  padding: ${Sizes.Gutter / 2}px ${Sizes.Gutter / 2}px;

  ${MediaQuery.tablet} {
    padding: ${Sizes.Gutter * 2}px ${Sizes.Gutter}px;
    padding-bottom: ${Sizes.Gutter}px;

    background: transparent;
    color: ${Colors.Black};
    font-size: ${FontSizes.Huge}px;
    font-weight: bold;
  }
`;
