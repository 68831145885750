import React, { useEffect } from 'react';
import { FlowContext } from 'components/UserFlow/FlowConfig';
import { FormValues } from './orderFlowConfig';
import Spinner from 'components/Spinner';
import { WebOrder, OrderClient } from 'api';
import useApiCall, { RequestStatus } from 'api/hooks/useApiCall';

interface Props {
  flowContext: FlowContext<FormValues>;
  onOrderSent(): void;
}

const SendOrderPage: React.FC<Props> = ({ flowContext, onOrderSent }) => {
  const postOrderCall = useApiCall(
    new OrderClient(),
    (orderClient, order: WebOrder) => orderClient.post(order)
  );

  useEffect(() => {
    const formValues = flowContext.formValues;

    const order = new WebOrder({
      created: new Date(),
      registrationNumber: formValues.registrationNumber,
      inventoryId: formValues.inventoryId,
      comment: '',
      fromRepairShopId: formValues.vehicleOrigin.repairshopId,
      toRepairShopId: formValues.vehicleDestination.repairshopId,
      pickupLocation: formValues.vehicleOrigin.address,
      orderDate: formValues.pickupTime ?? undefined,
      longitude: formValues.vehicleOrigin.coordinate!.lng,
      latitude: formValues.vehicleOrigin.coordinate!.lat,

      requestedDropOffLocation: formValues.vehicleDestination.address,

      name: formValues.name,
      phoneNumber: formValues.phoneNumber,
      feedbackSMS: formValues.feedbackSMS,
      feedbackEmails: formValues.feedbackEmails,
    });

    if (formValues.requestedTime && !formValues.pickupTime) {
      order.comment += 'Önskad tidpunkt: ' + formValues.requestedTime;
    }
    if (formValues.payer) {
      order.comment += '\nBetalare: ' + formValues.payer;
    }
    if (formValues.carkeyLocation) {
      order.comment += '\nVar finns nyckeln?: ' + formValues.carkeyLocation;
    }
    if (formValues.carLocation) {
      order.comment += '\nVar står bilen parkerad?: ' + formValues.carLocation;
    }
    if (formValues.comment) {
      order.comment += '\n\nKommentar:\n' + formValues.comment;
    }

    postOrderCall.run(order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (postOrderCall.status) {
      case RequestStatus.Error:
        flowContext.setPage(flowContext.currentPageIndex - 1);
        break;

      case RequestStatus.Fetched:
        onOrderSent();
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postOrderCall.status]);

  return <Spinner>Skickar Bärgning/Flytt-order</Spinner>;
};

export default SendOrderPage;
