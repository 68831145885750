import React from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'utils/style';

const Wrapper = styled.div`
  display: inline-block;
  cursor: pointer;
`;

interface StylingProps {
  value: boolean;
  disabled?: boolean;
}

const grooveHeight = 13 + 10;
const ToggleGroove = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: ${({ value: on }: StylingProps) =>
    on ? `flex-end` : `flex-start`};
  border: 2px solid
    ${({ disabled, value }) =>
      disabled
        ? value
          ? Colors.DarkGray
          : Colors.DarkWhite
        : !value
        ? Colors.Gray
        : Colors.Red};
  height: ${grooveHeight}px;
  width: ${grooveHeight * 1.7}px;
  border-radius: ${grooveHeight / 2}px;
  background-color: ${Colors.DarkWhite};
  ${({ value, disabled }) =>
    value &&
    css`
      background-color: ${disabled ? Colors.DarkGray : Colors.Red};
    `}
`;

const overlap = -2;
const size = grooveHeight + overlap * 2;
const ToggleHandle = styled.div`
  background-color: ${Colors.White};
  height: ${size}px;
  width: ${size}px;
  border-radius: ${size / 2}px;
  margin: ${-2 - overlap}px 0px;
  box-shadow: ${'0px 1px 3px 0px rgba(0, 0, 0, 0.50)'};

  ${({ disabled }: StylingProps) =>
    disabled &&
    css`
      border: 1px solid ${Colors.DarkWhite};
      box-shadow: none;
    `}
`;

interface Props {
  onClick(): void;
  on: boolean;
  disabled?: boolean;
}

const Toggle: React.FunctionComponent<Props> = ({ on, disabled, onClick }) => (
  <Wrapper onClick={onClick} className="toggle-input">
    <ToggleGroove value={on} disabled={disabled}>
      <ToggleHandle value={on} disabled={disabled} />
    </ToggleGroove>
  </Wrapper>
);

export default Toggle;
