export const toShortLocalDateString = (utcDate: Date) => {
  const localDate = new Date(utcDate);
  const YYYY = localDate.getFullYear();
  const MM = (localDate.getMonth() + 1).toString().padStart(2, '0');
  const DD = localDate.getDate().toString().padStart(2, '0');
  return `${YYYY}-${MM}-${DD}`;
};

export const toLocalDateTimeString = <D extends Date | undefined>(
  utcDate: D
): D extends undefined ? string | undefined : string => {
  if (utcDate === undefined) return undefined!;

  const YYYYMMDD = toShortLocalDateString(utcDate);
  const HHmm = toLocalHHMMString(utcDate);
  return `${YYYYMMDD} ${HHmm}`;
};

export const toLocalHHMMString = (utcDate: Date) => {
  const localDate = new Date(utcDate);
  const HH = localDate.getHours().toString().padStart(2, '0');
  const mm = localDate.getMinutes().toString().padStart(2, '0');
  return `${HH}:${mm}`;
};

export const toDynamicTimeString = (utcDate: Date) => {
  const oneDayInMs = 1000 * 60 * 60 * 24;
  const passedTime = new Date(utcDate).getTime();
  const currentTime = new Date().getTime();
  if (currentTime - passedTime >= oneDayInMs) {
    return toLocalDateTimeString(utcDate);
  }
  return toLocalHHMMString(utcDate);
};

export const toInputDateTimeString = (utcDate: Date) => {
  const localDate = new Date(utcDate);
  const YYYY = localDate.getFullYear();
  const MM = (localDate.getMonth() + 1).toString().padStart(2, '0');
  const DD = localDate.getDate().toString().padStart(2, '0');
  const HH = localDate.getHours().toString().padStart(2, '0');
  const mm = localDate.getMinutes().toString().padStart(2, '0');
  return `${YYYY}-${MM}-${DD}T${HH}:${mm}`;
};

export const isPastDateTime = (utcDate: Date) => {
  const sourceDate = new Date(utcDate).toISOString();
  const now = new Date().toISOString();
  const isPast = sourceDate.localeCompare(now) < 0;

  return isPast;
};

export const parseTimeString = (HHMMSSString: string) => {
  const [hours, minutes, seconds] = HHMMSSString.split(':');
  return {
    hours: Number(hours),
    minutes: Number(minutes),
    seconds: Number(seconds),
  };
};

export const toInputTimeString = (HHMMSSString: string) => {
  const { hours, minutes } = parseTimeString(HHMMSSString);
  const HH = hours.toString().padStart(2, '0');
  const mm = minutes.toString().padStart(2, '0');
  return `${HH}:${mm}`;
};

export const toHHMMSSString = (HHMMString: string) => {
  const { hours, minutes } = parseTimeString(HHMMString);
  const HH = hours.toString().padStart(2, '0');
  const mm = minutes.toString().padStart(2, '0');
  return `${HH}:${mm}:00`;
};

export const isToday = (date: Date): boolean => {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const isValidDate = (dateString?: string): boolean => {
  if (!dateString) return false;

  const date = new Date(dateString);
  return !isNaN(date.getTime());
};
