import React from 'react';
import styled from 'styled-components';

import { Sizes, Colors } from '../../utils/style';

interface Props {
  direction: 'left' | 'right';
  handleClick: React.MouseEventHandler<HTMLDivElement>;
}

const Icon = styled.i`
  font-size: 24px;
`;

const Wrapper = styled.div<{ direction: 'left' | 'right' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  ${({ direction }) =>
    direction === 'right'
      ? `right: ${Sizes.Gutter / 2}px`
      : `left: ${Sizes.Gutter / 2}px`};
  z-index: 1;
  margin: auto;
  padding: ${Sizes.Gutter / 3}px;
  width: 40px;
  height: 40px;

  background-color: ${Colors.SemiBlack};
  color: ${Colors.White};
  border-radius: 50%;
  cursor: pointer;
  transition: transform ease-in 0.1s;
  &:hover {
    transform: scale(1.1);
  }
  img {
    transform: translateX(
      ${({ direction }) => (direction === 'left' ? '-2' : '2')}px
    );
    &:focus {
      outline: 0;
    }
  }
`;

const Arrow = (props: Props) => {
  const { direction, handleClick } = props;

  return (
    <Wrapper direction={direction} onClick={handleClick}>
      {direction === 'right' ? (
        <Icon className="las la-angle-right" />
      ) : (
        <Icon className="las la-angle-left" />
      )}
    </Wrapper>
  );
};

export default Arrow;
