import React, { FC } from 'react';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyValueList from 'components/KeyValuesList';
import { SubSection, SubSectionTitle } from '../components';
import { InventoryDeliveryType, InventoryModel, StagingLotModel } from 'api';
import { toLocalDateTimeString } from 'utils/dateTime';
import useAppTexts from 'hooks/useAppTexts';
import { Controller, Path, UseFormReturn } from 'react-hook-form';
import { DeepFilterObjectPropertyTypes } from 'utils/types/DeepFilterObjectPropertyTypes';
import { InventoryFormValues } from '../utils/inventoryFormConverters';
import SwedishDateTimePopover from 'components/form/SwedishDateInput/SwedishDateTimePopover';
import { ApiResponse } from 'api/hooks/useResponse';
import StagingLotTimeSlotDatePicker from 'components/StagingLotTimeSlotDatePicker';
import styled from 'styled-components';
import { Colors } from 'utils/style';

export const RemoveButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${Colors.Red};
`;
interface Props {
  inventory: InventoryModel;
  form: UseFormReturn<InventoryFormValues>;
  stagingLotResponse: ApiResponse<StagingLotModel>;
  onDeleteTransfer: (transferId: number) => void;
}

const ToStagingLotSubsection: FC<Props> = ({
  inventory,
  form,
  stagingLotResponse,
  onDeleteTransfer,
}) => {
  const { inventoryPickupTypes } = useAppTexts();

  const mkDateInput = (
    name: Path<DeepFilterObjectPropertyTypes<InventoryFormValues, Date | null>>
  ) => (
    <Controller
      control={form.control}
      name={name}
      render={({ field }) =>
        name === 'plannedDeliveryToRepairShop' ? (
          <StagingLotTimeSlotDatePicker
            optional
            small
            stagingLotResponse={stagingLotResponse}
            onChange={(date) => field.onChange(date)}
            value={field.value}
            ref={field.ref}
          />
        ) : (
          <SwedishDateTimePopover
            optional
            small
            onChange={(date) => field.onChange(date)}
            value={field.value}
            ref={field.ref}
          />
        )
      }
    />
  );

  const transferToRepairShop = inventory.transferToRepairShop;

  const isPerformedByAk =
    transferToRepairShop?.pickUpType === InventoryDeliveryType.Ak;
  const isRebookable = transferToRepairShop?.isRebookable;

  return (
    <SubSection>
      <SubSectionTitle>
        <FontAwesomeIcon icon={faWrench} />
        Till verkstad{' '}
        {transferToRepairShop && transferToRepairShop.isRebookable && (
          <>
            <RemoveButton
              onClick={() => onDeleteTransfer(transferToRepairShop.transferId)}
            >
              <i className="las la-trash" title="Ta bort"></i>
            </RemoveButton>
          </>
        )}
      </SubSectionTitle>

      <KeyValueList
        useEmptyPlaceholder
        items={
          isPerformedByAk
            ? [
                {
                  key: 'Utförs av',
                  value: inventoryPickupTypes[transferToRepairShop.pickUpType!],
                },
                {
                  key: 'Bokad för upphämtning',
                  value: isRebookable
                    ? mkDateInput('plannedDeliveryToRepairShop')
                    : toLocalDateTimeString(
                        transferToRepairShop.plannedDelivery
                      ),
                },
                {
                  key: 'Hämtad',
                  value: toLocalDateTimeString(
                    transferToRepairShop.actualPickup
                  ),
                },
                {
                  key: 'Ankomst verkstad',
                  value: toLocalDateTimeString(
                    transferToRepairShop.actualDelivery
                  ),
                },
              ]
            : [
                {
                  key: 'Utförs av',
                  value:
                    inventoryPickupTypes[transferToRepairShop?.pickUpType!],
                },
                {
                  key: 'Bokad för upphämtning',
                  value: mkDateInput('plannedDeliveryToRepairShop'),
                },
                {
                  key: 'Hämtad',
                  value: mkDateInput('actualPickupToRepairShop'),
                },
                {
                  key: 'Ankomst verkstad',
                  value: mkDateInput('actualDeliveryToRepairShop'),
                },
              ]
        }
      />
    </SubSection>
  );
};

export default ToStagingLotSubsection;
