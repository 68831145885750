import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { UserMenu } from './UserMenu';
import logo from 'assets/logo.svg';
import './NavMenu.css';
import { Colors, Sizes, FontSizes, MediaQuery } from 'utils/style';
import SubMenu from './SubMenu';
import ContactBox from './ContactBox';

const Branding = styled.div`
  height: 10px;
  background-color: ${Colors.Red};

  ${MediaQuery.desktop} {
    height: 20px;
  }
`;

const LogoMenu = styled.div`
  padding-left: ${Sizes.Gutter / 2}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 70px;
  border-bottom: 3px solid ${Colors.DarkWhite};

  ${MediaQuery.desktop} {
    padding-left: ${Sizes.Gutter}px;
    border-bottom: 1px solid ${Colors.Gray};
  }
`;

export const LogoMenuLink = styled.div`
  margin: 0 ${Sizes.Gutter * 2}px;
  font-size: ${FontSizes.Large}px;
  color: ${Colors.Black};
`;

const FlexGrow = styled.div`
  flex: 1;
`;

const Logo = styled.img`
  width: 180px;
  max-height: 40px;

  ${MediaQuery.desktop} {
    width: 190px;
    max-height: 45px;
  }
`;

const MenuButton = styled.button`
  border: none;
  background: transparent;
  color: ${Colors.SemiBlack};
  font-size: 30px;
  padding: 0 5px;
  margin-right: ${Sizes.Gutter / 2 - 5}px;

  ${MediaQuery.desktop} {
    display: none;
  }
`;

const DesktopVisible = styled.div`
  display: none;
  ${MediaQuery.desktop} {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

interface Props {}

const NavMenu: React.FC<Props> = () => {
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <header>
      <Branding />
      <LogoMenu>
        <FlexGrow>
          <Link to="/">
            <Logo src={logo} alt="Logo" />
          </Link>
        </FlexGrow>

        <DesktopVisible>
          <UserMenu />
          <ContactBox />
        </DesktopVisible>

        <MenuButton onClick={() => setMenuVisible(!menuVisible)}>
          <i className="las la-bars" />
        </MenuButton>
      </LogoMenu>
      <SubMenu visible={menuVisible} onClose={() => setMenuVisible(false)} />
    </header>
  );
};

export default NavMenu;
