import React from 'react';
import styled from 'styled-components';
import { NotificationReceiversClient } from 'api';
import Button from 'components/primitives/Button';
import useModalStack from 'components/Modal/useModalStack';
import ButtonsModal from 'components/Modal/ButtonsModal';

const MyButton = styled(Button)`
  margin-right: auto;
  margin-bottom: 20px;
`;

const TestNotificationButton = () => {
  const modalStack = useModalStack();

  const handleClick = () => {
    const run = async () => {
      const client = new NotificationReceiversClient();
      const response = await client.testReport();
      console.log('testReport response', response);
      if (response) {
        alert('Test-notifikation skickad ');
      } else {
        alert('Test-notifikation-FEL ');
      }
    };

    modalStack.push(
      <ButtonsModal
        title="Skicka testnotifikation?"
        onClose={() => modalStack.pop()}
        buttons={[
          {
            label: 'Skicka testnotifikation',
            onClick: run,
          },
          { label: 'Avbryt' },
        ]}
      >
        Är du säker på att du vill skicka ut en testnotifikation till alla som
        är registrerade på notifikationslistan?
      </ButtonsModal>
    );
  };

  return (
    <MyButton onClick={handleClick}>
      <i className="las la-envelope" /> Skicka testnotifikation
    </MyButton>
  );
};

export default TestNotificationButton;
