import React from 'react';
import useModalStack from 'components/Modal/useModalStack';
import { useRef } from 'react';
import { ApiException } from 'api';
import Container from 'components/primitives/Container';
import Modal from 'components/Modal';
import Routes from 'utils/routes';
import { useHistory } from 'react-router';

type ErrorHandler = (error: unknown) => void;

const useFetchErrorHandler = (
  customErrorMessage?: string,
  ignoreUnauthorizedErrors?: boolean
): [ErrorHandler, React.MutableRefObject<ErrorHandler>] => {
  const modalStack = useModalStack();
  const history = useHistory();

  const handlerRef = useRef<ErrorHandler>((error) => {
    console.error('useFetchErroHandler fetch error: ', error);

    if (error instanceof ApiException) {
      // HTTP Unauthorized
      if (!ignoreUnauthorizedErrors && error.status === 401) {
        alert('Du verkar inte vara inloggad, pröva logga ut och in igen');
        window.location.pathname = Routes.Logout;
        return;
      }
      if (error.status === 403) {
        alert('Du har inte åtkomst till den här resursen');
        history.replace(Routes.Home);
        return;
      }
    }
    modalStack.push(
      <Modal title="Fel" onClose={() => modalStack.pop()}>
        <Container>
          {customErrorMessage || 'Ett fel har inträffat:'}

          {error instanceof Error && (
            <b>
              <pre>{error.message}</pre>
            </b>
          )}
          {error instanceof ApiException && <pre>{error.response}</pre>}
        </Container>
      </Modal>
    );
  });

  return [handlerRef.current, handlerRef];
};

export default useFetchErrorHandler;
