import useApiCall from 'api/hooks/useApiCall';
import { UserClient } from 'api';
import { useCallback } from 'react';
import { CustomerIdWatchCookie } from 'utils/CustomerIdCookieWatcher';

const useSetSelectedCustomer = () => {
  const changeCustomerCall = useApiCall(
    new UserClient(),
    (client, customerId: number) => client.changeCustomer(customerId)
  );

  return [
    useCallback(
      async (customerId: number) => {
        const [, error] = await changeCustomerCall.run(customerId);
        if (!error) {
          document.cookie = `${CustomerIdWatchCookie}=${customerId}; path=/`;
          window.location.reload();
        }
      },
      [changeCustomerCall]
    ),
    changeCustomerCall.status,
  ] as const;
};

export default useSetSelectedCustomer;
