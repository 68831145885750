import React, { FC } from 'react';
import styled from 'styled-components';

import Text from './primitives/Text';
import Label from './primitives/Label';

interface Props {
  label: string;
  text?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LabeledText: FC<Props> = (props) => (
  <Wrapper>
    <Text>{props.label}</Text>
    <Label>{props.text || '-'}</Label>
  </Wrapper>
);

export default LabeledText;
