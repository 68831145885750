export const Colors = {
  Red: 'rgb(226, 4, 19)',
  RedSelected: 'rgb(170, 3, 15)', // 75% of original values
  RedDisabled: 'rgba(226, 4, 19, 0.5)',
  Black: 'rgb(2, 2, 2)',
  SemiBlack: 'rgba(2, 2, 2, 0.6)',
  White: 'rgb(255, 255, 255)',
  DarkWhite: '#EFEFEF',
  Gray: 'rgb(208, 208, 208)',
  DarkGray: 'rgb(150, 150, 150)',
  ItemHover: '#fcfafa',
  Selected: '#49f',
  Good: '#0a0',
  Warn: '#f60',
} as const;

export const Sizes = {
  Gutter: 24,
  Map: 500,
} as const;

export const FontSizes = {
  Normal: 14,
  Large: 16,
  Larger: 20,
  Huge: 24,
  Heading: 36,
  Mega: 80,
} as const;

export const DeviceMinWidths = {
  tablet: 768,
  desktop: 1200,
};

export const MediaQuery = {
  /** OBS: make phone styling the default and override it with tablet or desktop media queries */
  phone: `@media only screen and (max-width: ${DeviceMinWidths.tablet}px)`,
  tablet: `@media only screen and (min-width: ${DeviceMinWidths.tablet}px)`,
  desktop: `@media only screen and (min-width: ${DeviceMinWidths.desktop}px)`,
} as const;
