import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Container from 'components/primitives/Container';
import Routes, { RouteParams } from 'utils/routes';
import PageHead from 'components/primitives/PageHead';
import useResponse from 'api/hooks/useResponse';
import {
  UserAdminClient,
  Role,
  UserInfo,
  CustomerAdminClient,
  CustomerPreviewModel,
} from 'api';
import ManageRequestStatus from 'components/ManageRequestStatus';
import useApiCall, { RequestStatus } from 'api/hooks/useApiCall';
import EditableList from 'components/Table/EditableList';
import { Sizes, Colors, MediaQuery } from 'utils/style';
import LabeledText from 'components/LabeledText';
import useParam from 'hooks/useParam';
import useModalStack from 'components/Modal/useModalStack';
import ButtonsModal from 'components/Modal/ButtonsModal';
import { useHistory } from 'react-router-dom';

const MyContainer = styled(Container)`
  flex: 1;
  display: flex;
  flex-direction: column;

  padding-top: ${Sizes.Gutter}px;
  padding-bottom: ${Sizes.Gutter}px;

  ${MediaQuery.desktop} {
    flex-direction: row;
    border-top: 1px solid ${Colors.Gray};
  }
`;

const ListWrapper = styled.div`
  &:first-child {
    padding-bottom: ${Sizes.Gutter * 2}px;
  }

  ${MediaQuery.desktop} {
    flex: 2;

    &:first-child {
      flex: 1;
      margin-bottom: 0;
      margin-right: ${Sizes.Gutter}px;
      padding-right: ${Sizes.Gutter}px;
      border-right: 1px solid ${Colors.Gray};
    }
  }
`;

const UserAdminPage: FunctionComponent = () => {
  // we assume that this page's route only matches if there's an id
  const userId = useParam(RouteParams.userId)!;
  const history = useHistory();
  const modalStack = useModalStack();

  const userResponse = useResponse(
    new UserAdminClient(),
    (client) => client.getUser(userId),
    [userId]
  );
  const user = userResponse.response;
  const customersResponse = useResponse(new CustomerAdminClient(), (client) =>
    client.getCustomers()
  );

  const addRoleCall = useApiCall(
    new UserAdminClient(),
    async (client, role: Role) => client.addUserRole(userId, role)
  );
  const removeRoleCall = useApiCall(
    new UserAdminClient(),
    (client, role: Role) => client.removeUserRole(userId, role)
  );

  const deleteUserCall = useApiCall(
    new UserAdminClient(),
    (client, userId: string) => client.dELETEUserEveryWhere(userId)
  );

  const addCustomerCall = useApiCall(
    new UserAdminClient(),
    (client, customerId: number) => client.addUserToCustomer(userId, customerId)
  );
  const removeCustomerCall = useApiCall(
    new UserAdminClient(),
    (client, customerId: number) =>
      client.removeUserCustomer(userId, customerId)
  );

  const handleAddRole = async (role: Role) => {
    const [newRoles] = await addRoleCall.run(role);

    if (newRoles) {
      const newUser = new UserInfo({
        ...userResponse.response,
        roles: newRoles,
      });

      userResponse.update(newUser);
    }
  };

  const handleRemoveRole = async ({ role }: { role: Role }) => {
    modalStack.push(
      <ButtonsModal
        title={`Ta bort roll?`}
        onClose={() => modalStack.pop()}
        buttons={[
          {
            label: 'Ta bort',
            onClick: async () => {
              const [newRoles] = await removeRoleCall.run(role);

              if (newRoles) {
                const newUser = new UserInfo({
                  ...userResponse.response,
                  roles: newRoles,
                });

                userResponse.update(newUser);
              }
            },
          },
          { label: 'Avbryt' },
        ]}
      >
        Vill du ta bort rollen "<b>{Role[role]}</b>" från{' '}
        {user?.displayName ? <b>{user.displayName}</b> : 'användaren'}?
      </ButtonsModal>
    );
  };

  const handleDeleteUser = () => {
    if (user && user.id) {
      modalStack.push(
        <ButtonsModal
          title={`Ta bort användaren ${user.displayName}?`}
          onClose={() => modalStack.pop()}
          buttons={[
            {
              label: (
                <>
                  <i className="las la-exclamation-triangle" /> Ta bort
                </>
              ),
              onClick: async () => {
                userResponse.setStatus(RequestStatus.Fetching);
                const [, error] = await deleteUserCall.run(user.id!);
                if (error) {
                  userResponse.setStatus(RequestStatus.Fetched);
                } else {
                  history.replace(Routes.AdminUsersTab);
                }
              },
            },
            { label: 'Avbryt' },
          ]}
        >
          OBS: Detta går inte att ångra.
        </ButtonsModal>
      );
    }
  };

  const handleAddCustomer = async (customer: CustomerPreviewModel) => {
    const [, error] = await addCustomerCall.run(customer.id);
    if (!error) {
      userResponse.update();
    }
  };

  const handleRemoveCustomer = async (customer: CustomerPreviewModel) => {
    modalStack.push(
      <ButtonsModal
        title={`Ta bort kund?`}
        onClose={() => modalStack.pop()}
        buttons={[
          {
            label: 'Ta bort',
            onClick: async () => {
              const [, error] = await removeCustomerCall.run(customer.id);
              if (!error) {
                userResponse.update();
              }
            },
          },
          { label: 'Avbryt' },
        ]}
      >
        Vill du ta bort kunden "<b>{customer.displayName}</b>" från{' '}
        {user?.displayName ? <b>{user.displayName}</b> : 'användaren'}?
      </ButtonsModal>
    );
  };

  const addableRoles = (
    Object.values(Role).filter((r) => typeof r === 'number') as Role[]
  )
    .filter((role) => user?.roles?.find((r) => r === role) === undefined)
    .map((role) => ({ name: Role[role], value: role }));

  const addableCustomers =
    customersResponse.response
      ?.filter(
        (c) =>
          user?.memberOfCustomers?.find((cu) => cu.id === c.id) === undefined
      )
      .map((c) => ({
        name: c.displayName!,
        value: c,
      })) ?? [];

  return (
    <>
      <PageHead
        backTo={Routes.AdminUsersTab}
        backToLabel="Admin"
        actions={[
          {
            label: (
              <>
                <i className="las la-exclamation-triangle" /> Ta bort användare
              </>
            ),
            onClick: handleDeleteUser,
            disabled: userResponse.status !== RequestStatus.Fetched,
          },
        ]}
      >
        Redigerar användare
      </PageHead>

      <ManageRequestStatus
        status={[userResponse.status, customersResponse.status]}
      >
        {user && (
          <MyContainer>
            <ListWrapper>
              <LabeledText label="Namn" text={user.displayName} />
              <LabeledText label="Id" text={user.id} />
              <LabeledText label="Email" text={user.email} />
              <LabeledText label="Telefon" text={user.phoneNumber} />

              <EditableList
                title="Roller"
                addButtonText="Lägg till roll"
                addableItems={addableRoles}
                columnSettings={[
                  {
                    header: 'Roll',
                    attribute: 'role',
                    formatter: (role) => Role[role],
                  },
                ]}
                onAddItem={handleAddRole}
                onRemoveItem={handleRemoveRole}
                rows={user.roles?.map((role) => ({ role })) ?? []}
              />
            </ListWrapper>

            <ListWrapper>
              <EditableList
                title="Kopplad till kunder"
                addButtonText="Lägg till kund"
                addableItems={addableCustomers}
                columnSettings={[
                  {
                    header: 'Kund',
                    attribute: 'displayName',
                  },
                  {
                    header: 'Verkstads-id',
                    attribute: 'repairshopId',
                  },
                  {
                    header: 'Organisationsnummer',
                    attribute: 'orgNr',
                  },
                ]}
                onAddItem={handleAddCustomer}
                onRemoveItem={handleRemoveCustomer}
                rows={user.memberOfCustomers ?? []}
              />
            </ListWrapper>
          </MyContainer>
        )}
      </ManageRequestStatus>
    </>
  );
};

export default UserAdminPage;
