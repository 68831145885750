import React, { FunctionComponent } from 'react';
import useResponse from 'api/hooks/useResponse';
import { StationAdminClient } from 'api';
import styled, { css } from 'styled-components';
import Container from 'components/primitives/Container';
import SortedTable from 'components/Table/SortedTable';
import ManageRequestStatus from 'components/ManageRequestStatus';
import Button from 'components/primitives/Button';
import { Link, generatePath } from 'react-router-dom';
import { Sizes, Colors } from 'utils/style';
import Routes, { RouteParams } from 'utils/routes';
import useApiCall from 'api/hooks/useApiCall';
import useModalStack from 'components/Modal/useModalStack';
import AddStationModal from './AddStationModal';

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
`;

const MyButton = styled(Button)`
  margin: ${Sizes.Gutter}px 0;
  margin-left: auto;
`;

const EditButton = styled(Link)`
  &,
  &:hover,
  &:visited {
    background: transparent;
    color: ${Colors.Red};
    font-size: 20px;
  }
`;

const StationsTab: FunctionComponent = () => {
  const modalStack = useModalStack();

  const stationsResponse = useResponse(new StationAdminClient(), (client) =>
    client.getStations()
  );
  const addStationCall = useApiCall(
    new StationAdminClient(),
    (client, name: string, zipCode: string, postAddress: string) =>
      client.createStation(name, zipCode, postAddress)
  );

  const handleAddStationClick = () => {
    if (stationsResponse.response) {
      modalStack.push(
        <AddStationModal
          onAddStation={async (name, zipCode, postAddress) => {
            const [newStation] = await addStationCall.run(
              name,
              zipCode,
              postAddress
            );
            if (newStation) {
              stationsResponse.update([
                ...stationsResponse.response!,
                newStation,
              ]);
            }
          }}
        />
      );
    }
  };

  return (
    <ManageRequestStatus
      status={[stationsResponse.status, addStationCall.status]}
    >
      <Wrapper>
        <MyButton onClick={handleAddStationClick}>
          <i className="las la-plus" /> Lägg till station
        </MyButton>

        <SortedTable
          rows={stationsResponse.response ?? []}
          columnSettings={[
            {
              header: 'Id',
              attribute: 'id',
              width: '70px',
            },
            {
              header: 'Namn',
              attribute: 'name',
            },
            {
              header: 'Adress',
              attribute: 'postAddress',
            },
            {
              header: 'Postkod',
              attribute: 'zipCode',
            },
            {
              header: '',
              cellRenderer: ({ row }) => (
                <EditButton
                  to={generatePath(Routes.StationAdmin, {
                    [RouteParams.stationId]: row.id,
                  })}
                >
                  <i className="las la-edit" />
                </EditButton>
              ),
              notSortable: true,
              width: '50px',
              cellStyle: css`
                padding-bottom: 0;
                padding-top: 0;
              `,
            },
          ]}
        />
      </Wrapper>
    </ManageRequestStatus>
  );
};

export default StationsTab;
