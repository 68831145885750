import { StagingLotTimeSlotGroupModel } from 'api';
import { parseTimeString } from 'utils/dateTime';

const useMatchingTimeSlots = (
  timeSlots: StagingLotTimeSlotGroupModel[] | null | undefined,
  selectedDate: Date | undefined
): StagingLotTimeSlotGroupModel[] | null => {
  if (!timeSlots) {
    return null;
  }

  const dayOfWeek = selectedDate?.getDay();
  const selectedHour = selectedDate?.getHours();
  const selectedMinute = selectedDate?.getMinutes();

  const timeSlotsForSelectedDate = timeSlots?.filter((ts) => {
    if (
      typeof dayOfWeek !== 'number' ||
      typeof selectedHour !== 'number' ||
      typeof selectedMinute !== 'number'
    )
      return true;

    const startTime = parseTimeString(ts.startTime);
    const endTime = parseTimeString(ts.endTime);

    return (
      ts.days?.includes(dayOfWeek) &&
      startTime.hours <= selectedHour &&
      endTime.hours >= selectedHour &&
      (startTime.hours !== selectedHour ||
        startTime.minutes <= selectedMinute) &&
      (endTime.hours !== selectedHour || endTime.minutes >= selectedMinute)
    );
  });

  return timeSlotsForSelectedDate;
};

export default useMatchingTimeSlots;
