import React, { FC, useRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import { Input } from '..';
import { DatePickerGlobalStyles } from './SwedishInlineDateInput';
import styled from 'styled-components';

registerLocale('sv', sv);

const MyInput = styled(Input)`
  width: 100px;
  min-width: 0;
`;

interface Props {
  value: string | null;
  onChange: (value: string | null) => void;
  popperClassName?: string;
  small?: boolean;
}
const SwedishTimeStringInput: FC<Props> = ({
  value,
  onChange,
  popperClassName,
  small,
}) => {
  const inputRef = useRef(null);

  const handleOnChange = (date: Date | null) => {
    if (!date) {
      onChange(null);
      return;
    }
    let hour = date.getHours().toString();
    let minute = date.getMinutes().toString();

    hour = hour.padStart(2, '0');
    minute = minute.padStart(2, '0');

    onChange(hour + ':' + minute);
  };

  return (
    <>
      <DatePicker
        showPopperArrow={false}
        selected={
          value
            ? new Date(
                0,
                0,
                0,
                parseInt(value.split(':')[0]),
                parseInt(value.split(':')[1])
              )
            : undefined
        }
        onChange={(date) => handleOnChange(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeFormat="HH:mm"
        dateFormat="HH:mm"
        locale={sv}
        customInput={<MyInput small={small} ref={inputRef} />}
        popperClassName={popperClassName}
      />
      <DatePickerGlobalStyles />
    </>
  );
};

export default SwedishTimeStringInput;
