import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Container from 'components/primitives/Container';
import { FontSizes, Sizes, MediaQuery, Colors } from 'utils/style';
import { InventoryClient, InventoryModel, InventorySearchRequest } from 'api';
import useResponse from 'api/hooks/useResponse';
import { toLocalDateTimeString } from 'utils/dateTime';
import InventoryModal from './InventoryModal';
import { Switch, Route } from 'react-router';
import Routes from 'utils/routes';
import Button from 'components/primitives/Button';
import useModalStack from 'components/Modal/useModalStack';
import AddInventoryModal from './AddInventoryModal';
import SearchBar from 'components/SearchBar';
import useApiCall from 'api/hooks/useApiCall';
import Checkbox from 'components/form/Checkbox';
import InventoryTable, {
  InventoryModelToInventoryTableItem,
} from './InventoryTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import HelpModal from './HelpModal';
import { PreventLayoutOverflow } from 'components/Layout';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: ${Sizes.Gutter / 2}px;
  padding-bottom: ${Sizes.Gutter * 2}px;

  ${MediaQuery.tablet} {
    padding-top: ${Sizes.Gutter}px;
  }
`;

const PageFooter = styled(Container)`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 5px;
  bottom: 0;
  background-color: ${Colors.DarkWhite};
  padding-top: ${Sizes.Gutter / 2}px;
  padding-bottom: ${Sizes.Gutter / 2}px;
  width: 100%;
  border-top: 1px solid ${Colors.Gray};
  z-index: 4;

  ${MediaQuery.tablet} {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .inventory-count {
    font-weight: bold;
  }

  .nowrap-date {
    white-space: nowrap;
  }
`;

const Head = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  background: ${Colors.DarkWhite};

  margin: 0;

  ${MediaQuery.tablet} {
    padding: ${Sizes.Gutter}px ${Sizes.Gutter}px 0;
    flex-direction: row;
    background: transparent;
    text-transform: uppercase;

    & > h1 {
      font-size: ${FontSizes.Heading}px;
      padding: 0;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  h1 {
    color: ${Colors.Black};
    font-size: ${FontSizes.Huge}px;
    font-weight: bold;
    padding: ${Sizes.Gutter / 2}px ${Sizes.Gutter / 2}px 0;
    margin: 0;
  }

  h2 {
    color: ${Colors.Black};
    font-size: ${FontSizes.Normal}px;
    font-weight: bold;
    text-transform: none;
    padding: ${Sizes.Gutter / 2}px ${Sizes.Gutter / 2}px;
    margin: 0;
  }

  ${MediaQuery.tablet} {
    h1 {
      font-size: ${FontSizes.Heading}px;
      padding: 0;
    }

    h2 {
      font-size: ${FontSizes.Larger}px;
      padding: ${Sizes.Gutter / 2}px 0 0;
    }
  }
`;

const HeaderInputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${MediaQuery.tablet} {
    flex-direction: row;
    justify-content: flex-end;
    gap: ${Sizes.Gutter}px;
    height: 40px;
  }
`;

const HelpButton = styled.button`
  flex-direction: row;
  gap: 5px;
  align-items: center;
  width: max-content;
  background-color: transparent;
  border: none;
  outline: none;
  padding: ${Sizes.Gutter / 2}px 0px;
  color: ${Colors.Black};
  text-decoration: underline;
  order: -2;
  display: none;
  color: ${Colors.SemiBlack};

  &.mobile-only {
    display: flex;
    order: 1;
    padding: ${Sizes.Gutter / 2}px ${Sizes.Gutter / 2}px 0;
  }

  &:hover {
    color: ${Colors.Red};
  }

  svg {
    color: ${Colors.DarkGray};
  }

  ${MediaQuery.tablet} {
    display: flex;

    &.mobile-only {
      display: none;
    }
  }
`;

const AddInventoryButton = styled(Button)`
  padding: 8px 18px;
`;

const MySearchBar = styled(SearchBar)`
  width: 100%;
  order: 2;

  ${MediaQuery.tablet} {
    order: -1;
    width: auto;
  }
`;

const FilterContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;

  label {
    margin: 0;
  }
`;

const Inventory = () => {
  const modalStack = useModalStack();
  const [searchResult, setSearchResult] = useState<InventoryModel[] | null>(
    null
  );
  const [includeClosed, setIncludeClosed] = useState<boolean>(false);

  const inventoryModelList = useResponse(new InventoryClient(), (client) =>
    client.getAllInventories()
  );

  const inventorySearch = useApiCall(new InventoryClient(), (c, request) =>
    c.search(request)
  );

  const inventoryTableList = useMemo(() => {
    if (searchResult) {
      return searchResult.map((model) =>
        InventoryModelToInventoryTableItem(model)
      );
    } else {
      return inventoryModelList?.response?.map((model) =>
        InventoryModelToInventoryTableItem(model)
      );
    }
  }, [inventoryModelList?.response, searchResult]);

  const fetchDate = useMemo(() => {
    return toLocalDateTimeString(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryModelList?.response]);

  const debounceAsync = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;

    return (searchTerm: string) => {
      return new Promise((resolve) => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(async () => {
          const result = await func(searchTerm);
          resolve(result);
        }, delay);
      });
    };
  };

  const onSearch = debounceAsync(async (searchTerm: string) => {
    if (!searchTerm) {
      setSearchResult(null);
      inventoryModelList.update();
    } else {
      const request = new InventorySearchRequest({
        registrationNumber: searchTerm,
        includeClosed: includeClosed,
      });
      const [response, error] = await inventorySearch.run(request);
      if (!error && response) {
        setSearchResult(response);
      } else {
        setSearchResult(null);
        inventoryModelList.update();
      }
    }
  }, 500);

  if (!inventoryModelList?.response || !inventoryTableList) {
    return null;
  }

  return (
    <Wrapper>
      <PreventLayoutOverflow />

      <Head>
        <TitleWrapper>
          <div>
            <h1>Uppställning</h1>
            <HelpButton
              className="mobile-only"
              onClick={() =>
                modalStack.push(<HelpModal onClose={() => modalStack.pop()} />)
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              Hjälp
            </HelpButton>
          </div>
          <h2>{inventoryModelList.response.length ?? '0'} fordon</h2>
        </TitleWrapper>
        <HeaderInputs>
          <HelpButton
            onClick={() =>
              modalStack.push(<HelpModal onClose={() => modalStack.pop()} />)
            }
          >
            <FontAwesomeIcon icon={faInfoCircle} />
            Hjälp
          </HelpButton>
          <MySearchBar
            onChange={(searchTerm) => onSearch(searchTerm)}
            placeholder="Sök på reg. nr"
            showFilter
            filterActive={includeClosed}
            filterContent={
              <FilterContent>
                <Checkbox
                  id="include-closed"
                  checked={includeClosed}
                  onChange={() => {
                    setIncludeClosed(!includeClosed);
                  }}
                />
                <label htmlFor="include-closed">
                  Inkludera stängda ärenden
                </label>
              </FilterContent>
            }
          />
          <AddInventoryButton
            variant="secondary"
            onClick={() =>
              modalStack.push(
                <AddInventoryModal
                  onClose={(newInventory) => {
                    modalStack.pop();
                    if (newInventory) {
                      inventoryModelList.update();
                    }
                  }}
                />
              )
            }
          >
            <i className="las la-plus"></i> Egen uppställning
          </AddInventoryButton>
        </HeaderInputs>
      </Head>
      <MyContainer>
        <InventoryTable inventories={inventoryTableList} />
      </MyContainer>

      <Switch>
        <Route path={Routes.InventoryItem}>
          <InventoryModal
            onInventoryUpdated={() => {
              inventoryModelList.update();
            }}
          />
        </Route>
      </Switch>
      <PageFooter>
        <div>
          Visar {inventoryTableList.length} fordon - senast uppdaterad:{' '}
          <span className={'nowrap-date'}>{fetchDate}</span>
        </div>
      </PageFooter>
    </Wrapper>
  );
};

export default Inventory;
