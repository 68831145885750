import {
  FieldValues,
  Path,
  RegisterOptions,
  FieldErrors,
  Control,
} from 'react-hook-form';
import { FC } from 'react';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import 'react-datepicker/dist/react-datepicker.css';
import styled, { createGlobalStyle } from 'styled-components';
import { Colors, FontSizes } from 'utils/style';
import { slideUpAndFade } from 'components/animations/fadeIn';

registerLocale('sv', sv);
const boxShadow = `box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2), 0 5px 90px rgba(0, 0, 0, 0.1);`;

export const DatePickerGlobalStyles = createGlobalStyle`
  // general styling of date picker

  .react-datepicker-popper {
    z-index: 2;

    &[data-placement^='bottom'] {
      padding-top: 0;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    font-size: ${FontSizes.Normal};
    /* ${boxShadow} */
    border-color: ${Colors.Gray};
    border: none;
    border-radius: 0;
  }
  .react-datepicker__header {
    padding-top: 0;
    background-color: ${Colors.DarkWhite};
    border: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    &:not(.react-datepicker__header--has-time-select) {
      border-top-right-radius: 0;
    }
    
    &--time--only {
      display: none;
    }
  }

  .react-datepicker__month {
    margin: 0;
    background-color: ${Colors.DarkWhite};
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 40px;
    line-height: 35px;
    margin: 2px;
  }
  .react-datepicker__day-names {
    padding-top: 5px;

    .react-datepicker__day-name {
      line-height: 2em;
    }
  }

  .react-datepicker__day {
    background-color: ${Colors.White};
    
    &:hover {
      border-radius: 0;
    }

    &--weekend {
      background-color: ${Colors.Gray};
    }

    &--keyboard-selected {
      border-radius: 0;

      &:hover {
        background-color: ${Colors.DarkWhite};
      }
    }

    &--outside-month {
      color: ${Colors.DarkGray};
      background-color: ${Colors.DarkWhite};
    }

    &--selected {
      background-color: ${Colors.Red};
      color: ${Colors.White};
      border-radius: 0;
      
      &:hover {
        background-color: ${Colors.RedSelected};
      }
    }
  }

  .reac-datepicker--time-only {
  }

  .react-datepicker__time-box {
    border-radius: 5px;
  }

  .react-datepicker__time {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2), 0 5px 90px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    animation: ${slideUpAndFade} 0.2s;
  }

  .react-datepicker__year-read-view--selected-year,
  .react-datepicker__month-read-view--selected-month {
    font-weight: bold;
  }

  .react-datepicker__close-icon::after {
    color: ${Colors.Red};
    background-color: transparent;
    font-size: ${FontSizes.Larger}px;
  }

  .react-datepicker__time-container {
    .react-datepicker__time {
      .react-datepicker__time-box {
        ul.react-datepicker__time-list {
          li.react-datepicker__time-list-item {
            padding: 10px;
            height: auto;
            border-bottom: 1px solid ${Colors.Gray};


            &--selected {
              background-color: ${Colors.Red};
              border-radius: 0;

              &:hover {
                background-color: ${Colors.RedSelected};
              }
            }
          }
        }
      }
    }
  }

  .react-datepicker__navigation-icon::before {
    border-color: ${Colors.Red};
    border-width: 2px 2px 0 0;

    .react-datepicker__navigation:hover & {
      border-color: ${Colors.RedSelected};
    }
  }

  .react-datepicker__navigation {
    top: 0px;
    line-height: 20px;
  }
`;

const DatePickerWrapper = styled.div`
  width: 100%;

  input {
    width: 100%;
  }
`;

export type DateInputRules = Omit<RegisterOptions, 'onChange'> & {
  onChange?(newDate: Date | null): void;
};

export interface FormInputProps<T extends FieldValues>
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name' | 'form'> {
  control: Control<T>;
  name: Path<T>;
  rules?: DateInputRules;
  label?: string;

  errors: FieldErrors<T>;
  readonly: boolean;
}

interface Props {
  value: Date | null;
  onChange: (newDate: Date | null) => void;
}

const SwedishInlineDateInput: FC<Props> = ({
  value,
  onChange,
}): JSX.Element => {
  return (
    <>
      <DatePickerWrapper>
        <DatePicker
          inline
          selected={value ?? undefined}
          locale={sv}
          onChange={(date) => {
            onChange(date);
          }}
          wrapperClassName="react-datepicker-wrapper"
        />
      </DatePickerWrapper>
      <DatePickerGlobalStyles />
    </>
  );
};

export default SwedishInlineDateInput;
