import styled from 'styled-components';
import { Sizes, MediaQuery } from '../../utils/style';

export const TableActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${Sizes.Gutter / 2}px;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }

  ${MediaQuery.tablet} {
    flex-direction: row;
    justify-content: flex-end;

    & > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
`;

export const TableActionsTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 24px;
  font-weight: bold;
  flex: 1;
`;
