import React from 'react';
import Modal, { Props as ModalProps } from '.';
import styled from 'styled-components';
import Button, { ButtonVariant } from 'components/primitives/Button';
import useModalStack from './useModalStack';
import { MediaQuery, Sizes } from 'utils/style';
import Container from 'components/primitives/Container';

export const modalContainerClass = 'modal-container';

const MyModal = styled(Modal)``;

const MyContainer = styled(Container)`
  overflow-y: auto;
`;

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${Sizes.Gutter}px ${Sizes.Gutter / 2}px;
  gap: ${Sizes.Gutter / 2}px;

  ${MediaQuery.tablet} {
    padding: ${Sizes.Gutter}px;
    gap: ${Sizes.Gutter / 2}px;
  }
`;

const MyButton = styled(Button)``;

export interface ModalButton {
  label: React.ReactChild;
  variant?: ButtonVariant;
  /** if left undefined it defaults to closing the modal */
  onClick?(): void;
  dontPopAfterClick?: boolean;
  disabled?: boolean;
  /** If true: don't render this button. */
  hide?: boolean;
}

interface Props extends ModalProps {
  buttons: ModalButton[];
}

const ButtonsModal: React.SFC<Props> = ({
  children,
  buttons,
  ...modalProps
}) => {
  const modalStack = useModalStack();

  return (
    <MyModal {...modalProps}>
      <MyContainer className={modalContainerClass}>{children}</MyContainer>

      <ButtonsWrap>
        {buttons
          .filter((b) => !b.hide)
          .map((button, index) => (
            <MyButton
              key={index}
              onClick={() => {
                button.onClick && button.onClick();
                if (!button.dontPopAfterClick) {
                  modalStack.pop();
                }
              }}
              variant={button.variant}
              disabled={button.disabled}
            >
              {button.label}
            </MyButton>
          ))}
      </ButtonsWrap>
    </MyModal>
  );
};

export default ButtonsModal;
