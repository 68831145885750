import { useRef, useState, useEffect } from 'react';

const useDimensions = <ElementType extends HTMLElement>(delay: number = 0) => {
  const ref = useRef<ElementType | null>(null);
  const [dimensions, setDimensions] = useState<
    ClientRect | DOMRect | undefined
  >();
  const firstDelayValue = useRef(delay); // to ignore useEffect missing dependency warning
  const timeout = useRef<ReturnType<typeof setTimeout>>(-1 as any);

  useEffect(() => {
    const handleResize = () => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        if (ref && ref.current) {
          setDimensions(ref.current.getBoundingClientRect());
        }
      }, firstDelayValue.current);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return [ref, dimensions] as const;
};

export default useDimensions;
