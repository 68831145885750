import React, { useRef, useLayoutEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';

import { Colors, FontSizes, Sizes, MediaQuery } from '../../utils/style';
import H2 from 'components/primitives/H2';

const fadeInClassname = 'ak-headsup-fade-in-modal';
const fadeOutClassname = 'ak-headsup-fade-out-modal';
const fadingTime = 0.15;
export const modalBodyClass = 'ak-headsup-modal-body';

const fadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 98;

  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: center;
  align-items: flex-end;
  animation: ${fadeIn} ${fadingTime}s;

  &:not(.${fadeOutClassname}) {
    animation: ${fadeIn} ${fadingTime}s;
  }
  &.${fadeOutClassname} {
    animation: ${fadeIn} ${fadingTime}s reverse;
  }

  ${MediaQuery.tablet} {
    align-items: center;
  }
`;

const glideIn = keyframes`
0% {
  opacity: 0;
  transform: translateY(20px) scale(0.95);
}
100% {
  opacity: 1;
  transform: translateY(0px) scale(1);
}
`;

const glideInPhone = keyframes`
0% {
  transform: translateY(20px);
}
100% {
  transform: translateY(0px);
}
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 99;
  position: relative;
  background-color: ${Colors.White};

  width: 100vw;
  max-height: 95svh;
  max-width: 100svw;
  animation: ${glideIn} ${fadingTime}s;

  &:not(.${fadeOutClassname}) {
    animation: ${glideIn} ${fadingTime}s;
  }
  &.${fadeOutClassname} {
    animation: ${glideIn} ${fadingTime}s reverse;
  }

  ${MediaQuery.tablet} {
    width: auto;
    height: auto;
    max-width: 90vw;
  }

  ${MediaQuery.phone} {
    &:not(.${fadeOutClassname}) {
      animation: ${glideInPhone} ${fadingTime}s;
    }
    &.${fadeOutClassname} {
      animation: ${glideInPhone} ${fadingTime}s reverse;
    }
  }
`;

const TitleBar = styled.div<{ condensed?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-bottom: ${Sizes.Gutter / 2}px;
  background-color: ${Colors.DarkWhite};

  ${({ condensed }) =>
    condensed &&
    css`
      margin-bottom: 0;
    `}

  ${MediaQuery.tablet} {
    padding: ${Sizes.Gutter / 2}px;
    padding-bottom: 0;
    background-color: transparent;

    ${({ condensed }) =>
      condensed &&
      css`
        padding: ${Sizes.Gutter / 4}px ${Sizes.Gutter / 2}px;
        border-bottom: 1px solid ${Colors.Gray};
      `}
  }
`;

const MyH2 = styled(H2)<{ condensed?: boolean }>`
  flex: 1;
  margin: 0;
  padding: ${Sizes.Gutter / 2}px;

  ${({ condensed }) =>
    condensed &&
    css`
      font-size: 1rem;
      font-weight: normal;
      line-height: 1em;
    `}
`;

const ModalClose = styled.button<{ condensed?: boolean }>`
  padding: ${Sizes.Gutter / 2}px;
  line-height: 1em;
  appearance: none;
  border: none;
  background: transparent;
  color: ${Colors.Black};
  font-size: ${FontSizes.Huge}px;

  ${({ condensed }) =>
    condensed &&
    css`
      padding-top: ${Sizes.Gutter / 4}px;
      padding-bottom: ${Sizes.Gutter / 4}px;

      ${MediaQuery.tablet} {
        padding: 0;
      }
    `}
`;

export interface Props {
  title?: string;
  condensedTitle?: boolean;
  /**
   * Provide a function to be called when the modal is closed.
   * If undefined, the modal won't display a close button.
   */
  onClose?(): void;
  className?: string;
}

const Modal: React.FC<Props> = ({
  title,
  condensedTitle,
  children,
  onClose,
  className,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const currentOverlayRef = overlayRef.current;

    currentOverlayRef?.classList.add(fadeInClassname);

    const fadeInTimeout = setTimeout(() => {
      currentOverlayRef?.classList.remove(fadeInClassname);
    }, fadingTime * 1000);

    return () => {
      clearTimeout(fadeInTimeout);

      // Prevent fading out the modal if its still fading in.
      if (currentOverlayRef?.classList.contains(fadeInClassname)) return;

      // Clone all of the DOM elements and let them fade out on unmount.
      const clonedModalDOM = currentOverlayRef?.cloneNode(true);

      if (clonedModalDOM instanceof HTMLElement) {
        clonedModalDOM.classList.add(fadeOutClassname);
        clonedModalDOM
          .querySelector(`.${modalBodyClass}`)
          ?.classList.add(fadeOutClassname);
        document.body.appendChild(clonedModalDOM);

        setTimeout(() => {
          document.body.removeChild(clonedModalDOM);
        }, fadingTime * 900);
      }
    };
  }, []);

  return (
    <Overlay onClick={onClose} ref={overlayRef}>
      <ModalWrapper
        className={`${modalBodyClass} ${className}`}
        onClick={(eve) => eve.stopPropagation()}
      >
        {(title || onClose) && (
          <TitleBar condensed={condensedTitle}>
            <MyH2 condensed={condensedTitle}>{title}</MyH2>
            {onClose && (
              <ModalClose
                type="button"
                onClick={onClose}
                condensed={condensedTitle}
              >
                <i className="las la-times" />
              </ModalClose>
            )}
          </TitleBar>
        )}
        {children}
      </ModalWrapper>
    </Overlay>
  );
};

export default Modal;
