import styled from 'styled-components';
import Spinner from '.';

const OverlaySpinner = styled(Spinner)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;
`;

export default OverlaySpinner;
