import { StagingLotModel } from 'api';
import { RequestStatus } from 'api/hooks/useApiCall';
import SwedishDateTimePopover, {
  Props as DateInputProps,
} from 'components/form/SwedishDateInput/SwedishDateTimePopover';
import React from 'react';
import styled from 'styled-components';
import StagingLotTimeSlots from './StagingLotTimeSlots';
import useMatchingTimeSlots from './useMatchingTimeSlots';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props extends Omit<DateInputProps, 'bottomContent'> {
  stagingLotResponse: {
    response?: StagingLotModel | null;
    status: RequestStatus;
  };
}

const StagingLotTimeSlotDatePicker = React.forwardRef<HTMLButtonElement, Props>(
  ({ stagingLotResponse, ...dateInputProps }, ref) => {
    const matchingTimeSlots = useMatchingTimeSlots(
      stagingLotResponse.response?.timeSlots,
      dateInputProps.value ?? undefined
    );

    const warningMessage = !matchingTimeSlots?.length
      ? `Det finns inga öppettider för
              vald dag eller tid
              ${
                stagingLotResponse.response?.customerDisplayName
                  ? ` på ${stagingLotResponse.response.customerDisplayName}.`
                  : '.'
              }`
      : undefined;

    return (
      <Wrapper>
        <SwedishDateTimePopover
          {...dateInputProps}
          warningMessage={warningMessage}
          bottomContent={() => (
            <StagingLotTimeSlots
              stagingLotResponse={stagingLotResponse}
              selectedDate={dateInputProps.value ?? undefined}
              warningMessage={warningMessage}
            />
          )}
          ref={ref}
        />
      </Wrapper>
    );
  }
);

export default StagingLotTimeSlotDatePicker;
