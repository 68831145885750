import { RepairshopInfo } from 'api';
import { Coordinate } from 'containers/MarkerMap';
import { MapPosition } from './MapPage';

export const getAddressFromCoords = (
  { lat, lng }: Coordinate,
  setIsFetching: (fetching: boolean) => void
): Promise<MapPosition> => {
  setIsFetching(true);

  const geocoder = new google.maps.Geocoder();

  return new Promise<MapPosition>((resolve) =>
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      setIsFetching(false);

      if (
        status === google.maps.GeocoderStatus.OK &&
        results &&
        results.length > 0
      ) {
        resolve({
          coordinate: {
            lat,
            lng,
          },
          address: results[0].formatted_address,
        });
      } else {
        throw Error('Kunde inte hitta addressen för platsen.');
      }
    })
  );
};

export function mapPositionFromRepairshopInfo(
  repairshopInfo: RepairshopInfo
): MapPosition {
  return {
    address: `${repairshopInfo.name} - ${repairshopInfo.address}, ${repairshopInfo.city}`,
    coordinate: {
      lat: repairshopInfo.latitude,
      lng: repairshopInfo.longitude,
    },
    repairshopId: repairshopInfo.id,
  };
}
