import styled, { css } from 'styled-components';

import selectArrow from 'assets/selectArrow.svg';
import { Colors, Sizes, FontSizes } from '../../utils/style';

interface InputStyleProps {
  small?: boolean;
}

export const inputStyle = css<InputStyleProps>`
  margin: 0;
  padding: ${Sizes.Gutter / 3}px;
  min-width: 300px;

  font: inherit;
  font-weight: normal;
  font-size: ${FontSizes.Large}px;
  border-radius: 0;
  border: 1px solid ${Colors.Gray};
  color: ${Colors.Black};
  background: ${Colors.White};
  -webkit-appearance: none;

  ${({ small }) =>
    small &&
    css`
      padding: 2px ${Sizes.Gutter / 3}px;
      min-width: 0;
      font-weight: normal;
      font-size: 1em;
    `}
`;

export const Form = styled.form``;

export const InputWrapper = styled.div`
  margin-bottom: ${Sizes.Gutter / 2}px;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label<{ error?: boolean; requiredStar?: boolean }>`
  font-size: ${FontSizes.Large}px;
  font-weight: 500;
  margin-bottom: ${Sizes.Gutter / 4}px;

  color: ${({ error }) => (error ? Colors.Red : Colors.Black)};
  ${({ requiredStar }) =>
    requiredStar &&
    css`
      &::after {
        content: ' *';
        color: red;
      }
    `}
`;

export const Input = styled.input`
  ${inputStyle}
`;

export const Select = styled.select`
  ${inputStyle}
  min-width: 0;
  padding-right: calc(${(Sizes.Gutter / 3) * 3}px + 1.5em);
  background-image: url('${selectArrow}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
`;

export const TextArea = styled.textarea`
  ${inputStyle}

  resize: none;
`;
