import React, { FC } from 'react';
import ButtonsModal from 'components/Modal/ButtonsModal';
import useResponse from 'api/hooks/useResponse';
import {
  IStagingLotTimeSlotGroupModel,
  StagingLotClient,
  StagingLotTimeSlotGroupModel,
} from 'api';
import useApiCall, { RequestStatus } from 'api/hooks/useApiCall';
import { Controller, useForm } from 'react-hook-form';
import {
  defaultTimeSlotFormValues,
  TimeSlotFormValues,
  timeSlotFormValuesToModel,
  timeSlotModelToFormValues,
} from './formConverters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { Input, InputWrapper, Label, Select } from 'components/form';
import styled from 'styled-components';
import { Sizes } from 'utils/style';
import DaysPicker from './DaysPicker';
import useUserInfo from 'hooks/useUserInfo';
import ManageRequestStatus from 'components/ManageRequestStatus';
import ValidationMessage from 'components/form/ValidationMessage';
import SwedishTimeStringInput from 'components/form/SwedishDateInput/SwedishTimeStringInput';

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Sizes.Gutter / 2}px;
`;

interface Props {
  /** If undefined, this modal will create a new timeslot */
  timeSlot?: IStagingLotTimeSlotGroupModel & { stagingLotId: number };
  onTimeslotSaved: () => void;
}

const TimeSlotUpsertModal: FC<Props> = ({ timeSlot, onTimeslotSaved }) => {
  const userInfo = useUserInfo();

  const createTimeSlotCall = useApiCall(
    new StagingLotClient(),
    (c, stagingLotId: number, timeSlot: StagingLotTimeSlotGroupModel) =>
      c.createTimeSlot(stagingLotId, timeSlot)
  );
  const updateTimeSlotCall = useApiCall(
    new StagingLotClient(),
    (c, stagingLotId: number, timeSlot: StagingLotTimeSlotGroupModel) =>
      c.updateTimeSlot(stagingLotId, timeSlot)
  );

  const stagingLotsResponse = useResponse(new StagingLotClient(), async (c) =>
    userInfo?.selectedCustomer?.stagingGroupId
      ? await c.getStagingLotsByGroupId(
          userInfo.selectedCustomer.stagingGroupId
        )
      : null
  );

  const form = useForm({
    defaultValues: timeSlot
      ? timeSlotModelToFormValues(timeSlot, timeSlot.stagingLotId)
      : defaultTimeSlotFormValues,
  });

  const handleSave = async (formValues: TimeSlotFormValues) => {
    const newTimeSlot = timeSlotFormValuesToModel(formValues, timeSlot);

    const [, error] = await (newTimeSlot.id === 0
      ? createTimeSlotCall
      : updateTimeSlotCall
    ).run(formValues.stagingLotId, newTimeSlot);

    if (!error) {
      onTimeslotSaved();
    }
  };

  const isAnyRequestLoading = [
    stagingLotsResponse.status,
    updateTimeSlotCall.status,
    createTimeSlotCall.status,
  ].some((s) => s === RequestStatus.Fetching);

  return (
    <ButtonsModal
      title="Lägg till öppettid"
      buttons={
        isAnyRequestLoading
          ? []
          : [
              {
                hide: !form.formState.isDirty,
                variant: 'secondary',
                dontPopAfterClick: true,
                onClick: () => form.reset(),
                label: (
                  <>
                    <FontAwesomeIcon icon={faUndo} /> Återställ
                  </>
                ),
              },
              {
                label: 'Spara',
                dontPopAfterClick: true,
                onClick: form.handleSubmit(handleSave),
                disabled: !form.formState.isDirty,
              },
              {
                label: 'Avbryt',
              },
            ]
      }
    >
      <ManageRequestStatus
        status={[
          stagingLotsResponse.status,
          updateTimeSlotCall.status,
          createTimeSlotCall.status,
        ]}
      >
        <InputWrapper>
          <ValidationMessage control={form.control} name="label">
            <Label requiredStar>Etikett</Label>
            <Input
              {...form.register('label', {
                required: 'Ange etikett',
              })}
            />
          </ValidationMessage>
        </InputWrapper>

        <InputWrapper>
          <ValidationMessage control={form.control} name="stagingLotId">
            <Label requiredStar>Ställplats</Label>
            <Select
              {...form.register('stagingLotId', {
                valueAsNumber: true,
                validate: (v) => v !== -1 || 'Välj ställplats',
              })}
            >
              <option value={-1} disabled>
                Välj ställplats
              </option>

              {stagingLotsResponse.response?.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.customerDisplayName}
                </option>
              ))}
            </Select>
          </ValidationMessage>
        </InputWrapper>

        <Horizontal>
          <InputWrapper>
            <Label>Från</Label>
            <ValidationMessage control={form.control} name="startTime">
              <Controller
                control={form.control}
                name={'startTime'}
                rules={{
                  required: 'Ange tid',
                }}
                render={({ field }) => (
                  <SwedishTimeStringInput
                    onChange={(timeString) => {
                      field.onChange(timeString);
                    }}
                    value={field.value}
                  />
                )}
              />
            </ValidationMessage>
          </InputWrapper>
          <InputWrapper>
            <Label>Till</Label>
            <ValidationMessage control={form.control} name="endTime">
              <Controller
                control={form.control}
                name={'endTime'}
                rules={{
                  required: 'Ange tid',
                }}
                render={({ field }) => (
                  <SwedishTimeStringInput
                    onChange={(timeString) => {
                      field.onChange(timeString);
                    }}
                    value={field.value}
                  />
                )}
              />
            </ValidationMessage>
          </InputWrapper>
        </Horizontal>

        <Controller
          control={form.control}
          name="days"
          render={({ field }) => (
            <DaysPicker
              selectedDays={new Set(field.value)}
              onSelectedDaysChanged={(newSelectedDays) => {
                field.onChange(Array.from(newSelectedDays));
              }}
            />
          )}
        />
      </ManageRequestStatus>
    </ButtonsModal>
  );
};

export default TimeSlotUpsertModal;
