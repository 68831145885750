import React, { FunctionComponent } from 'react';
import { StationAdminClient } from 'api';
import useParam from 'hooks/useParam';
import useResponse from 'api/hooks/useResponse';
import Routes, { RouteParams } from 'utils/routes';
import PageHead from 'components/primitives/PageHead';
import ManageRequestStatus from 'components/ManageRequestStatus';
import EditStationFields from './EditStationFields';
import styled from 'styled-components';
import { MediaQuery, Sizes, Colors } from 'utils/style';
import EditStationTowers from './EditStationTowers';
import useApiCall from 'api/hooks/useApiCall';
import useModalStack from 'components/Modal/useModalStack';
import ButtonsModal from 'components/Modal/ButtonsModal';
import { useHistory } from 'react-router-dom';

const Sections = styled.div`
  ${MediaQuery.desktop} {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: ${Sizes.Gutter}px;

    border-top: 1px solid ${Colors.Gray};
  }
`;

const StationAdminPage: FunctionComponent = () => {
  // we assume that this page's route only matches if there's an id
  const stationIdStr = useParam(RouteParams.stationId)!;
  const stationId = Number(stationIdStr);
  const history = useHistory();
  const modalStack = useModalStack();

  const stationResponse = useResponse(
    new StationAdminClient(),
    (client) => client.getStation(stationId),
    [stationId]
  );
  const deleteStationCall = useApiCall(
    new StationAdminClient(),
    (client, stationId: number) => client.deleteStation(stationId)
  );

  const handleDeleteClick = () => {
    if (stationResponse.response) {
      modalStack.push(
        <ButtonsModal
          title="Ta bort station?"
          onClose={() => modalStack.pop()}
          buttons={[
            {
              label: (
                <>
                  <i className="las la-exclamation-triangle" /> Ta bort station
                </>
              ),
              onClick: async () => {
                const [, err] = await deleteStationCall.run(
                  stationResponse.response!.id
                );

                if (!err) {
                  history.replace(Routes.AdminStationsTab);
                }
              },
            },
            {
              label: 'Avbryt',
            },
          ]}
        >
          Är du säker på att du vill ta bort stationen "
          <b>{stationResponse.response.name}</b>"? Den tas också bort från alla
          associerade kunder.
          <br />
          <br />
          <b>OBS: Detta går ej att ångra.</b>
        </ButtonsModal>
      );
    }
  };

  return (
    <>
      <PageHead
        backTo={Routes.AdminStationsTab}
        backToLabel="Admin"
        actions={[
          {
            label: (
              <>
                <i className="las la-trash" /> Ta bort station
              </>
            ),
            onClick: handleDeleteClick,
          },
        ]}
      >
        Redigerar station
      </PageHead>

      <ManageRequestStatus
        status={[stationResponse.status, deleteStationCall.status]}
      >
        {stationResponse.response && (
          <Sections>
            <EditStationFields
              station={stationResponse.response}
              onStationUpdated={stationResponse.update}
            />
            <EditStationTowers
              station={stationResponse.response}
              onStationUpdated={stationResponse.update}
            />
          </Sections>
        )}
      </ManageRequestStatus>
    </>
  );
};

export default StationAdminPage;
