import React, { useState } from 'react';
import { TableActions, TableActionsTitle } from './components';
import Table, { Props as TableProps } from './SortedTable';
import styled, { css } from 'styled-components';
import { CellRendererProps } from '.';
import { Colors } from '../../utils/style';
import Button from '../primitives/Button';
import { Select } from 'components/form';

export const RemoveButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${Colors.Red};
  font-size: 25px;
`;

export interface Props<Item extends Object, AddableValue>
  extends TableProps<Item> {
  addableItems: { name: string; value: AddableValue }[];
  onAddItem?(value: AddableValue): void;
  onRemoveItem?(item: Item): void;
  rows: Item[];

  title?: string;
  addButtonText: string;
  selectPlaceholder?: string;
}

const EditableList = <Item extends Object, AddableValue>({
  addableItems,
  onAddItem,
  onRemoveItem,
  title,
  addButtonText,
  selectPlaceholder,
  columnSettings,
  ...restProps
}: Props<Item, AddableValue>) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(-1);

  const handleAddItemClick = () => {
    if (!onAddItem) return;

    if (selectedItemIndex >= 0) {
      onAddItem(addableItems[selectedItemIndex].value);
    }
    setSelectedItemIndex(-1);
  };

  const handleDeleteItemClick = (props: CellRendererProps<Item>) => {
    if (!onRemoveItem) return;

    onRemoveItem(props.row);
  };

  return (
    <>
      <TableActions>
        <TableActionsTitle>{title}</TableActionsTitle>

        {onAddItem && (
          <>
            <Select
              value={selectedItemIndex}
              onChange={(eve: React.ChangeEvent<HTMLSelectElement>) =>
                setSelectedItemIndex(Number(eve.target.value))
              }
            >
              <option disabled value={-1}>
                {selectPlaceholder ?? 'Välj ett värde'}
              </option>

              {addableItems.map((item, index) => (
                <option key={index} value={index}>
                  {item.name}
                </option>
              ))}
            </Select>

            <Button
              onClick={handleAddItemClick}
              disabled={selectedItemIndex === -1}
              small
            >
              <i className="las la-plus"></i>
              {addButtonText}
            </Button>
          </>
        )}
      </TableActions>

      <Table
        columnSettings={
          onRemoveItem
            ? [
                ...columnSettings,
                {
                  header: 'Ta bort',
                  width: '100px',
                  notSortable: true,
                  cellRenderer: (props) => (
                    <RemoveButton onClick={() => handleDeleteItemClick(props)}>
                      <i className="las la-trash"></i>
                    </RemoveButton>
                  ),
                  cellStyle: css`
                    padding-bottom: 0;
                    padding-top: 0;
                  `,
                },
              ]
            : columnSettings
        }
        {...restProps}
      />
    </>
  );
};

export default EditableList;
