export const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const isValidPhoneNr = (phone: string) => {
  return /^\+?[0-9]+$/.test(phone.replaceAll('-', '').replaceAll(' ', ''));
};

export const isValidSweRegnr = (regnr: string) =>
  regnr.length >= 2 && regnr.length <= 7 && /^[A-Z0-9ÅÄÖ ]+$/.test(regnr);

export const enforceValidRegnr = (regnr: string) => regnr.toUpperCase();
