import { StagingLotWeekDay } from 'api';
import { parseTimeString } from 'utils/dateTime';

export const shortDayLabelsByWeekDay: Record<StagingLotWeekDay, string> = {
  [StagingLotWeekDay.M]: 'M',
  [StagingLotWeekDay.Ti]: 'Ti',
  [StagingLotWeekDay.O]: 'O',
  [StagingLotWeekDay.To]: 'To',
  [StagingLotWeekDay.F]: 'F',
  [StagingLotWeekDay.L]: 'L',
  [StagingLotWeekDay.S]: 'S',
};

const dayOrder: Record<StagingLotWeekDay, number> = {
  [StagingLotWeekDay.M]: 1,
  [StagingLotWeekDay.Ti]: 2,
  [StagingLotWeekDay.O]: 3,
  [StagingLotWeekDay.To]: 4,
  [StagingLotWeekDay.F]: 5,
  [StagingLotWeekDay.L]: 6,
  [StagingLotWeekDay.S]: 7,
};

export const sortDaysList = (days: StagingLotWeekDay[]) => {
  const sortedDays = [...days];
  sortedDays.sort((a, b) => dayOrder[a] - dayOrder[b]);
  return sortedDays;
};

/**
 * @param timeString time string in format HH:MM:SS
 * @returns time string in format HH:MM
 */
export const toHHMM = (timeString: string) => {
  const { hours, minutes } = parseTimeString(timeString);
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0'
  )}`;
};
