import Arrow from 'components/Slider/Arrow';
import Spinner from 'components/Spinner';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import PinchZoom, {
  hasTranslate3DSupport,
  make2dTransformValue,
  make3dTransformValue,
} from 'react-quick-pinch-zoom';
import { UpdateAction } from 'react-quick-pinch-zoom/esm/PinchZoom/types';
import styled, { keyframes } from 'styled-components';
import { Colors, FontSizes } from 'utils/style';
import useModalStack from './useModalStack';

const fadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 98;
  background-color: ${Colors.SemiBlack};
  backdrop-filter: blur(3px);
  animation: ${fadeIn} 0.2s;
`;

const ModalClose = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  z-index: 1;

  border: 1px solid ${Colors.Gray};
  border: 0;
  border-radius: 1000px;
  background: ${Colors.SemiBlack};
  color: ${Colors.White};
  font-size: ${FontSizes.Huge}px;
`;

const Img = styled.img``;

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const use3DTransform = hasTranslate3DSupport() && !isSafari;

const makeTransformValue = use3DTransform
  ? make3dTransformValue
  : make2dTransformValue;

interface Props {
  imageUrls: string[];
  startingIndex: number;
}

const FullImageViewerModal: FC<Props> = ({ imageUrls, startingIndex }) => {
  const [imageIndex, _setImageIndex] = useState(-1);
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const currentLoadingImage = useRef(startingIndex);

  useEffect(() => {
    // To get the loading spinner when mounting
    // +1 because changeImageIndex changes relative to the current index. The current index is -1 on mount.
    changeImageIndex(startingIndex + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const imgRef = useRef<HTMLImageElement | null>(null);
  const { pop: popModal } = useModalStack();

  const changeImageIndex = useCallback(
    (indexDiff: number) => {
      _setImageIndex((currentIndex) => {
        let index = currentIndex + indexDiff;
        index = (index + imageUrls.length) % imageUrls.length;

        const img = new Image();
        currentLoadingImage.current = index;
        setImageIsLoading(true);
        imgRef.current = null;
        img.onload = () => {
          if (currentLoadingImage.current === index) setImageIsLoading(false);
        };
        img.src = imageUrls[index];

        return index;
      });
    },
    [imageUrls]
  );

  const handleClose = useCallback(
    (eve: React.MouseEvent) => {
      eve.stopPropagation();
      popModal();
    },
    [popModal]
  );

  const handleZoom = useCallback((action: UpdateAction) => {
    imgRef.current?.style.setProperty('transform', makeTransformValue(action));
  }, []);

  return (
    <Wrapper onClick={handleClose}>
      <ModalClose type="button" onClick={handleClose}>
        <i className="las la-times" />
      </ModalClose>
      {imageUrls.length > 1 && (
        <>
          <Arrow
            direction="left"
            handleClick={(eve) => {
              eve.stopPropagation();
              changeImageIndex(-1);
            }}
          />
          <Arrow
            direction="right"
            handleClick={(eve) => {
              eve.stopPropagation();
              changeImageIndex(1);
            }}
          />
        </>
      )}

      {imageIsLoading ? (
        <Spinner light />
      ) : (
        <PinchZoom
          onUpdate={handleZoom}
          minZoom={0.5}
          maxZoom={10}
          wheelScaleFactor={200}
          shouldInterceptWheel={() => false}
          zoomOutFactor={1.1}
        >
          <Img
            src={imageUrls[imageIndex]}
            ref={imgRef}
            onClick={(eve) => {
              eve.stopPropagation();
            }}
          />
        </PinchZoom>
      )}
    </Wrapper>
  );
};

export default FullImageViewerModal;
