import React from 'react';
import styled from 'styled-components';

import { FontSizes, Sizes, Colors } from '../../utils/style';

interface Props {
  label: string;
  value: string;
  onChange?: (value: string) => void;
  iconName?: string;
  locked?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: ${FontSizes.Large}px;
  background-color: ${Colors.White};

  border: 1px solid ${Colors.Gray};
`;

const Label = styled.div<{ locked: boolean }>`
  font-weight: bold;
  margin-left: ${Sizes.Gutter / 2}px;
  color: ${({ locked }) => (locked ? Colors.DarkGray : Colors.Black)};
  font-style: ${({ locked }) => (locked ? 'italic' : 'normal')};
`;

const Input = styled.input<{ $locked: boolean }>`
  &,
  &:disabled {
    border: none;
    flex: 1;
    width: 0;
    padding: ${Sizes.Gutter / 3}px;
    background-color: ${Colors.White};
    color: ${({ $locked: locked }) =>
      locked ? Colors.DarkGray : Colors.Black};
    -webkit-text-fill-color: ${({ $locked: locked }) =>
      locked
        ? Colors.DarkGray
        : Colors.Black}; /* Override iOS / Android font color change */
    opacity: 1;
    font-style: ${({ $locked: locked }) => (locked ? 'italic' : 'normal')};
  }

  &:focus {
    outline: none;
  }
`;

const Icon = styled.i`
  padding: ${Sizes.Gutter / 2}px;
  cursor: pointer;
`;

const IconLabelInput: React.FC<Props> = ({
  label,
  value,
  onChange,
  iconName,
  locked = false,
}) => {
  return (
    <Wrapper>
      <Label locked={locked}>{label}</Label>
      <Input
        $locked={locked}
        disabled={locked}
        placeholder=""
        value={value}
        onChange={(eve) => onChange && onChange(eve.target.value)}
      />
      {iconName && <Icon className={iconName} />}
    </Wrapper>
  );
};

export default IconLabelInput;
