import React, { FC } from 'react';
import { InventoryModel } from 'api';
import styled from 'styled-components';
import Slider from 'components/Slider';
import useModalStack from 'components/Modal/useModalStack';
import FullImageViewerModal from 'components/Modal/FullImageViewerModal';
import { DeviceMinWidths, MediaQuery, Sizes } from 'utils/style';
import { SectionTitle } from '../components';
import useWindowDimensionIsBetween from 'hooks/useWindowDimensionIsBetween';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SliderWrapper = styled.div`
  height: calc(70vw + 37px);
  width: calc(100% - ${Sizes.Gutter}px);
  margin-left: ${Sizes.Gutter / 2}px;

  ${MediaQuery.tablet} {
    height: calc(14vw + 37px);
    width: calc(100% - ${Sizes.Gutter * 2}px);
    margin-left: ${Sizes.Gutter}px;
  }
`;

interface Props {
  inventory: InventoryModel;
}

const InventoryImagesSection: FC<Props> = ({ inventory }) => {
  const modalStack = useModalStack();
  const isTablet = useWindowDimensionIsBetween(DeviceMinWidths.tablet);

  const thumbnailUrls =
    inventory.images?.map((i) => i.thumbnailUrl ?? '') ?? [];

  if (thumbnailUrls.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <SectionTitle>Bilder</SectionTitle>

      <SliderWrapper>
        <Slider
          slides={thumbnailUrls}
          slideSubtitle="Klicka på en bild för att förstora den"
          imageWidth={640}
          imageHeight={480}
          imageCount={isTablet ? 3 : 1}
          imagesCover
          imageSeparation={5}
          onSlideClick={(index) =>
            modalStack.push(
              <FullImageViewerModal
                imageUrls={inventory.images?.map((i) => i.imageUrl ?? '') ?? []}
                startingIndex={index}
              />
            )
          }
        />
      </SliderWrapper>
    </Wrapper>
  );
};

export default InventoryImagesSection;
