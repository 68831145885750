import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  Line,
  XAxis,
  YAxis,
} from 'recharts';
import { getMonthTowingsStatisticsData } from './util';
import { TowingMonthSummary } from 'api';
import { Colors, MediaQuery } from 'utils/style';
import styled from 'styled-components';

const yAxisWidth = 40;

const LetAxisesOverflow = styled.div`
  ${MediaQuery.tablet} {
    margin-left: ${-yAxisWidth}px;
    width: calc(100% + ${yAxisWidth}px);
  }
`;

interface Props {
  monthReports: TowingMonthSummary[];
}

const MonthTowingCountChart: React.FC<Props> = ({ monthReports }) => (
  <LetAxisesOverflow>
    <ResponsiveContainer
      width="100%"
      height="95%"
      minHeight={300}
      minWidth={100}
      maxHeight={400}
    >
      <LineChart
        margin={{ bottom: 40, left: 0 }}
        data={getMonthTowingsStatisticsData(monthReports)}
      >
        <CartesianGrid stroke={Colors.Gray} />
        <Line
          stroke={Colors.Red}
          strokeWidth={3}
          type="linear"
          dataKey="count"
          dot={{ strokeWidth: 0, r: 4, fill: Colors.Red }}
        />
        <YAxis width={yAxisWidth} />
        <XAxis
          dataKey="label"
          tick={({ x, y, payload }) => {
            const split = payload.value.split('\n');

            return (
              <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                  {split[0]}
                </text>
                {split[1] && (
                  <text x={0} y={16} dy={16} textAnchor="middle" fill="#666">
                    {split[1]}
                  </text>
                )}
              </g>
            );
          }}
        />
        <YAxis />
      </LineChart>
    </ResponsiveContainer>
  </LetAxisesOverflow>
);

export default MonthTowingCountChart;
