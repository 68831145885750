import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { Colors, FontSizes, MediaQuery, Sizes } from '../utils/style';
import { Case } from '../api';
import { toLocalDateTimeString } from '../utils/dateTime';
import CaseDetailModal from './CaseDetail';
import {
  Section,
  SectionLabel,
  RowWrapper,
  MobileSectionHeading,
} from './SectionedList';
import useModalStack from './Modal/useModalStack';
import FullImageViewerModal from './Modal/FullImageViewerModal';

const Row = styled(RowWrapper)`
  cursor: pointer;
  padding-right: ${Sizes.Gutter / 2}px;
  flex-direction: row;
  flex-wrap: wrap;

  & > * {
    min-width: calc(50% - ${Sizes.Gutter / 4}px);
    margin-right: ${Sizes.Gutter / 4}px;
  }

  * {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: ${Colors.ItemHover};
  }

  ${MediaQuery.tablet} {
    & > * {
      min-width: unset;
      margin-right: ${Sizes.Gutter}px;
    }
  }
`;

const ImageSection = styled(Section)<{ src?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;

  ${({ src }) =>
    src
      ? css`
          padding-top: ${(110 / 165) * 100}%;
          background: url(${src}) center/cover no-repeat;
          background-origin: padding-box;
        `
      : css`
          display: none;
        `}

  ${MediaQuery.tablet} {
    display: flex;
    width: 165px;
    height: 110px;
    min-width: 165px;
    max-width: 165px;
    max-height: 110px;
    padding-top: 0;
    background-origin: content-box;
    background-clip: content-box;
  }
`;

const Icon = styled.i`
  font-size: ${FontSizes.Mega}px;
  color: ${Colors.Gray};
`;

interface Props {
  repairShopCase: Case;
}

const CaseComponent = (props: Props) => {
  const { repairShopCase } = props;
  const { push: pushModal } = useModalStack();

  const showDetails = useCallback(() => {
    pushModal(<CaseDetailModal repairShopCase={repairShopCase} />);
  }, [pushModal, repairShopCase]);

  const showImages = useCallback(
    (eve: React.MouseEvent) => {
      if (
        repairShopCase.incident?.images &&
        repairShopCase.incident?.images?.length > 0
      ) {
        eve.stopPropagation();

        pushModal(
          <FullImageViewerModal
            imageUrls={repairShopCase.incident.images}
            startingIndex={0}
          />
        );
      }
    },

    [pushModal, repairShopCase.incident?.images]
  );

  const coverImage =
    (repairShopCase.incident &&
      repairShopCase.incident.thumbnailImages &&
      repairShopCase.incident.thumbnailImages[0]) ||
    '';

  return (
    <Row onClick={showDetails}>
      <ImageSection src={coverImage} flex={10} onClick={showImages}>
        {!coverImage && <Icon className="las la-image" />}
      </ImageSection>

      <Section flex={10}>
        <MobileSectionHeading>Status</MobileSectionHeading>

        {repairShopCase.leftIncidentTimeStamp && (
          <>
            <SectionLabel>Hämtat fordon</SectionLabel>
            {toLocalDateTimeString(repairShopCase.leftIncidentTimeStamp)}
          </>
        )}
        {repairShopCase.leftVehicleTimeStamp && (
          <>
            <SectionLabel>Lämnat fordon</SectionLabel>
            {toLocalDateTimeString(repairShopCase.leftVehicleTimeStamp)}
          </>
        )}
      </Section>

      <Section flex={15}>
        <MobileSectionHeading>Skadeinformation</MobileSectionHeading>

        <SectionLabel>
          {repairShopCase.incident && repairShopCase.incident.type}
        </SectionLabel>
        <i>
          {repairShopCase.incident &&
            toLocalDateTimeString(repairShopCase.incident.date)}{' '}
        </i>
        <div>
          {repairShopCase.incident && repairShopCase.incident.description}
        </div>
      </Section>

      <Section flex={15}>
        <MobileSectionHeading>Fordonsinformation</MobileSectionHeading>

        <SectionLabel>
          {repairShopCase.wreck && repairShopCase.wreck.registrationNumber}
        </SectionLabel>
        <div>{repairShopCase.wreck && repairShopCase.wreck.model}</div>
        <div>
          {repairShopCase.wreck && repairShopCase.wreck.insuranceInformation}
        </div>
      </Section>

      <Section flex={10}>
        <MobileSectionHeading>Bärgare</MobileSectionHeading>

        <SectionLabel>
          {repairShopCase.tower && repairShopCase.tower.name}
        </SectionLabel>
        <div>{repairShopCase.tower && repairShopCase.tower.phoneNumber}</div>
      </Section>

      <Section flex={10}>
        <MobileSectionHeading>Kund</MobileSectionHeading>

        <SectionLabel>
          {repairShopCase.customer && repairShopCase.customer.name}
        </SectionLabel>
        <div>
          {repairShopCase.customer && repairShopCase.customer.phoneNumber}
        </div>
      </Section>
    </Row>
  );
};

export default CaseComponent;
