import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DepartmentType,
  InventoryClient,
  InventoryModel,
  InventoryStatus,
  StagingLotClient,
} from 'api';
import { Icons } from 'assets';
import KeyValueList from 'components/KeyValuesList';
import Button from 'components/primitives/Button';
import { inventoryIdQueryParam } from 'containers/OrderPage/OrderUserFlow';
import useAppTexts from 'hooks/useAppTexts';
import React, { FC } from 'react';
import { UseFormReturn, Controller } from 'react-hook-form';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Routes from 'utils/routes';
import { Sizes } from 'utils/style';
import {
  ActionsListSection,
  FullWidthSelect,
  SectionTitle,
} from '../components';
import ValidationMessage from '../../../../components/form/ValidationMessage';
import { InventoryFormValues } from '../utils/inventoryFormConverters';
import ToRepairshopSubsection from './ToRepairshopSubsection';
import ToStagingLotSubsection from './ToStagingLotSubsection';
import useModalStack from 'components/Modal/useModalStack';
import OwnTransferModal from '../components/OwnTransferModal';
import RegNrInput from 'components/form/RegNrInput';
import useResponse from 'api/hooks/useResponse';
import StagingLotTimeSlotDatePicker from 'components/StagingLotTimeSlotDatePicker';
import { toLocalDateTimeString } from 'utils/dateTime';
import useApiCall from 'api/hooks/useApiCall';
import ButtonsModal from 'components/Modal/ButtonsModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Sizes.Gutter / 2}px 0;
`;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.Gutter}px;
`;

const TowIcon = styled(Icons.TowTruck)`
  width: 1.5em;
  height: 1.5em;
`;

const InspectionText = styled.span`
  margin-right: 5px;
`;

interface Props {
  inventory: InventoryModel;
  form: UseFormReturn<InventoryFormValues>;
  onOwnTransferBooked: () => void;
  onTransferDeleted: () => void;
}

const InventoryInfoSection: FC<Props> = ({
  inventory,
  form,
  onOwnTransferBooked,
  onTransferDeleted,
}) => {
  const history = useHistory();
  const modalStack = useModalStack();
  const { departmentTypes, inventoryStatuses, inventorySources } =
    useAppTexts();

  const stagingLotResponse = useResponse(
    new StagingLotClient(),
    (c) => c.get(inventory.stagingLotId),
    [inventory.stagingLotId]
  );

  const hasRepairshopTransfer = !!(
    inventory.transferToRepairShop?.pickUpType ||
    inventory.transferToRepairShop?.plannedDelivery ||
    inventory.transferToRepairShop?.actualPickup
  );

  const statusOptions = [
    { status: InventoryStatus.New },
    inventory.status === InventoryStatus.AssistOrdered
      ? { status: InventoryStatus.AssistOrdered, disabled: true }
      : { status: InventoryStatus.DigitalInOrdered, disabled: true },
    { status: InventoryStatus.InTransitToStagingLot, disabled: true },
    { status: InventoryStatus.StandBy },
    { status: InventoryStatus.InTransitToRepairShop },
    { status: InventoryStatus.Closed },
  ];

  const deleteTransferCall = useApiCall(
    new InventoryClient(),
    (c, inventoryId: number, transferId: number) =>
      c.deleteTransfer(inventoryId, transferId)
  );

  const handleOnDeleteTransfer = async (transferId: number) => {
    modalStack.push(
      <ButtonsModal
        title="Ta bort bokning?"
        onClose={() => modalStack.pop()}
        buttons={[
          {
            label: 'Ta bort bokning',
            onClick: async () => {
              await deleteTransferCall.run(inventory.id, transferId);
              onTransferDeleted();
            },
          },
          { label: 'Avbryt' },
        ]}
      >
        Är du säker på att du vill ta bort den här bokningen?
      </ButtonsModal>
    );
  };

  if (inventory.status === InventoryStatus.Removed) {
    statusOptions.push({ status: InventoryStatus.Removed, disabled: true });
  }

  const caseDetails = inventory.serviceCaseDetails?.[0];
  const incidentDate = inventory.serviceCaseDetails?.sort(
    (a, b) => a.incidentDate.getTime() - b.incidentDate.getTime()
  )[0]?.incidentDate;

  return (
    <Wrapper>
      <SectionTitle>Information</SectionTitle>

      <Sections>
        <KeyValueList
          useEmptyPlaceholder
          tabletInset={Sizes.Gutter}
          mobileInset={Sizes.Gutter / 2}
          items={[
            {
              key: 'Regnr',
              value: (
                <ValidationMessage
                  control={form.control}
                  name="registrationNumber"
                >
                  <Controller
                    control={form.control}
                    name="registrationNumber"
                    render={({ field }) => (
                      <RegNrInput
                        value={field.value}
                        onChange={field.onChange}
                        ref={field.ref}
                        small={true}
                      />
                    )}
                    rules={{
                      required: 'Ange ett registreringsnummer',
                    }}
                  />
                </ValidationMessage>
              ),
            },
            {
              key: 'Status',
              value: (
                <FullWidthSelect
                  small
                  {...form.register('status', { valueAsNumber: true })}
                >
                  {statusOptions.map((option) => (
                    <option
                      key={option.status}
                      value={option.status}
                      disabled={option.disabled}
                    >
                      {inventoryStatuses[option.status]}
                    </option>
                  ))}
                </FullWidthSelect>
              ),
            },
            {
              key: 'Verkstadstyp',
              value: (
                <FullWidthSelect
                  small
                  {...form.register('departmentType', { valueAsNumber: true })}
                >
                  <option key={'none'} value={0}>
                    {' '}
                  </option>
                  {Object.values(DepartmentType)
                    .filter((v): v is DepartmentType => typeof v === 'number')
                    .map((v) => (
                      <option key={v} value={v}>
                        {departmentTypes[v]}
                      </option>
                    ))}
                </FullWidthSelect>
              ),
            },
            {
              key: 'Källa',
              value: inventorySources[inventory.source],
            },
            {
              key: 'Uppställningsplats',
              value: inventory.stagingLotName,
            },
            {
              key: (
                <>
                  <InspectionText>Boka besiktning</InspectionText>
                  <FontAwesomeIcon icon={faClipboard} />
                </>
              ),
              value: (
                <Controller
                  control={form.control}
                  name="inspection"
                  render={({ field }) => (
                    <StagingLotTimeSlotDatePicker
                      optional
                      small
                      stagingLotResponse={stagingLotResponse}
                      onChange={(date) => field.onChange(date)}
                      value={field.value}
                      ref={field.ref}
                    />
                  )}
                />
              ),
            },
          ]}
        />

        {caseDetails && (
          <KeyValueList
            useEmptyPlaceholder
            tabletInset={Sizes.Gutter}
            mobileInset={Sizes.Gutter / 2}
            items={[
              {
                key: 'Kund namn',
                value: caseDetails.customerName,
              },
              {
                key: 'Kund telefon',
                value: caseDetails.customerPhoneNumber,
              },
              {
                key: 'Skadetyp',
                value: caseDetails.incidentType,
              },
              {
                key: 'Skadedatum',
                value: toLocalDateTimeString(incidentDate),
              },
              {
                key: 'Skadebeskrivning',
                value: caseDetails.incidentDescription,
              },
            ]}
          />
        )}

        <ToStagingLotSubsection
          inventory={inventory}
          form={form}
          stagingLotResponse={stagingLotResponse}
          onDeleteTransfer={handleOnDeleteTransfer}
        />

        {hasRepairshopTransfer && (
          <ToRepairshopSubsection
            inventory={inventory}
            form={form}
            stagingLotResponse={stagingLotResponse}
            onDeleteTransfer={handleOnDeleteTransfer}
          />
        )}

        <ActionsListSection>
          {!hasRepairshopTransfer && (
            <>
              <Button
                variant="textbutton"
                onClick={() => {
                  history.push(
                    `${Routes.Order}?${inventoryIdQueryParam}=${inventory.id}`
                  );
                }}
              >
                <TowIcon /> Boka flytt med Assistancekåren
              </Button>
              <Button
                variant="textbutton"
                onClick={() =>
                  modalStack.push(
                    <OwnTransferModal
                      onOwnTransferBooked={() => {
                        onOwnTransferBooked();
                        modalStack.pop();
                      }}
                      inventory={inventory}
                      onClose={() => modalStack.pop()}
                    />
                  )
                }
              >
                <TowIcon />
                Boka egen flytt
              </Button>
            </>
          )}
        </ActionsListSection>
      </Sections>
    </Wrapper>
  );
};

export default InventoryInfoSection;
