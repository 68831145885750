import React, { FC, PropsWithChildren } from 'react';
import NavMenu from './NavMenu';
import styled, { createGlobalStyle } from 'styled-components';

/**
 * Global styling that prevents the body from scrolling. Useful if you want your element to be able to flex and have its own internal scroll.
 */
export const PreventLayoutOverflow = createGlobalStyle`
  html, body, #root, #app-content-wrapper {
    overflow: hidden;
    height: 100%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Layout: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <>
      <NavMenu />
      <ContentWrapper id="app-content-wrapper">{children}</ContentWrapper>
    </>
  );
};
