import React, { FC, useContext } from 'react';
import { AppTextClient, IAppTexts } from 'api';
import useResponse from 'api/hooks/useResponse';
import ManageRequestStatus from 'components/ManageRequestStatus';

interface AppTextsContextValue extends IAppTexts {}

const AppTextsContext = React.createContext<AppTextsContextValue | undefined>(
  undefined
);

export const AppTextsProvider: FC = ({ children }) => {
  const appTextsResponse = useResponse(new AppTextClient(), (client) =>
    client.getAppTexts()
  );

  return (
    <ManageRequestStatus status={[appTextsResponse.status]}>
      {appTextsResponse.response && (
        <AppTextsContext.Provider value={appTextsResponse.response}>
          {children}
        </AppTextsContext.Provider>
      )}
    </ManageRequestStatus>
  );
};

const useAppTexts = () => {
  const appTexts = useContext(AppTextsContext);

  if (!appTexts) {
    throw new Error('AppTextsProvider is not set');
  }

  return appTexts;
};

export default useAppTexts;
