import {
  DepartmentType,
  InventoryModel,
  InventoryStatus,
  UpdateInventoryRequest,
} from 'api';

export interface InventoryFormValues {
  registrationNumber: string;
  departmentType?: DepartmentType;
  status: InventoryStatus;

  plannedDeliveryToStagingLot: Date | null;
  actualPickupToStagingLot: Date | null;
  actualDeliveryToStagingLot: Date | null;
  plannedDeliveryToRepairShop: Date | null;
  actualPickupToRepairShop: Date | null;
  actualDeliveryToRepairShop: Date | null;
  inspection: Date | null;
}

export const defaultInventoryFormValues: Readonly<InventoryFormValues> = {
  registrationNumber: '',
  status: InventoryStatus.New,

  plannedDeliveryToStagingLot: null,
  actualPickupToStagingLot: null,
  actualDeliveryToStagingLot: null,
  plannedDeliveryToRepairShop: null,
  actualPickupToRepairShop: null,
  actualDeliveryToRepairShop: null,
  inspection: null,
};

export function InventoryModelToFormValues(
  model: InventoryModel
): InventoryFormValues {
  return {
    registrationNumber: model.registrationNumber ?? '',
    departmentType: model.departmentType,
    status: model.status,

    plannedDeliveryToStagingLot:
      model.transferToStagingLot?.plannedDelivery ?? null,
    actualPickupToStagingLot: model.transferToStagingLot?.actualPickup ?? null,
    actualDeliveryToStagingLot:
      model.transferToStagingLot?.actualDelivery ?? null,
    plannedDeliveryToRepairShop:
      model.transferToRepairShop?.plannedDelivery ?? null,
    actualPickupToRepairShop: model.transferToRepairShop?.actualPickup ?? null,
    actualDeliveryToRepairShop:
      model.transferToRepairShop?.actualDelivery ?? null,
    inspection: model.inspection ?? null,
  };
}

export function InventoryFormValuesToUpdateInventoryRequest(
  formValues: InventoryFormValues
): UpdateInventoryRequest {
  return new UpdateInventoryRequest({
    registrationNumber: formValues.registrationNumber,
    departmentType: !!formValues.departmentType
      ? formValues.departmentType
      : undefined,
    status: formValues.status,
    plannedDeliveryToStagingLot:
      formValues.plannedDeliveryToStagingLot ?? undefined,
    actualPickupToStagingLot: formValues.actualPickupToStagingLot ?? undefined,
    actualDeliveryToStagingLot:
      formValues.actualDeliveryToStagingLot ?? undefined,
    plannedDeliveryToRepairShop:
      formValues.plannedDeliveryToRepairShop ?? undefined,
    actualPickupToRepairShop: formValues.actualPickupToRepairShop ?? undefined,
    actualDeliveryToRepairShop:
      formValues.actualDeliveryToRepairShop ?? undefined,
    inspection: formValues.inspection ?? undefined,
  });
}
