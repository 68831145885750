import {
  IStagingLotTimeSlotGroupModel,
  StagingLotTimeSlotGroupModel,
  StagingLotWeekDay,
} from 'api';
import { toHHMMSSString, toInputTimeString } from 'utils/dateTime';

export interface TimeSlotFormValues {
  label: string;
  startTime: string;
  endTime: string;
  stagingLotId: number;

  days: StagingLotWeekDay[];
}

export const defaultTimeSlotFormValues: Readonly<TimeSlotFormValues> = {
  label: '',
  startTime: '00:00',
  endTime: '00:00',
  stagingLotId: -1,

  days: [],
};

export const timeSlotModelToFormValues = (
  timeSlot: IStagingLotTimeSlotGroupModel,
  stagingLotId: number
): TimeSlotFormValues => {
  return {
    label: timeSlot.label ?? '',
    startTime: toInputTimeString(timeSlot.startTime),
    endTime: toInputTimeString(timeSlot.endTime),
    stagingLotId,

    days: timeSlot.days ?? [],
  };
};

export const timeSlotFormValuesToModel = (
  formValues: TimeSlotFormValues,
  base?: IStagingLotTimeSlotGroupModel
): StagingLotTimeSlotGroupModel => {
  return new StagingLotTimeSlotGroupModel({
    id: base?.id ?? 0,
    label: formValues.label,
    startTime: toHHMMSSString(formValues.startTime),
    endTime: toHHMMSSString(formValues.endTime),

    days: formValues.days,
  });
};
