import React, { FunctionComponent, useState } from 'react';
import ButtonsModal from 'components/Modal/ButtonsModal';
import styled from 'styled-components';
import { Sizes } from 'utils/style';
import { Input } from 'components/form';

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${Sizes.Gutter / 2}px;
  width: 100%;
`;

interface Props {
  onAddStation(name: string, zipCode: string, postAddress: string): void;
}

const AddStationModal: FunctionComponent<Props> = ({ onAddStation }) => {
  const [name, setName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [postAddress, setPostAddress] = useState('');

  return (
    <ButtonsModal
      title="Lägg till station"
      buttons={[
        {
          label: 'Lägg till',
          onClick: () => onAddStation(name, zipCode, postAddress),
        },
        {
          label: 'Avbryt',
        },
      ]}
    >
      <InputWrap>
        <b>Namn</b>
        <Input value={name} onChange={(eve) => setName(eve.target.value)} />
      </InputWrap>

      <InputWrap>
        <b>Adress</b>
        <Input
          value={postAddress}
          onChange={(eve) => setPostAddress(eve.target.value)}
        />
      </InputWrap>

      <InputWrap>
        <b>Postkod</b>
        <Input
          value={zipCode}
          onChange={(eve) => setZipCode(eve.target.value)}
        />
      </InputWrap>
    </ButtonsModal>
  );
};

export default AddStationModal;
