import React, { forwardRef, useEffect } from 'react';
import RegNrInput from 'components/form/RegNrInput';
import { InventoryClient, InventorySearchRequest } from 'api';
import useApiCall from 'api/hooks/useApiCall';
import styled from 'styled-components';
import { Colors, Sizes } from 'utils/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { ReactTable } from '@assistancekaren/react-components';
import useAppTexts from 'hooks/useAppTexts';

const ExistingInventroryNotice = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.Gutter / 2}px;
  padding: ${Sizes.Gutter / 4}px;
  margin-top: ${Sizes.Gutter / 2}px;
  font-weight: 500;
  background: #ffaa7733;
  border: 1px solid ${Colors.Warn};
`;

function normailzeRegNr(regnr: string | undefined) {
  return regnr?.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
}

interface Props {
  registrationNumber: string;
  onChange: (registrationNumber: string) => void;
  onOpenExistingInventory: (inventoryId: number) => void;
}

const UniqueRegNrInput = forwardRef<HTMLInputElement, Props>(
  ({ registrationNumber, onChange, onOpenExistingInventory }, ref) => {
    const { inventoryStatuses } = useAppTexts();

    const searchRegNr = useApiCall(
      new InventoryClient(),
      async (c, regnr: string) =>
        c.search(
          new InventorySearchRequest({
            registrationNumber: regnr,
            includeClosed: false,
          })
        )
    );

    const exactRegnrResults = searchRegNr.response?.filter(
      (r) =>
        normailzeRegNr(r.registrationNumber) ===
        normailzeRegNr(registrationNumber)
    );

    useEffect(() => {
      const timeout = setTimeout(() => {
        // minimum length for custom swedish registration number
        if (registrationNumber.length >= 2) searchRegNr.run(registrationNumber);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }, [registrationNumber]);

    return (
      <>
        <RegNrInput value={registrationNumber} onChange={onChange} ref={ref} />

        {exactRegnrResults?.length ? (
          <ExistingInventroryNotice>
            <span>
              <FontAwesomeIcon icon={faWarning} /> Det finns redan{' '}
              {exactRegnrResults.length > 1
                ? `${exactRegnrResults.length} uppställningar`
                : `en uppställning`}{' '}
              med detta registreringsnummer.
            </span>

            <ReactTable
              columns={[
                {
                  header: 'Regnr.',
                  accessorKey: 'registrationNumber',
                },
                {
                  header: 'Status',
                  accessorFn: (row) =>
                    inventoryStatuses[row.status as number] ?? '',
                },
              ]}
              onRowClick={(row) => {
                onOpenExistingInventory(row.id);
              }}
              data={exactRegnrResults}
            />
          </ExistingInventroryNotice>
        ) : null}
      </>
    );
  }
);

export default UniqueRegNrInput;
