import React, { useEffect, useState } from 'react';

import { FontSizes, Colors } from '../utils/style';
import styled, { css } from 'styled-components';
import Popover from './Popover';

const Wrapper = styled.div`
  position: relative;
  border: 1px solid ${Colors.Gray};
  width: 220px;
  height: 40px;

  display: flex;
  flex-direction: row;
`;

const Input = styled.input`
  font-size: ${FontSizes.Large}px;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 6px 26px 6px 8px;
`;

const SearchButton = styled.button<{ showFilter?: boolean }>`
  border: none;
  background: transparent;
  color: ${Colors.Black};

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  width: 26px;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 3px;

  ${(props) =>
    props.showFilter &&
    css`
      right: 22px;
    `}
`;

const FilterButton = styled(SearchButton)<{ filterActive?: boolean }>`
  right: 0;
  cursor: pointer;

  ${({ filterActive }) =>
    filterActive &&
    css`
      &:after {
        position: absolute;
        top: 8px;
        right: 3px;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 1000px;
        background-color: ${Colors.Red};
      }
    `}
`;

const Icon = styled.i`
  font-size: ${FontSizes.Larger}px;
`;

interface Props {
  onChange: (term: string) => void;
  className?: string;
  placeholder?: string;
  showFilter?: boolean;
  filterActive?: boolean;
  filterContent?: React.ReactNode;
}

const SearchBar = ({
  onChange,
  className,
  placeholder,
  showFilter,
  filterActive,
  filterContent,
}: Props) => {
  const [value, setValue] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterActive]);

  const handleTermChange = (term: string) => {
    setValue(term);
    onChange(term);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleTermChange(event.target.value);
  };

  const handleIconClick = () => {
    if (value !== '') {
      handleTermChange('');
    }
  };

  return (
    <Wrapper className={className}>
      <Input
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={placeholder ?? 'Sök'}
      />
      <SearchButton
        showFilter={showFilter}
        style={{ cursor: value === '' ? 'auto' : 'pointer' }}
        onClick={handleIconClick}
      >
        {value === '' && <Icon className="las la-search"></Icon>}
        {value !== '' && <Icon className="las la-times"></Icon>}
      </SearchButton>
      {showFilter && (
        <Popover
          open={filterOpen}
          noPadding
          onPointerDownOutside={() => setFilterOpen(false)}
          content={<>{filterContent}</>}
        >
          <FilterButton
            filterActive={filterActive}
            onClick={() => setFilterOpen(true)}
          >
            <Icon className="las la-filter"></Icon>
          </FilterButton>
        </Popover>
      )}
    </Wrapper>
  );
};

export default SearchBar;
