import React, { FunctionComponent } from 'react';
import { RequestStatus } from 'api/hooks/useApiCall';
import Spinner from './Spinner';
import styled from 'styled-components';

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex: 1;
  width: 100%;
  height: 100%;
`;

interface Props {
  /** if any status !== Fetched, children won't render */
  status: RequestStatus[];
  loadingMessage?: React.ReactChild;
  errorMessage?: React.ReactChild;
  ignoreErrorStatus?: boolean;
}

const ManageRequestStatus: FunctionComponent<Props> = ({
  status: watchStatus,
  loadingMessage,
  errorMessage,
  ignoreErrorStatus,
  children,
}) => {
  if (
    !ignoreErrorStatus &&
    watchStatus.find((s) => s === RequestStatus.Error) !== undefined
  ) {
    return (
      <ErrorMessage>{errorMessage ?? 'Ett fel har inträffat.'}</ErrorMessage>
    );
  } else if (
    watchStatus.find((s) => s === RequestStatus.Fetching) !== undefined
  ) {
    return <Spinner>{loadingMessage || 'Laddar...'}</Spinner>;
  } else {
    return <>{children}</>;
  }
};

export default ManageRequestStatus;
