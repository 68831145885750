import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Sizes, MediaQuery } from 'utils/style';
import SortedTable from 'components/Table/SortedTable';
import { RemoveButton } from 'components/Table/EditableList';
import { TableActions, TableActionsTitle } from 'components/Table/components';
import { NotificationReceiverDto, NotificationReceiversClient } from 'api';
import AddNotificationReceiver from './AddNotificationReceiver';
import Toggle from 'components/form/Toggle';
import Spinner from 'components/Spinner';
import OverlaySpinner from 'components/Spinner/OverlaySpinner';
import useFetchErrorHandler from 'api/hooks/useFetchErrorHandler';
import { tableCellClass, tableHeadClass } from 'components/Table';
import useModalStack from 'components/Modal/useModalStack';
import ButtonsModal from 'components/Modal/ButtonsModal';
import TimeRangePicker from './TimeRangePicker';

const MySortedTable = styled(SortedTable)`
  & > .${tableCellClass}, & > .${tableHeadClass} {
    border-right-width: 0px;
    flex: none;
  }

  margin-bottom: ${Sizes.Gutter / 2}px;
` as typeof SortedTable;

const TableSpacer = styled.div`
  padding-top: ${Sizes.Gutter * 2}px;
`;

const Notifications = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: ${Sizes.Gutter}px;
  margin-bottom: ${Sizes.Gutter * 2}px;

  ${MediaQuery.desktop} {
    margin-right: ${Sizes.Gutter}px;
  }
`;

export enum NotificationType {
  Mail = 'Mail',
  SMS = 'SMS',
}

const NotificationReceiversEditor: React.SFC = () => {
  const modalStack = useModalStack();
  const [fetching, setFetching] = useState(false);
  const client = useRef(new NotificationReceiversClient()).current;

  const [handleFetchError] = useFetchErrorHandler();
  const [notificationReceivers, setNotificationReceivers] = useState<
    NotificationReceiverDto[]
  >([]);

  useEffect(() => {
    fetchNotificationReceivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const fetchNotificationReceivers = async () => {
    setFetching(true);
    try {
      setNotificationReceivers(await client.get());
    } catch (err) {
      handleFetchError(err);
    }
    setFetching(false);
  };

  const createNotificationReceiver = async (
    notificationReceiver: NotificationReceiverDto
  ) => {
    setFetching(true);
    try {
      const created = await client.create(notificationReceiver);
      setNotificationReceivers([...notificationReceivers, created]);
    } catch (err) {
      handleFetchError(err);
    }
    setFetching(false);
  };

  const updateNotificationReceiver = async (
    notificationReceiver: NotificationReceiverDto
  ) => {
    setFetching(true);
    try {
      await client.update(notificationReceiver.id, notificationReceiver);
    } catch (err) {
      handleFetchError(err);
    }
    setFetching(false);
    fetchNotificationReceivers();
  };

  const deleteNotificationReceiver = async (
    notificationReceiver: NotificationReceiverDto
  ) => {
    modalStack.push(
      <ButtonsModal
        title="Ta bort notifikation-mottagare"
        onClose={() => modalStack.pop()}
        buttons={[
          {
            label: 'Avbryt',
            onClick: () => modalStack.pop(),
          },
          {
            label: 'Ta bort',
            onClick: async () => {
              setFetching(true);
              try {
                await client.delete(notificationReceiver.id);
              } catch (err) {
                handleFetchError(err);
              }
              setFetching(false);
              fetchNotificationReceivers();
            },
          },
        ]}
      >
        <p>
          Vill du ta bort notifikation-mottagaren{' '}
          <strong>
            "{notificationReceiver.description}" ({notificationReceiver.value})
          </strong>
          ?
        </p>
      </ButtonsModal>
    );
  };

  const toggleActive = (notificationReceiver: NotificationReceiverDto) => {
    const updated = new NotificationReceiverDto(notificationReceiver);
    updated.active = !updated.active;
    updateNotificationReceiver(updated);
  };

  const updateNotificationReceiverTimeRange = (
    notificationReceiver: NotificationReceiverDto,
    activeFromMsOnUTCDay: number,
    activeToMsOnUTCDay: number
  ) => {
    const updated = new NotificationReceiverDto(notificationReceiver);
    updated.activeFromMsOnUTCDay = activeFromMsOnUTCDay;
    updated.activeToMsOnUTCDay = activeToMsOnUTCDay;
    updateNotificationReceiver(updated);
  };

  if (fetching && notificationReceivers.length === 0) {
    return <Spinner />;
  }

  return (
    <Notifications>
      {fetching && <OverlaySpinner />}
      <TableActions>
        <TableActionsTitle>Epost</TableActionsTitle>
      </TableActions>
      <MySortedTable
        rows={notificationReceivers.filter(
          (notiReceiver) => notiReceiver.type === NotificationType.Mail
        )}
        columnSettings={[
          {
            header: 'Namn',
            attribute: 'description',
          },
          {
            header: 'Epost',
            attribute: 'value',
          },
          {
            header: 'Aktiverad',
            notSortable: true,
            cellRenderer: ({ row }) => (
              <Toggle on={row.active} onClick={() => toggleActive(row)} />
            ),
            width: '90px',
            cellStyle: css`
              padding-top: 0;
              padding-bottom: 0;
              justify-content: flex-end;
              align-items: center;
            `,
          },
          {
            header: 'Ta bort',
            notSortable: true,
            cellRenderer: ({ row }) => (
              <RemoveButton onClick={() => deleteNotificationReceiver(row)}>
                <i className="las la-trash"></i>
              </RemoveButton>
            ),
            width: '70px',
            cellStyle: css`
              padding-top: 0;
              padding-bottom: 0;
              justify-content: flex-end;
            `,
          },
        ]}
      />
      <AddNotificationReceiver
        notificationType={NotificationType.Mail}
        onSave={createNotificationReceiver}
      />

      <TableSpacer />

      <TableActions>
        <TableActionsTitle>SMS</TableActionsTitle>
      </TableActions>
      <MySortedTable
        rows={notificationReceivers.filter(
          (notiReceiver) => notiReceiver.type === NotificationType.SMS
        )}
        columnSettings={[
          {
            header: 'Namn',
            attribute: 'description',
          },
          {
            header: 'Telefonnummer',
            attribute: 'value',
          },
          {
            header: 'Skickar mellan',
            notSortable: true,
            cellRenderer: ({ row }) =>
              row.active ? (
                <TimeRangePicker
                  activeFromMsOnUTCDay={row.activeFromMsOnUTCDay}
                  activeToMsOnUTCDay={row.activeToMsOnUTCDay}
                  onSaveClick={(activeFromMsOnUTCDay, activeToMsOnUTCDay) =>
                    updateNotificationReceiverTimeRange(
                      row,
                      activeFromMsOnUTCDay,
                      activeToMsOnUTCDay
                    )
                  }
                />
              ) : (
                <i>Deaktiverad</i>
              ),
            width: '150px',
            cellStyle: css`
              justify-content: flex-end;
            `,
          },
          {
            header: 'Aktiverad',
            notSortable: true,
            cellRenderer: ({ row }) => (
              <Toggle on={row.active} onClick={() => toggleActive(row)} />
            ),
            width: '95px',
            cellStyle: css`
              padding-top: 0;
              padding-bottom: 0;
              justify-content: flex-end;
              align-items: center;
            `,
          },
          {
            header: 'Ta bort',
            notSortable: true,
            cellRenderer: ({ row }) => (
              <RemoveButton onClick={() => deleteNotificationReceiver(row)}>
                <i className="las la-trash"></i>
              </RemoveButton>
            ),
            width: '70px',
            cellStyle: css`
              padding-top: 0;
              padding-bottom: 0;
              justify-content: flex-end;
            `,
          },
        ]}
      />
      <AddNotificationReceiver
        notificationType={NotificationType.SMS}
        onSave={createNotificationReceiver}
      />

      <TableSpacer />
    </Notifications>
  );
};

export default NotificationReceiversEditor;
