import { TowingMonthSummary } from 'api';

interface MonthTowingCount {
  count: number;
  label: string;
}

const monthReportSorter = (a: TowingMonthSummary, b: TowingMonthSummary) =>
  a.year < b.year || (a.year === b.year && a.month < b.month) ? -1 : 1;

export const getMonthTowingsStatisticsData = (
  monthReports: TowingMonthSummary[]
): MonthTowingCount[] => {
  const sorted = monthReports.sort(monthReportSorter);

  const lastEle = sorted[sorted.length - 1];
  const firstEle = sorted[0];

  if (!lastEle || !firstEle) {
    return [];
  }

  const towingMonthSummaryByMonth: {
    [monthYear: string]: TowingMonthSummary;
  } = {};

  monthReports.forEach(
    towingSummary =>
      (towingMonthSummaryByMonth[
        `${towingSummary.month}-${towingSummary.year}`
      ] = towingSummary)
  );

  // fill in all missing months with TowingMonthSummary with count = 0
  for (let year = firstEle.year; year <= lastEle.year; year++) {
    for (
      let month = year === firstEle.year ? firstEle.month : 1;
      month <= (year === lastEle.year ? lastEle.month : 12);
      month++
    ) {
      if (!towingMonthSummaryByMonth[`${month}-${year}`]) {
        towingMonthSummaryByMonth[`${month}-${year}`] = new TowingMonthSummary({
          count: 0,
          month,
          year,
        });
      }
    }
  }

  const asArray = Object.keys(towingMonthSummaryByMonth)
    .map(monthYearKey => towingMonthSummaryByMonth[monthYearKey])
    .sort(monthReportSorter);

  return asArray.map((report, index) => ({
    count: report.count,
    label:
      toShortMonthString(report.month) +
      (index === 0 || report.month === 1 ? '\n' + report.year : ''),
  }));
};

const toShortMonthString = (month: number) => {
  const date = new Date();
  date.setMonth(month - 1, 1);
  return date.toLocaleString('sv-SE', { month: 'short' });
};
