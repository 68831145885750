import React, { FunctionComponent } from 'react';
import useResponse from 'api/hooks/useResponse';
import { UserAdminClient } from 'api';
import ManageRequestStatus from 'components/ManageRequestStatus';
import styled, { css } from 'styled-components';
import SortedTable from 'components/Table/SortedTable';
import { Sizes, Colors } from 'utils/style';
import Container from 'components/primitives/Container';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router-dom';
import Routes, { RouteParams } from 'utils/routes';

const MyTable = styled(SortedTable)`
  margin-bottom: ${Sizes.Gutter}px;
` as typeof SortedTable;

const EditButton = styled(Link)`
  &,
  &:hover,
  &:visited {
    background: transparent;
    color: ${Colors.Red};
    font-size: 20px;
  }
`;

const UsersTab: FunctionComponent = () => {
  const users = useResponse(new UserAdminClient(), (client) =>
    client.getUsers()
  );

  return (
    <ManageRequestStatus status={[users.status]}>
      <Container>
        {users.response && (
          <MyTable
            columnSettings={[
              // { header: 'Id', attribute: 'id' },
              { header: 'Namn', attribute: 'displayName' },
              { header: 'Epost', attribute: 'email' },
              { header: 'Telefonnummer', attribute: 'phoneNumber' },
              {
                header: '',
                cellRenderer: ({ row }) => (
                  <EditButton
                    to={generatePath(Routes.UserAdmin, {
                      [RouteParams.userId]: row.id ?? '',
                    })}
                  >
                    <i className="las la-edit" />
                  </EditButton>
                ),
                notSortable: true,
                width: '50px',
                cellStyle: css`
                  padding-top: 0;
                  padding-bottom: 0;
                `,
              },
            ]}
            rows={users.response}
          />
        )}
      </Container>
    </ManageRequestStatus>
  );
};

export default UsersTab;
