import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Container from 'components/primitives/Container';
import useResponse from 'api/hooks/useResponse';
import { CustomerAdminClient, StationClient, CustomerModel } from 'api';
import Routes, { ParamsIn, RouteParams } from 'utils/routes';
import { useParams, useHistory } from 'react-router-dom';
import PageHead from 'components/primitives/PageHead';
import EditCustomerFields from './EditCustomerFields';
import EditCustomerStations from './EditCustomerStations';
import { MediaQuery, Colors, Sizes } from 'utils/style';
import ManageRequestStatus from 'components/ManageRequestStatus';
import useApiCall, { RequestStatus } from 'api/hooks/useApiCall';
import useModalStack from 'components/Modal/useModalStack';
import ButtonsModal from 'components/Modal/ButtonsModal';

const customerFieldsClass = 'customerFieldsClass';
const customerStationsClass = 'customerStationsClass';

const MyContainer = styled(Container)`
  padding: ${Sizes.Gutter / 2}px;

  .${customerStationsClass} {
    margin-top: ${Sizes.Gutter * 2}px;
  }

  ${MediaQuery.tablet} {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: ${Sizes.Gutter}px;
    border-top: 1px solid ${Colors.Gray};

    .${customerFieldsClass} {
      flex: 1;
      padding-right: ${Sizes.Gutter}px;
      margin-right: ${Sizes.Gutter}px;
      border-right: 1px solid ${Colors.Gray};
    }

    .${customerStationsClass} {
      flex: 3;
      margin-top: 0;
    }
  }
`;

const CustomerAdminPage: FunctionComponent = () => {
  const modalStack = useModalStack();
  const history = useHistory();
  const { [RouteParams.customerId]: customerIdStr } =
    useParams<ParamsIn<typeof Routes.CustomerAdmin>>();
  const customerResponse = useResponse(
    new CustomerAdminClient(),
    async (client) => {
      if (customerIdStr) {
        return await client.getCustomer(Number(customerIdStr));
      }
    }
  );
  const stationsResponse = useResponse(new StationClient(), (client) =>
    client.getAllStations()
  );

  const setCustomerIsActiveCall = useApiCall(
    new CustomerAdminClient(),
    (client, customer: CustomerModel, isActive: boolean) =>
      client.updateCustomer(
        customer.id,
        new CustomerModel({
          ...customer,
          isActive,
        })
      )
  );

  const customer = customerResponse.response;
  const stations = stationsResponse.response;

  return (
    <>
      <PageHead
        backTo={Routes.AdminCustomersTab}
        backToLabel="Admin"
        actions={[
          {
            label: customer?.isActive ? (
              <>
                <i className="las la-trash" /> Ta bort kund
              </>
            ) : (
              <>
                <i className="las la-undo" /> Återställ kund
              </>
            ),
            onClick: () => {
              if (!customer) return;

              modalStack.push(
                <ButtonsModal
                  title={customer.isActive ? 'Ta bort kund' : 'Återställ kund'}
                  onClose={() => modalStack.pop()}
                  buttons={[
                    {
                      label: customer.isActive ? (
                        <>
                          <i className="las la-exclamation-triangle" /> Ta bort
                          kund
                        </>
                      ) : (
                        <>
                          <i className="las la-undo" /> Återställ kund
                        </>
                      ),
                      onClick: async () => {
                        if (!customer) {
                          return;
                        }
                        const [updatedCustomer, error] =
                          await setCustomerIsActiveCall.run(
                            customer,
                            !customer.isActive
                          );

                        if (!error && updatedCustomer) {
                          if (!updatedCustomer.isActive) {
                            history.replace(Routes.AdminCustomersTab);
                          } else {
                            customerResponse.update(updatedCustomer);
                          }
                        }
                      },
                    },
                    {
                      label: 'Avbryt',
                    },
                  ]}
                >
                  {customer.isActive
                    ? 'Är du säker på att du vill ta bort kunden?'
                    : 'Är du säker på att du vill återställa kunden?'}
                </ButtonsModal>
              );
            },
            disabled:
              !customerResponse.response ||
              setCustomerIsActiveCall.status === RequestStatus.Fetching,
          },
        ]}
      >
        {customer ? (
          customer.isActive ? (
            `Redigerar kund: ${customer.displayName}`
          ) : (
            <>
              <i className="las la-trash" /> Borttagen kund:{' '}
              {customer.displayName}
            </>
          )
        ) : (
          'Redigerar kund'
        )}
      </PageHead>

      <ManageRequestStatus
        status={[
          customerResponse.status,
          stationsResponse.status,
          setCustomerIsActiveCall.status,
        ]}
      >
        <MyContainer>
          {customer && stations && (
            <>
              <EditCustomerFields
                customer={customer}
                onCustomerUpdated={customerResponse.update}
                readOnly={!customer.isActive}
                className={customerFieldsClass}
              />
              <EditCustomerStations
                allStations={stations}
                customer={customer}
                onCustomerUpdated={customerResponse.update}
                readOnly={!customer.isActive}
                className={customerStationsClass}
              />
            </>
          )}
        </MyContainer>
      </ManageRequestStatus>
    </>
  );
};

export default CustomerAdminPage;
