import React, { FC, useEffect } from 'react';
import Modal from 'components/Modal';
import styled from 'styled-components';
import { InventoryClient, UpdateInventoryRequest } from 'api';
import { MediaQuery } from 'utils/style';
import InventoryInfoSection from './InventoryInfoSection';
import InventoryLogSection from './InventoryLogSection';
import { useHistory, useParams } from 'react-router-dom';
import useResponse from 'api/hooks/useResponse';
import Routes, { ParamsIn } from 'utils/routes';
import ManageRequestStatus from 'components/ManageRequestStatus';
import InventoryImagesSection from './InventoryImagesSection';
import { useForm } from 'react-hook-form';
import {
  defaultInventoryFormValues,
  InventoryFormValues,
  InventoryFormValuesToUpdateInventoryRequest,
  InventoryModelToFormValues,
} from './utils/inventoryFormConverters';
import Button from 'components/primitives/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import useApiCall from 'api/hooks/useApiCall';
import { FormActionButtons, formActionButtonsHeight } from './components';

const MyModal = styled(Modal)`
  ${MediaQuery.desktop} {
    height: 80svh;
    width: 80svw;
    max-width: 1500px;
  }
  ${MediaQuery.tablet} {
    height: 95svh;
    width: 95svw;
    max-width: 1500px;
  }
`;

const Layout = styled.div<{ formIsDirty: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: ${({ formIsDirty }) =>
    formIsDirty ? formActionButtonsHeight : 0}px;

  ${MediaQuery.tablet} {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    margin-bottom: 0;
  }
`;

const LeftSections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${MediaQuery.tablet} {
    flex: 2;
    overflow: auto;
  }
`;

interface Props {
  onInventoryUpdated: () => void;
}

const InventoryModal: FC<Props> = ({ onInventoryUpdated }) => {
  const history = useHistory();
  const { inventoryItemId } =
    useParams<ParamsIn<typeof Routes.InventoryItem>>();

  const inventoryResponse = useResponse(new InventoryClient(), (c) =>
    c.getInventoryDetails(Number(inventoryItemId))
  );
  const updateInventoryApiCall = useApiCall(
    new InventoryClient(),
    (c, inventoryId: number, updateRequest: UpdateInventoryRequest) =>
      c.update(inventoryId, updateRequest)
  );

  const logsResponse = useResponse(new InventoryClient(), (c) =>
    c.getComments(Number(inventoryItemId))
  );

  const form = useForm<InventoryFormValues>({
    defaultValues: defaultInventoryFormValues,
  });

  const { isDirty } = form.formState;

  useEffect(() => {
    if (inventoryResponse.response) {
      form.reset(InventoryModelToFormValues(inventoryResponse.response));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryResponse.response]);

  const handleSave = async (formValues: InventoryFormValues) => {
    const updateRequest =
      InventoryFormValuesToUpdateInventoryRequest(formValues);

    if (!inventoryResponse.response) return;

    const [response, error] = await updateInventoryApiCall.run(
      inventoryResponse.response.id,
      updateRequest
    );

    if (response && !error) {
      onInventoryUpdated();
      inventoryResponse.update(response);
      logsResponse.update();
    }
  };

  return (
    <MyModal
      title={
        inventoryResponse.response?.registrationNumber ?? 'Uppställt fordon'
      }
      condensedTitle
      onClose={() => history.goBack()}
    >
      <ManageRequestStatus status={[inventoryResponse.status]}>
        {inventoryResponse.response && (
          <Layout formIsDirty={true}>
            <LeftSections>
              <ManageRequestStatus
                status={[updateInventoryApiCall.status]}
                ignoreErrorStatus
                loadingMessage="Sparar..."
              >
                <InventoryInfoSection
                  onOwnTransferBooked={() => {
                    onInventoryUpdated();
                    inventoryResponse.update();
                    logsResponse.update();
                  }}
                  inventory={inventoryResponse.response}
                  form={form}
                  onTransferDeleted={() => {
                    inventoryResponse.update();
                  }}
                />
                <InventoryImagesSection
                  inventory={inventoryResponse.response}
                />

                <FormActionButtons>
                  {isDirty && (
                    <Button
                      small
                      variant="secondary"
                      onClick={() => form.reset()}
                    >
                      <FontAwesomeIcon icon={faUndo} /> Ångra ändringar
                    </Button>
                  )}

                  <Button
                    small
                    disabled={!isDirty}
                    onClick={form.handleSubmit(handleSave)}
                  >
                    Spara
                  </Button>
                </FormActionButtons>
              </ManageRequestStatus>
            </LeftSections>

            <InventoryLogSection
              logsResponse={logsResponse}
              inventoryId={Number(inventoryItemId)}
              onCommentAdded={() => {
                logsResponse.update();
              }}
            />
          </Layout>
        )}
      </ManageRequestStatus>
    </MyModal>
  );
};

export default InventoryModal;
