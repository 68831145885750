import React, { FunctionComponent } from 'react';
import { Role } from 'api';
import useUserInfo from 'hooks/useUserInfo';

interface Props {
  roles: Role[];
}

const VisibleForRole: FunctionComponent<Props> = ({ roles, children }) => {
  const userInfo = useUserInfo();

  if (!userInfo || !userInfo.roles) {
    return null;
  }

  for (let userRole of userInfo.roles) {
    for (let allowedRole of roles) {
      if (userRole === allowedRole) {
        return <>{children}</>;
      }
    }
  }

  return null;
};

export default VisibleForRole;
