import React, { FC } from 'react';
import { StagingLotWeekDay } from 'api';
import Button from 'components/primitives/Button';
import styled from 'styled-components';
import { shortDayLabelsByWeekDay } from '../utils';
import { Colors } from 'utils/style';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const DayButton = styled(Button)`
  flex: 1;
  padding: 10px;
  border: 1px solid ${Colors.Gray};

  &:not(:last-child) {
    border-right: none;
  }
`;

interface Props {
  selectedDays: Set<StagingLotWeekDay>;
  onSelectedDaysChanged: (newSelectedDays: Set<StagingLotWeekDay>) => void;
}

const DaysPicker: FC<Props> = ({ selectedDays, onSelectedDaysChanged }) => {
  const mkDayButton = (day: StagingLotWeekDay) => (
    <DayButton
      key={day}
      variant={selectedDays.has(day) ? 'primary' : 'secondary'}
      onClick={() => {
        const newSelectedDays = new Set(selectedDays);
        if (newSelectedDays.has(day)) {
          newSelectedDays.delete(day);
        } else {
          newSelectedDays.add(day);
        }
        onSelectedDaysChanged(newSelectedDays);
      }}
    >
      {shortDayLabelsByWeekDay[day]}
    </DayButton>
  );

  return (
    <Wrapper>
      {mkDayButton(StagingLotWeekDay.M)}
      {mkDayButton(StagingLotWeekDay.Ti)}
      {mkDayButton(StagingLotWeekDay.O)}
      {mkDayButton(StagingLotWeekDay.To)}
      {mkDayButton(StagingLotWeekDay.F)}
      {mkDayButton(StagingLotWeekDay.L)}
      {mkDayButton(StagingLotWeekDay.S)}
    </Wrapper>
  );
};

export default DaysPicker;
