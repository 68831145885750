import React, { FC } from 'react';
import ButtonsModal from 'components/Modal/ButtonsModal';
import { IStagingLotTimeSlotGroupModel, StagingLotClient } from 'api';
import { toHHMM } from './utils';
import useApiCall, { RequestStatus } from 'api/hooks/useApiCall';
import ManageRequestStatus from 'components/ManageRequestStatus';

interface Props {
  stagingLotId: number;
  timeSlot: IStagingLotTimeSlotGroupModel;
  onTimeSlotDeleted: () => void;
  onCancel: () => void;
}

const DeleteTimeSlotModal: FC<Props> = ({
  stagingLotId,
  timeSlot,
  onTimeSlotDeleted,
  onCancel,
}) => {
  const deleteCall = useApiCall(
    new StagingLotClient(),
    (c, stagingLotId: number, timeSlotId: number) =>
      c.deleteTimeSlot(stagingLotId, timeSlotId)
  );

  const handleDeleteClick = async () => {
    const [, error] = await deleteCall.run(stagingLotId, timeSlot.id);

    if (!error) {
      onTimeSlotDeleted();
    }
  };

  const name = timeSlot.label?.trim() ? `'${timeSlot.label}'` : 'tidsintervall';

  return (
    <ButtonsModal
      title={`Ta bort ${name}?`}
      onClose={onCancel}
      buttons={
        deleteCall.status === RequestStatus.Fetching
          ? []
          : [
              {
                label: (
                  <>
                    <i
                      className="las la-trash"
                      style={{
                        fontSize: 20,
                      }}
                    />{' '}
                    Ta bort
                  </>
                ),
                dontPopAfterClick: true,
                onClick: handleDeleteClick,
              },
              {
                label: 'Avbryt',
                dontPopAfterClick: true,
                onClick: onCancel,
              },
            ]
      }
    >
      <ManageRequestStatus
        status={[deleteCall.status]}
        loadingMessage="Tar bort tidsintervall..."
      >
        Är du säker på att du vill ta bort {name} {toHHMM(timeSlot.startTime)} -{' '}
        {toHHMM(timeSlot.endTime)}?
      </ManageRequestStatus>
    </ButtonsModal>
  );
};

export default DeleteTimeSlotModal;
