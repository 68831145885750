import styled from 'styled-components';
import { Colors } from 'utils/style';

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 700px;
  margin: 0 auto;

  background-color: ${Colors.White};
`;

export default BottomSection;
