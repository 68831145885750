import React, { FunctionComponent } from 'react';
import EditableList from 'components/Table/EditableList';
import { CustomerModel, StationPreviewModel, CustomerAdminClient } from 'api';
import styled from 'styled-components';
import useApiCall from 'api/hooks/useApiCall';
import ManageRequestStatus from 'components/ManageRequestStatus';
import useModalStack from 'components/Modal/useModalStack';
import ButtonsModal from 'components/Modal/ButtonsModal';

const Wrapper = styled.div``;

interface Props {
  allStations: StationPreviewModel[];
  customer: CustomerModel;
  onCustomerUpdated(customer: CustomerModel): void;
  readOnly: boolean;
  className?: string;
}

const EditCustomerStations: FunctionComponent<Props> = ({
  customer,
  allStations,
  onCustomerUpdated,
  readOnly,
  className,
}) => {
  const modalStack = useModalStack();
  const addStationCall = useApiCall(
    new CustomerAdminClient(),
    (c, stationId: number) => c.addCustomerStation(customer.id, stationId)
  );
  const removeStationCall = useApiCall(
    new CustomerAdminClient(),
    (c, stationId: number) => c.removeCustomerStation(customer.id, stationId)
  );

  const handleAddStation = async (station: StationPreviewModel) => {
    const [newStation] = await addStationCall.run(station.id);

    newStation &&
      onCustomerUpdated(
        CustomerModel.fromJS({
          ...customer,
          stations: [...(customer.stations ?? []), newStation],
        })
      );
  };

  const handleRemoveStation = async (station: StationPreviewModel) => {
    modalStack.push(
      <ButtonsModal
        title="Ta bort station"
        onClose={() => modalStack.pop()}
        buttons={[
          {
            label: 'Ta bort',
            onClick: async () => {
              const [, error] = await removeStationCall.run(station.id);

              !error &&
                onCustomerUpdated(
                  CustomerModel.fromJS({
                    ...customer,
                    stations: customer.stations?.filter(
                      (s) => s.id !== station.id
                    ),
                  })
                );
            },
          },
          { label: 'Avbryt' },
        ]}
      >
        Är du säker på att du vill ta bort stationen "<b>{station.name}</b>"?
      </ButtonsModal>
    );
  };

  return (
    <Wrapper className={className}>
      <ManageRequestStatus
        status={[addStationCall.status, removeStationCall.status]}
      >
        <EditableList
          title="Stationer"
          addButtonText="Lägg till"
          selectPlaceholder="Välj station"
          addableItems={allStations
            .filter(
              (station) => !customer.stations?.find((s) => s.id === station.id)
            )
            .map((station) => ({
              name: station.name ?? '-',
              value: station,
            }))}
          rows={customer.stations ?? []}
          columnSettings={[{ header: 'Station', attribute: 'name' }]}
          onAddItem={readOnly ? undefined : handleAddStation}
          onRemoveItem={readOnly ? undefined : handleRemoveStation}
        />
      </ManageRequestStatus>
    </Wrapper>
  );
};

export default EditCustomerStations;
