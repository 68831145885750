import React, { useState, useCallback } from 'react';
import useModalStack from 'components/Modal/useModalStack';
import useFetchErrorHandler from './useFetchErrorHandler';

export enum RequestStatus {
  Idle,
  Fetching,
  Fetched,
  Error,
}

export interface UseApiCallOptions {
  /** if you want to replace the currently visible modal with error modal if an error occurs */
  popModalBeforeError?: boolean;
  errorMessage?: string;
  initialStatus?: RequestStatus;
  ignoreUnauthorizedErrors?: boolean;
}

export type RunReturnValue<Response> =
  | [response: Response, error: undefined]
  | [response: undefined, error: unknown];

export interface ApiCall<Response, ExtraParams extends any[]> {
  /** Runs the api call and manages any server or connection errors that may occur in a user-friendly way.*/
  run(...extra: ExtraParams): Promise<RunReturnValue<Response>>;

  response?: Response;
  error?: unknown;
  status: RequestStatus;
  setResponse: React.Dispatch<React.SetStateAction<Response | undefined>>;
  setStatus: React.Dispatch<React.SetStateAction<RequestStatus>>;
}

interface State<Response> {
  response?: Response;
  error?: unknown;
  status: RequestStatus;
}

const useApiCall = <Client, Response, ExtraParams extends any[]>(
  client: Client,
  methodCaller: (client: Client, ...extra: ExtraParams) => Promise<Response>,
  options?: UseApiCallOptions
): ApiCall<Response, ExtraParams> => {
  const [state, setState] = useState<State<Response>>({
    response: undefined,
    error: undefined,
    status: options?.initialStatus ?? RequestStatus.Idle,
  });

  const [errorHandler] = useFetchErrorHandler(
    options?.errorMessage,
    options?.ignoreUnauthorizedErrors
  );
  const popModal = useModalStack().pop;

  const run = useCallback(
    async (...extra: ExtraParams): Promise<RunReturnValue<Response>> => {
      setState((s) => ({ ...s, status: RequestStatus.Fetching }));

      try {
        const response = await methodCaller(client, ...extra);
        setState((s) => ({ ...s, status: RequestStatus.Fetched, response }));

        return [response, undefined];
      } catch (err) {
        options?.popModalBeforeError && popModal();
        setState((s) => ({ ...s, status: RequestStatus.Error, error: err }));

        errorHandler(err);

        return [undefined, err];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorHandler, methodCaller, options, popModal]
  );

  const setResponse: React.Dispatch<
    React.SetStateAction<Response | undefined>
  > = useCallback((setAction) => {
    if (setAction instanceof Function) {
      setState((s) => ({ ...s, response: setAction(s.response) }));
    } else {
      setState((s) => ({ ...s, response: setAction }));
    }
  }, []);

  const setStatus: React.Dispatch<React.SetStateAction<RequestStatus>> =
    useCallback((setAction) => {
      if (setAction instanceof Function) {
        setState((s) => ({ ...s, status: setAction(s.status) }));
      } else {
        setState((s) => ({ ...s, status: setAction }));
      }
    }, []);

  return {
    run,
    response: state.response,
    error: state.error,
    status: state.status,
    setResponse,
    setStatus,
  };
};

export default useApiCall;
