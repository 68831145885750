import React, { FC, useEffect, useRef, useState } from 'react';
import useApiCall, { RequestStatus } from 'api/hooks/useApiCall';
import {
  InventoryClient,
  InventoryModel,
  RepairShopInfoWithCustomerName,
} from 'api';
import Popover, { SelectItem, SelectItems } from 'components/Popover';
import Spinner from 'components/Spinner';
import styled from 'styled-components';
import { Colors, Sizes } from 'utils/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import RegNrInput from 'components/form/RegNrInput';

const MySpinner = styled(Spinner)`
  padding: 20px;
`;

const Notice = styled.div`
  padding: ${Sizes.Gutter / 4}px ${Sizes.Gutter / 2}px;
  color: ${Colors.DarkGray};
  text-style: italic;
`;

const MySelectItem = styled(SelectItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  b {
    font-weight: 500;
  }
`;

interface Props {
  repairshop: RepairShopInfoWithCustomerName | undefined;
  value: string;
  onChange: (value: string) => void;
  onInventorySelected: (inventory: InventoryModel) => void;
}

const InventorySearchInput: FC<Props> = ({
  repairshop,
  value,
  onChange,
  onInventorySelected,
}) => {
  const [showResults, setShowResults] = useState(false);
  const [markedItemIndex, setMarkedItemIndex] = useState(0);
  const popoverRef = useRef<HTMLDivElement>(null);

  const searchInventoryCall = useApiCall(
    new InventoryClient(),
    (c, repairShopId: number, regNr: string) =>
      c.bookingSearch(repairShopId, regNr)
  );

  const supportsStagingLotGroupSearch =
    !!repairshop?.repairshopInfo?.id && !!repairshop.stagingLotGroupId;

  useEffect(() => {
    const debounce = setTimeout(async () => {
      if (!value || !supportsStagingLotGroupSearch) {
        searchInventoryCall.setResponse(undefined);
        return;
      }
      await searchInventoryCall.run(repairshop.repairshopInfo!.id, value);
      setMarkedItemIndex(0);
    }, 500);

    return () => {
      clearTimeout(debounce);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const incrementMarkedItemIndex = (offset: number) => {
    setMarkedItemIndex((i) => {
      const newIndex =
        (i + offset) % (searchInventoryCall.response?.length ?? 0);

      if (newIndex < 0)
        return (searchInventoryCall.response?.length ?? 1) - 1 || 0;

      return newIndex;
    });
  };

  return (
    <Popover
      open={supportsStagingLotGroupSearch && !!value && showResults}
      autoFocusOnOpen={false}
      autofocusTriggerOnClose={false}
      fullWidth
      noPadding
      noArrow
      content={
        <SelectItems>
          <Notice>
            <FontAwesomeIcon icon={faSearch} /> Uppställda fordon på{' '}
            <b>{repairshop?.customerName}</b>
          </Notice>

          {searchInventoryCall.status === RequestStatus.Fetching ? (
            <MySpinner>Söker...</MySpinner>
          ) : (
            searchInventoryCall.response
              ?.sort((a, b) =>
                (a.registrationNumber ?? '') > (b.registrationNumber ?? '')
                  ? 1
                  : a.registrationNumber === b.registrationNumber
                  ? 0
                  : -1
              )
              .map((inventory, i) => (
                <MySelectItem
                  key={inventory.id}
                  selected={markedItemIndex === i}
                  onClick={() => {
                    onInventorySelected(inventory);
                    setShowResults(false);
                  }}
                >
                  <b>{inventory.registrationNumber}</b>
                </MySelectItem>
              ))
          )}
        </SelectItems>
      }
      ref={popoverRef}
    >
      <RegNrInput
        value={value}
        onFocus={() => {
          setShowResults(true);
        }}
        onKeyDown={(e) => {
          if (!showResults) return;

          switch (e.key) {
            case 'ArrowDown':
              e.preventDefault();
              incrementMarkedItemIndex(1);
              return;

            case 'ArrowUp':
              e.preventDefault();
              incrementMarkedItemIndex(-1);
              return;

            case 'Enter':
              const selectedInventory =
                searchInventoryCall.response?.[markedItemIndex];

              if (selectedInventory) {
                onInventorySelected(selectedInventory);
                setShowResults(false);
              }
              return;

            case 'Escape':
              setShowResults(false);
              return;
          }
        }}
        onBlur={(eve) => {
          if (
            eve.relatedTarget instanceof Node &&
            popoverRef.current?.contains(eve.relatedTarget)
          )
            return;

          // Auto-select inventory if there is only one exact match
          if (
            searchInventoryCall.response?.length === 1 &&
            searchInventoryCall.response[0].registrationNumber === value
          ) {
            onInventorySelected(searchInventoryCall.response[0]);
          }

          setShowResults(false);
        }}
        onChange={(value) => {
          onChange(value);
          setShowResults(true);
        }}
      />
    </Popover>
  );
};

export default InventorySearchInput;
