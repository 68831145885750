import styled from 'styled-components';
import { MediaQuery } from 'utils/style';

export const centerContentWidth = 1500;

const CenterContent = styled.div`
  flex: 1;

  ${MediaQuery.desktop} {
    width: ${centerContentWidth}px;
    max-width: 100%;
    margin: 0 auto;
  }
`;

export default CenterContent;
