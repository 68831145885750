import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import Container from 'components/primitives/Container';
import SortedTable from 'components/Table/SortedTable';
import { Sizes, Colors } from 'utils/style';
import { Link } from 'react-router-dom';
import useResponse from 'api/hooks/useResponse';
import { CustomerAdminClient } from 'api';
import useModalStack from 'components/Modal/useModalStack';
import { generatePath } from 'react-router-dom';
import Routes, { RouteParams } from 'utils/routes';
import AddCustomerModal from './AddCustomerModal';
import Button from 'components/primitives/Button';
import ManageRequestStatus from 'components/ManageRequestStatus';
import Toggle from 'components/form/Toggle';

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const MyButton = styled(Button)`
  margin: ${Sizes.Gutter}px 0;
  margin-left: auto;
`;

const EditButton = styled(Link)`
  &,
  &:hover,
  &:visited {
    background: transparent;
    color: ${Colors.Red};
    font-size: 20px;
  }
`;

interface Props {}

const CustomersTab: FunctionComponent<Props> = () => {
  const [showDeactivatedCustomers, setShowDeactivatedCustomers] =
    React.useState(false);
  const customers = useResponse(new CustomerAdminClient(), (client) =>
    client.getCustomers()
  );
  const modalStack = useModalStack();

  const filteredCustomers = React.useMemo(() => {
    if (!customers.response) return [];
    if (showDeactivatedCustomers) return customers.response;

    return customers.response.filter((customer) => customer.isActive);
  }, [customers.response, showDeactivatedCustomers]);

  return (
    <ManageRequestStatus status={[customers.status]}>
      <Wrapper>
        <Actions>
          <Toggle
            on={showDeactivatedCustomers}
            onClick={() => setShowDeactivatedCustomers((s) => !s)}
          />
          Visa borttagna kunder
          <MyButton
            onClick={() =>
              modalStack.push(
                <AddCustomerModal
                  onCustomerAdded={(customer) =>
                    customers.update([...(customers.response || []), customer])
                  }
                />
              )
            }
          >
            <i className="las la-plus" /> Lägg till kund
          </MyButton>
        </Actions>

        <SortedTable
          columnSettings={[
            { header: 'Id', attribute: 'id', width: '70px' },
            ...(showDeactivatedCustomers
              ? [
                  {
                    header: 'Borttagen',
                    width: '110px',
                    attribute: 'isActive',
                    formatter: (v: boolean) => (!v ? 'Ja' : 'Nej'),
                  } as const,
                ]
              : []),
            {
              header: 'Verkstads-id',
              attribute: 'repairshopId',
              width: '150px',
            },
            { header: 'Visningsnamn', attribute: 'displayName' },
            { header: 'Organisationsnummer', attribute: 'orgNr' },
            {
              header: 'Statistik - avståndsavgränsning (km)',
              attribute: 'statisticsDistanceInKm',
            },
            {
              header: '',
              cellRenderer: ({ row }) => (
                <EditButton
                  to={generatePath(Routes.CustomerAdmin, {
                    [RouteParams.customerId]: row.id,
                  })}
                >
                  <i className="las la-edit" />
                </EditButton>
              ),
              notSortable: true,
              width: '50px',
              cellStyle: css`
                padding-top: 0;
                padding-bottom: 0;
              `,
            },
          ]}
          rows={filteredCustomers}
        />
      </Wrapper>
    </ManageRequestStatus>
  );
};

export default CustomersTab;
