import styled from 'styled-components';

import { Colors, FontSizes, Sizes, MediaQuery } from '../../utils/style';

export default styled.h1`
  background: ${Colors.DarkWhite};
  color: ${Colors.Black};
  font-size: ${FontSizes.Huge}px;
  font-weight: bold;

  margin: 0;
  padding: ${Sizes.Gutter / 2}px;

  ${MediaQuery.tablet} {
    padding: ${Sizes.Gutter}px;

    background: transparent;
    color: ${Colors.Black};
    font-size: ${FontSizes.Heading}px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;
