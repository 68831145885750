import React from 'react';
import useResponse from 'api/hooks/useResponse';
import useApiCall, { RequestStatus } from 'api/hooks/useApiCall';
import { UserClient, UserInfo } from 'api';
import Spinner from 'components/Spinner';
import SortedTable from 'components/Table/SortedTable';
import { RemoveButton } from 'components/Table/EditableList';
import useModalStack from 'components/Modal/useModalStack';
import ButtonsModal from 'components/Modal/ButtonsModal';
import styled, { css } from 'styled-components';

const MyTable = styled(SortedTable)`
  & > .table-column:last-child {
    flex: none;
  }
` as typeof SortedTable;

const UsersTable: React.SFC = () => {
  const users = useResponse(new UserClient(), (client) => client.getUsers());
  const removeUserCall = useApiCall(
    new UserClient(),
    (client, userId: string) => client.removeUser(userId)
  );

  const modalStack = useModalStack();

  const handleRemoveUser = (userInfo: UserInfo) => {
    modalStack.push(
      <ButtonsModal
        title="Är du säker?"
        onClose={() => modalStack.pop()}
        buttons={[
          {
            label: 'Ta bort',
            onClick: async () => {
              users.setStatus(RequestStatus.Fetching);
              const [, error] = await removeUserCall.run(userInfo.id!);
              !error && (await users.update());
            },
          },
          { label: 'Avbryt' },
        ]}
      >
        Är du säker på att du vill ta bort användaren{' '}
        <b>{userInfo.displayName}</b> som har eposten <b>{userInfo.email}</b>?
      </ButtonsModal>
    );
  };

  if (!users.response || users.status === RequestStatus.Fetching) {
    return <Spinner />;
  }

  return (
    <MyTable
      columnSettings={[
        {
          header: 'Namn',
          attribute: 'displayName',
          formatter: (v) => String(v || ''),
        },
        {
          header: 'Epost',
          attribute: 'email',
          formatter: (v) => String(v || ''),
        },
        {
          header: 'Ta bort',
          notSortable: true,
          cellRenderer: ({ row }) => (
            <RemoveButton onClick={() => handleRemoveUser(row)}>
              <i className="las la-trash"></i>
            </RemoveButton>
          ),
          width: '70px',
          cellStyle: css`
            padding-bottom: 0;
            padding-top: 0;
          `,
        },
      ]}
      rows={users.response}
    />
  );
};

export default UsersTable;
