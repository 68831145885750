import React, { useEffect, useMemo } from 'react';

const useOutsideClick = (
  refs: React.RefObject<HTMLElement>[],
  callback: () => any
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoedRefs = useMemo(() => refs, refs);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        !memoedRefs.some(
          (ref) =>
            ref.current &&
            e.target instanceof Node &&
            ref.current.contains(e.target)
        )
      ) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [callback, memoedRefs]);
};

export default useOutsideClick;
