import React, { FunctionComponent, useState } from 'react';
import { CustomerModel, CustomerAdminClient } from 'api';
import styled from 'styled-components';
import { Input, Select } from 'components/form';
import Button from 'components/primitives/Button';
import { Sizes, MediaQuery } from 'utils/style';
import useApiCall from 'api/hooks/useApiCall';
import ManageRequestStatus from 'components/ManageRequestStatus';
import { statisticsDistanceInKmOptions } from '../constants';
import LabeledText from 'components/LabeledText';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Sizes.Gutter / 2}px;

  ${MediaQuery.tablet} {
    align-items: flex-start;
  }
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface Props {
  customer: CustomerModel;
  onCustomerUpdated(customer: CustomerModel): void;
  readOnly: boolean;
  className?: string;
}

const EditCustomerFields: FunctionComponent<Props> = ({
  customer,
  onCustomerUpdated,
  readOnly,
  className,
}) => {
  const [name, setName] = useState(customer.displayName);
  const [orgNo, setOrgNo] = useState(customer.orgNr);
  const [repairshopId, setRepairshopId] = useState(
    String(customer.repairshopId)
  );
  const [statisticsDistanceInKm, setStatisticsDistanceInKm] = useState(
    customer.statisticsDistanceInKm
  );

  const updateCall = useApiCall(
    new CustomerAdminClient(),
    (client, updateModel: CustomerModel) =>
      client.updateCustomer(customer.id, updateModel)
  );

  const handleSaveClick = async () => {
    const updatedModel = CustomerModel.fromJS({ ...customer });
    updatedModel.displayName = name;
    updatedModel.orgNr = orgNo;
    updatedModel.repairshopId = Number(repairshopId);
    updatedModel.statisticsDistanceInKm = statisticsDistanceInKm;

    const [savedCustomer] = await updateCall.run(updatedModel);

    if (savedCustomer) {
      onCustomerUpdated(savedCustomer);
    }
  };

  if (readOnly) {
    return (
      <Wrapper className={className}>
        <LabeledText label="Visningsnamn" text={customer.displayName} />
        <LabeledText label="Organisationsnummer" text={customer.orgNr} />
        <LabeledText
          label="Verkstads-id"
          text={String(customer.repairshopId)}
        />
        <LabeledText
          label="Statistik - avståndsavgränsning"
          text={`${customer.statisticsDistanceInKm} km`}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper className={className}>
      <ManageRequestStatus
        status={[updateCall.status]}
        loadingMessage="Sparar..."
      >
        <InputWrap>
          <b title="Ändrar bara det namn som används för att byta mellan kunder.">
            Visningsnamn
          </b>
          <Input value={name} onChange={(eve) => setName(eve.target.value)} />
        </InputWrap>

        <InputWrap>
          <b>Organisationsnummer</b>
          <Input value={orgNo} onChange={(eve) => setOrgNo(eve.target.value)} />
        </InputWrap>

        <InputWrap>
          <b>Verkstads-id</b>
          <Input
            type="text"
            value={repairshopId}
            onChange={(eve) =>
              setRepairshopId(eve.target.value.replace(/\D/g, ''))
            }
          />
        </InputWrap>

        <InputWrap>
          <b>Statistik - avståndsavgränsning</b>
          <Select
            value={statisticsDistanceInKm}
            onChange={(eve) =>
              setStatisticsDistanceInKm(Number(eve.target.value))
            }
          >
            {statisticsDistanceInKmOptions.find(
              (v) => v === statisticsDistanceInKm
            ) === undefined ? (
              <option value={statisticsDistanceInKm}>
                {statisticsDistanceInKm} km
              </option>
            ) : null}

            {statisticsDistanceInKmOptions.map((v) => (
              <option key={v} value={v}>
                {v} km
              </option>
            ))}
          </Select>
        </InputWrap>

        <Button
          onClick={handleSaveClick}
          disabled={!name || !orgNo || !repairshopId || !statisticsDistanceInKm}
        >
          Spara
        </Button>
      </ManageRequestStatus>
    </Wrapper>
  );
};

export default EditCustomerFields;
