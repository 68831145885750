import { faCog, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InventoryCommentModel } from 'api';
import React, { FC } from 'react';
import styled from 'styled-components';
import { toLocalDateTimeString } from 'utils/dateTime';
import { Colors, FontSizes, Sizes } from 'utils/style';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: ${Sizes.Gutter / 2}px;

  background-color: ${Colors.White};
  border: 1px solid ${Colors.Gray};
`;

const TimeStamp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: ${FontSizes.Normal}px;
  color: ${Colors.SemiBlack};
`;

const LogText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  white-space: pre-wrap;
  font-weight: 500;
`;

const LogCreator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  color: ${Colors.SemiBlack};
`;

interface Props {
  log: InventoryCommentModel;
}

const LogItem: FC<Props> = ({ log }) => {
  return (
    <Wrapper>
      <TimeStamp>{toLocalDateTimeString(log.created)}</TimeStamp>
      <LogText>{log.text}</LogText>
      <LogCreator>
        {log.userName ? (
          <FontAwesomeIcon icon={faComment} color="#4A87F2" />
        ) : (
          <FontAwesomeIcon icon={faCog} color={Colors.SemiBlack} />
        )}
        {log.userName ? log.userName : 'System'}
      </LogCreator>
    </Wrapper>
  );
};

export default LogItem;
