import { Coordinate } from '.';

export const calcBounds = (coordinates: Coordinate[]) => {
  if (coordinates.length > 0) {
    const bounds = new window.google.maps.LatLngBounds();
    coordinates.forEach(coordinate => bounds!.extend(coordinate));
    return bounds;
  }
};

export const getSwedenBounds = () =>
  new window.google.maps.LatLngBounds(
    { lat: 55.0059799, lng: 10.5798 },
    { lat: 69.0599709, lng: 24.1773101 }
  );
