import styled from 'styled-components';
import { Sizes, Colors, FontSizes, MediaQuery } from 'utils/style';

export const HeadingsWrapper = styled.div`
  display: none;

  ${MediaQuery.tablet} {
    display: flex;
    flex-direction: row;
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid rgb(208, 208, 208);
    padding: ${Sizes.Gutter * 0.25}px 0;
  }
`;

export const Heading = styled.div<{ flex?: number }>`
  padding-right: ${Sizes.Gutter * 0.75}px;
  margin-right: ${Sizes.Gutter * 0.75}px;
  flex: ${(props) => props.flex || 1};

  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MobileSectionHeading = styled.div`
  font-weight: bold;

  ${MediaQuery.tablet} {
    display: none;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${Sizes.Gutter / 2}px 0;
  padding-left: ${Sizes.Gutter / 2}px;
  border-bottom: 1px solid ${Colors.Gray};

  &:nth-child(odd) {
    background: ${Colors.DarkWhite};
  }

  &:last-child {
    margin-bottom: 15vh;
  }

  ${MediaQuery.tablet} {
    flex-direction: row;
    padding-left: 0;

    &:nth-child(odd) {
      background: transparent;
    }
  }
`;

interface SectionProps {
  width?: number;
  flex?: number;
  mobileOrder?: number;
}
export const Section = styled.div<SectionProps>`
  width: ${(props) => props.width};
  margin: ${Sizes.Gutter / 4}px 0;
  flex: ${({ flex }) => flex || 1};
  order: ${({ mobileOrder }) => mobileOrder || 'unset'};

  ${MediaQuery.tablet} {
    padding-right: ${Sizes.Gutter * 0.75}px;
    margin-right: ${Sizes.Gutter * 0.75}px;
    order: unset;

    &:not(:last-child) {
      border-right: 1px solid ${Colors.Gray};
    }
  }

  & > * {
    color: ${Colors.Black};
    font-size: ${FontSizes.Normal}px;
    white-space: pre-wrap;
  }
`;

export const SectionLabel = styled.div`
  color: ${Colors.Black};
  font-size: ${FontSizes.Normal}px;

  ${MediaQuery.tablet} {
    font-weight: bold;
  }
`;
