import React from 'react';
import styled from 'styled-components';
import H3 from 'components/primitives/H3';
import LabeledText from 'components/LabeledText';
import { Colors, Sizes, MediaQuery } from 'utils/style';
import NotificationReceiversEditor from './NotificationReceiversEditor';
import { RepairShopInfoClient } from 'api';
import Container from 'components/primitives/Container';
import useResponse from 'api/hooks/useResponse';
import { RequestStatus } from 'api/hooks/useApiCall';
import TestNotificationButton from './TestNotificationButton';
import { supportContactEmail } from 'utils';
import PageHead from 'components/primitives/PageHead';
import Spinner from 'components/Spinner';
import TimeSlotsEditor from './TimeSlotsEditor';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MyContainer = styled(Container)`
  padding-top: ${Sizes.Gutter / 2}px;

  ${MediaQuery.tablet} {
    padding-top: ${Sizes.Gutter}px;
  }
`;

const SectionWrap = styled.div`
  display: flex;
  flex-direction: column;

  ${MediaQuery.desktop} {
    flex-direction: row;
    flex: 1;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;

  ${MediaQuery.desktop} {
    min-height: 100%;

    &:first-child {
      min-width: 700px;
      width: 33.33%;
    }

    &:last-child {
      flex: 1;
    }
    &:not(:first-child) .section-label {
      padding-left: ${Sizes.Gutter}px;
    }
  }
`;

const SectionLabel = styled.div`
  border-bottom: 1px solid ${Colors.Gray};
`;

const RepairShopInfo = styled.div`
  flex: 1;
  margin: ${Sizes.Gutter}px 0;

  ${MediaQuery.tablet} {
    margin-bottom: 0;
  }

  ${MediaQuery.desktop} {
    border-left: 1px solid ${Colors.Gray};
    padding: 0 ${Sizes.Gutter}px;
  }
`;

const LabeledTexts = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${Sizes.Gutter}px;

  ${MediaQuery.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: max-content;
    gap: ${Sizes.Gutter}px;
    min-height: 500px;
  }
`;

const RepairShopInfoNotice = styled.div`
  text-align: center;
  margin-bottom: ${Sizes.Gutter}px;
`;

const MyRepairshopPage = () => {
  const repairshopInfo = useResponse(new RepairShopInfoClient(), (client) =>
    client.getUsersCurrentCustomerRepairshopInfo()
  );

  const buildEmailString = () => {
    let result = '';

    if (!repairshopInfo.response) {
      return result;
    }

    if (
      repairshopInfo.response.prioritizedEmails &&
      repairshopInfo.response.prioritizedEmails.length > 0
    ) {
      result += repairshopInfo.response.prioritizedEmails.join(', ');
      result += '\n';
    }

    if (
      repairshopInfo.response.otherEmails &&
      repairshopInfo.response.otherEmails.length > 0
    ) {
      result += repairshopInfo.response.otherEmails.join(', ');
    }

    return result;
  };

  return (
    <Wrapper>
      <PageHead>Min verkstad</PageHead>

      {repairshopInfo.status === RequestStatus.Fetching ? (
        <Spinner />
      ) : (
        <MyContainer>
          <SectionWrap>
            <Section>
              <SectionLabel className="section-label">
                <H3>Notifikationer</H3>
              </SectionLabel>
              <NotificationReceiversEditor />

              <TestNotificationButton />
            </Section>

            <Section>
              <SectionLabel className="section-label">
                <H3>Verkstadsuppgifter</H3>
              </SectionLabel>
              <RepairShopInfo>
                {repairshopInfo.response && (
                  <LabeledTexts>
                    <LabeledText
                      label="Namn"
                      text={repairshopInfo.response.name}
                    />
                    <LabeledText
                      label="Adress"
                      text={`${repairshopInfo.response.address}\n${
                        repairshopInfo.response.zip
                          ? repairshopInfo.response.zip + ' '
                          : ''
                      }${repairshopInfo.response.city}`}
                    />
                    <LabeledText label="E-post" text={buildEmailString()} />
                    <LabeledText
                      label="Telefon"
                      text={repairshopInfo.response.phoneNumbersString}
                    />

                    <LabeledText
                      label="Verkstadstyp"
                      text={repairshopInfo.response.departmentTypes?.join(', ')}
                    />

                    <LabeledText
                      label="Hanterar bilmärken"
                      text={repairshopInfo.response.carBrands
                        ?.map((cb) => cb.name)
                        .join(', ')}
                    />

                    <LabeledText
                      label="Auktoriserad för"
                      text={repairshopInfo.response.carBrands
                        ?.filter((cb) => cb.isAuthorized)
                        .map((cb) => cb.name)
                        .join(', ')}
                    />

                    <LabeledText
                      label="Vagnskadegarantier för"
                      text={repairshopInfo.response.carBrands
                        ?.filter((cb) => cb.hasWarranty)
                        .map((cb) => cb.name)
                        .join(', ')}
                    />

                    {/* 
                  <LabeledText label="Hemsida" text={'TODO'} />
                  <LabeledText label="Tillgänglighet" text={'TODO'} />
                  <LabeledText label="Kodlås yttergrind" text={'TODO'} />
                  <LabeledText label="Larm" text={'TODO'} />
                  */}
                  </LabeledTexts>
                )}

                <RepairShopInfoNotice>
                  Om något inte stämmer, maila {supportContactEmail}
                </RepairShopInfoNotice>

                <TimeSlotsEditor />
              </RepairShopInfo>
            </Section>
          </SectionWrap>
        </MyContainer>
      )}
    </Wrapper>
  );
};

export default MyRepairshopPage;
