import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StagingLotModel } from 'api';
import { RequestStatus } from 'api/hooks/useApiCall';
import {
  shortDayLabelsByWeekDay,
  sortDaysList,
  toHHMM,
} from 'containers/MyRepairshopPage/TimeSlotsEditor/utils';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Colors } from 'utils/style';
import pulseLoadingAnimation from '../animations/pulseLoading';
import useMatchingTimeSlots from './useMatchingTimeSlots';

const Wrapper = styled.div<{ $loading?: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 308px;
  padding: 0px 10px;
  margin-top: 5px;
  margin-right: auto;
  background-color: #0001;
  border-radius: 5px;

  ${({ $loading }) => $loading && pulseLoadingAnimation}
`;

const TimeSlotsGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 0 20px;
  margin-right: auto;

  & > b {
    font-weight: 500;
    text-wrap: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const NoTimeSlots = styled.div`
  color: ${Colors.Warn};
`;

const SelectedDay = styled.span`
  text-decoration: underline;
`;

const InfoText = styled.i`
  margin-top: 5px;
  color: ${Colors.SemiBlack};
`;

interface Props {
  selectedDate?: Date;
  stagingLotResponse: {
    response?: StagingLotModel | null;
    status: RequestStatus;
  };
  warningMessage?: ReactNode;
  className?: string;
}

const StagingLotTimeSlots: FC<Props> = ({
  selectedDate,
  stagingLotResponse,
  warningMessage,
  className,
}) => {
  const allTimeSlots = stagingLotResponse.response?.timeSlots ?? null;

  const timeSlotsForSelectedDate = useMatchingTimeSlots(
    allTimeSlots,
    selectedDate
  );
  const dayOfWeek = selectedDate?.getDay();

  return (
    <Wrapper
      $loading={stagingLotResponse.status === RequestStatus.Fetching}
      className={className}
    >
      {stagingLotResponse.status === RequestStatus.Fetching ? (
        'Laddar öppettider...'
      ) : (
        <>
          {warningMessage && (
            <NoTimeSlots>
              <FontAwesomeIcon icon={faWarning} /> {warningMessage}
            </NoTimeSlots>
          )}

          <TimeSlotsGrid>
            {allTimeSlots?.map((ts) => {
              const isActive = timeSlotsForSelectedDate?.includes(ts);
              const inactiveStyle = isActive
                ? undefined
                : {
                    opacity: 0.5,
                  };

              return (
                <React.Fragment key={ts.label}>
                  <b title={ts.label} style={inactiveStyle}>
                    {ts.label}
                  </b>
                  <span style={inactiveStyle}>
                    {ts.days &&
                      sortDaysList(ts.days).map((d, i, array) => (
                        <React.Fragment key={i}>
                          {d === dayOfWeek ? (
                            <SelectedDay>
                              {shortDayLabelsByWeekDay[d]}
                            </SelectedDay>
                          ) : (
                            shortDayLabelsByWeekDay[d]
                          )}
                          {i < array.length - 1 && ', '}
                        </React.Fragment>
                      ))}
                  </span>
                  <span style={inactiveStyle}>
                    {toHHMM(ts.startTime)} - {toHHMM(ts.endTime)}{' '}
                  </span>
                </React.Fragment>
              );
            })}
          </TimeSlotsGrid>

          <InfoText>Redigera dessa under "Min verkstad"</InfoText>
        </>
      )}
    </Wrapper>
  );
};

export default StagingLotTimeSlots;
