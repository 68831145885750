import logo, { ReactComponent as logoIcon } from './logo.svg';
import selectArrow, {
  ReactComponent as selectArrowIcon,
} from './selectArrow.svg';
import sweden, { ReactComponent as swedenIcon } from './sweden.svg';
import towTruck, { ReactComponent as towTruckIcon } from './towTruck.svg';

export const Images = {
  logo,
  selectArrow,
  sweden,
  towTruck,
};

export const Icons = {
  Logo: logoIcon,
  SelectArrow: selectArrowIcon,
  Sweden: swedenIcon,
  TowTruck: towTruckIcon,
};
