import React from 'react';
import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { MediaQuery } from 'utils/style';

const Table = styled.table<{ mobileInset: number; tabletInset: number }>`
  ${({ mobileInset, tabletInset }) => css`
    th,
    td {
      width: 50%;
      height: 32px;
      padding: 1px 10px;

      &:first-child {
        padding-left: ${mobileInset}px;
      }
      &:last-child {
        padding-right: ${mobileInset}px;
      }

      ${MediaQuery.tablet} {
        &:first-child {
          padding-left: ${tabletInset}px;
        }
        &:last-child {
          padding-right: ${tabletInset}px;
        }
      }
    }
  `}
`;

const KeyCell = styled.td``;

const ValueCell = styled.td``;

export type KeyValueItem =
  | {
      key: ReactNode;
      value: ReactNode;
    }
  | 'spacer';

interface Props {
  items: KeyValueItem[];
  useEmptyPlaceholder?: boolean | string;
  mobileInset?: number;
  tabletInset?: number;
  className?: string;
}

const KeyValueList: FC<Props> = ({
  items,
  useEmptyPlaceholder = false,
  mobileInset = 0,
  tabletInset = 0,
  className,
}) => {
  return (
    <Table
      mobileInset={mobileInset}
      tabletInset={tabletInset}
      className={className}
    >
      <tbody>
        {items.map((item, index) =>
          item === 'spacer' ? (
            <tr
              key={index}
              style={{ background: 'transparent', height: '2em' }}
            />
          ) : (
            <tr key={index}>
              <KeyCell>{item.key}</KeyCell>
              <ValueCell>
                {item.value
                  ? item.value
                  : useEmptyPlaceholder === true
                  ? '-'
                  : useEmptyPlaceholder}
              </ValueCell>
            </tr>
          )
        )}
      </tbody>
    </Table>
  );
};

export default KeyValueList;
