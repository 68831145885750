import { css, keyframes } from 'styled-components';

const animation = keyframes`

0% {
  background-position: 0% 0%;
}
100% {
  background-position: 200% 0%;
}
`;

const pulseLoadingAnimation = css`
  background: linear-gradient(
    90deg,
    #bbb 0%,
    #fff 25%,
    #bbb 50%,
    #fff 75%,
    #bbb 100%
  );
  background-size: 400% 400%;
  animation: ${animation} 3s linear reverse infinite;
`;

export default pulseLoadingAnimation;
